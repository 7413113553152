import React from 'react'
import { StyleSheet, TouchableWithoutFeedback, Modal } from 'react-native'
import { CustomText } from '../text/StyledText'
import Colors from '../../constants/Colors'
import { View } from 'react-native'
import { Platform } from 'react-native'
import { ScrollView } from 'react-native'
import { borderRadiusM } from '../../styles/corners'
import { cardShadow } from '../../styles/shadows'
import CustomPressable from "../pressables/CustomPressable"

export default function CustomDialog({ visible, onDismiss, children, style }) {
  return (
    <Modal animationType={'none'} transparent={true} visible={visible}>
      <CustomPressable
        onPress={onDismiss || null}
        style={[styles.modalBackground, !onDismiss && { cursor: 'default' }]}
      >
        <TouchableWithoutFeedback>
          <View
            style={[
              styles.modal,
              style,
              Platform.OS === 'web' && { cursor: 'default' },
            ]}
          >
            {children}
          </View>
        </TouchableWithoutFeedback>
      </CustomPressable>
    </Modal>
  )
}

export const ScrollableDialog = (props) => {
  return (
    <Modal animationType={'none'} transparent={true} visible={props.visible}>
      <View style={styles.modalBackground}>
        <View style={[styles.modal, props.style]}>
          {props.children}
        </View>
      </View>
    </Modal>
  )
}

export const ContentContainer = (props) => {
  if (props.noScroll === true) return <View style={[styles.contentContainer, props.style]}>{props.children}</View>
  else return <ScrollView style={[styles.contentContainer, props.style]}>{props.children}</ScrollView>
}

export const ActionButton = (props) => (
  <CustomPressable
    style={styles.button}
    accessibilityRole="button"
    onPress={props.onPress}
  >
    <CustomText
      font="bold"
      style={[styles.buttonText, props.danger && styles.danger, props.success && styles.success]}
    >
      {props.children}
    </CustomText>
  </CustomPressable>
)

const styles = StyleSheet.create({
  button: {
    borderTopColor: Colors.blackTransparent,
    borderTopWidth: 1,
    paddingBottom: 12,
    paddingTop: 12,
  },
  contentContainer: { flexGrow: 0, margin: 24 },
  danger: {
    color: Colors.danger,
  },
  success: {
    color: Colors.green,
  },
  modal: {
    ...cardShadow,
    ...borderRadiusM,
    backgroundColor: Colors.white,
    marginLeft: 12,
    marginRight: 12,
    maxHeight: 600,
    maxWidth: 400,
  },
  modalBackground: {
    alignItems: 'center',
    backgroundColor: Colors.modalBackground,
    flex: 1,
    justifyContent: 'center',
  },
  buttonText: {
    textAlign: 'center',
  },
})
