import React from 'react'
import TxtTMD from '../../../constants/TxtTMD'
import PageTitle from '../../../components/tags/PageTitle'
import MainContainer from '../../../components/MainContainer/MainContainer'
import { getUniverseConfig } from '../../../universe/universeController'
import ProfileView from '../../../components/views/Profile/ProfileView'

export default function UniverseProfileViewScreen(props) {
  const universeObject = getUniverseConfig(props.route.params.universe?.toLowerCase())

  return (
    <MainContainer backgroundColor={universeObject?.backgroundColor}>
      <PageTitle title={TxtTMD.profile.title[TxtTMD.lang]} />
      <ProfileView />
    </MainContainer>
  )
}
