import React from 'react'
import { createActivity as createActivityApi, getActivities } from '../../services/api/Activities'
import { txt } from '../../locales/i18n'
import Activities from '../../components/views/Activities/Activities'
import { useNavigation, useRoute } from '@react-navigation/core'

export default function ActivitiesFeedScreen() {
  const navigation = useNavigation()
  const route = useRoute()

  const navigateToActivity = (activityId, date) => {
    navigation.push('Activity', {
      id: activityId,
      date,
    })
  }

  const navigateToMyActivities = () => {
    navigation.replace('Activities', {
      chip: txt('activities.chipParams.mine'),
    })
  }

  const createActivity = async (data) => {
    return await createActivityApi(data)
  }

  function _getActivities(params) {
    if (route?.params?.municipality !== undefined) {
      params.municipality = route.params.municipality
    }

    return getActivities(params)
  }

  return (
    <Activities
      getActivities={_getActivities}
      createActivity={createActivity}
      navigateToActivity={navigateToActivity}
      navigateToMyActivities={navigateToMyActivities}
    />
  )
}
