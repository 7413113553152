import { apiGet, apiPost, apiPut, apiGetFile, apiDelete } from './ApiRequest'
import { setGroups, updateGroup as updateGroupRedux, leaveGroup } from '../../redux/actions'
import store from '../../redux/store'
import Paths from '../../constants/Paths'

export async function getJoinedGroupsList() {
  const res = await apiGet('groups/joined')
  if (res.data) store.dispatch(setGroups(res.data))
  return res.data ?? []
}

export async function getGroupByID(groupId) {
  if (!groupId) return
  const res = await apiGet('groups/group/' + groupId.toString())
  if (!res) return
  if (res.data[0]?.id) return res.data[0]
  else if (res.data.id) return res.data
}

export async function updateGroup(data) {
  const result = await apiPut(data, 'groups/group/' + data.id.toString())
  if (!result) return
  else return result.json()
}

export async function getGroupMessages(group_id, skip, amount) {
  const res = await apiGet(`groups/${group_id}/messages?skip=${skip}&amount=${amount}`)
  let messages = []
  if (res) {
    messages = res.map((msg) => {
      msg.child_messages = JSON.parse(msg.child_messages)
      msg.child_messages?.reverse()
      return msg
    })
  }
  return messages
}

export async function getGroupMessageImages(groupId, msgId) {
  const res = await apiGet(`groups/group/${groupId}/messages/${msgId}/images`)
  if (!res.images) {
    return
  }
  return res.images
}

export async function getGroupMessageDocuments(groupId, msgId) {
  const { documents } = await apiGet(`groups/group/${groupId}/messages/${msgId}/documents`)
  return documents
}

export async function getGroupMessageComments(groupId, msgId, skip) {
  return await apiGet(`groups/group/${groupId}/messages/${msgId}/comments?skip=${skip}`)
}

export async function getGroupMessageDocument(groupId, msgId, id, extension) {
  return await apiGetFile(`groups/group/${groupId}/messages/${msgId}/document?id=${id}&extension=${extension}`)
}

export async function getGroupMembers(groupId) {
  const res = await apiGet(`groups/group/${groupId}/members`)
  if (!res) return []
  return res.data ?? []
}

export async function getJoinRequests(groupId) {
  const res = await apiGet(`groups/group/${groupId}/joinRequests`)
  if (!res) return []
  return res.data ?? []
}

export async function getInvitedMembers(groupId) {
  const res = await apiGet(`groups/group/${groupId}/invitesAll`)
  if (!res) return []
  return res.data ?? []
}

export async function getBannedMembers(groupId) {
  const res = await apiGet(`groups/group/${groupId}/bannedMembers`)
  if (!res) return []
  return res.data ?? []
}

export async function searchGroups({ search, skip, amount }) {
  let uri = `groups/?amount=${amount}&skip=${skip}&search=${search}`
  const groups = await apiGet(uri)
  return groups || []
}

export async function joinGroupByID(id, password, dataConsent = false) {
  const body = { password, dataConsent }
  const response = await apiPost(body, `groups/group/${id}`)

  const data = await response.json()

  if (data.group) {
    const groupsFiltered = store.getState().groups.filter((group) => {
        return group.id !== data.group.id
    })

    const groups = [...groupsFiltered, data.group].sort((a, b) => {
      return new Date(b.latestMsgDate) - new Date(a.latestMsgDate)
    })

    store.dispatch(setGroups(groups))
  }

  return data?.message ?? 'UNKNOWN ERROR'
}

export async function leaveGroupByID(groupId) {
  if (!groupId) return null
  let res = await apiPost({}, `groups/group/${groupId}/leave`)
  if (!res.ok) return null
  res = await res.json()
  store.dispatch(leaveGroup(groupId))
  return res.data[0]
}

export async function removeGroupMember(groupId, userId, removeId) { // remove_id = kick(0) or ban(1)
  if (groupId == null || userId == null || removeId == null) return null
  const data = { user_id: userId, remove_id: removeId }
  let res = await apiPost(data, `groups/group/${groupId}/remove`)
  if (!res.ok) return null
  res = await res.json()
  return res.data
}

export async function unbanGroupMember(groupId, userId) {
  if (groupId === null || userId === null) return null
  let res = await apiPost({ user_id: userId }, `groups/group/${groupId}/unban`)
  if (!res.ok) return null
  res = await res.json()
  return res.data[0]
}

export async function approveJoinRequest(groupId, userId) {
  if (groupId === null || userId === null) return null
  let res = await apiPost({ user_id: userId }, `groups/group/${groupId}/approve`)
  if (!res.ok) return null
  res = await res.json()
  return res.data
}

export async function sendInvite(groupId, email, username) {
  if (groupId === null || email === null || username === null) return null
  const data = { email, username }

  let res = await apiPost(data, `groups/group/${groupId}/invite`)
  if (!res.ok) return null
  res = await res.json()
  return res.data
}

export async function deleteInvite(groupId, email) {
	if (!groupId || !email) return null
	let res = await apiPost({ email }, `groups/group/${groupId}/deleteInvite`)
	return res
}

export async function makeUserAdmin(groupId, userId) {
  if (groupId === null || userId === null) return null
  let res = await apiPost({ user_id: userId }, `groups/group/${groupId}/makeAdmin`)
  if (!res.ok) return null
  res = await res.json()
  return res.data[0]
}

export async function deleteMessage(groupId, msgId) {
  if (groupId === null || msgId === null) return null
  let res = await apiPost({ msg_id: msgId }, `groups/group/${groupId}/messages/delete`)
  if (!res.ok) return null
  res = await res.json()
  return res.data[0]
}

export async function editMessage(groupId, msgId, body) {
  let data = { msg_id: msgId, body }
  let res = await apiPut(data, `groups/group/${groupId}/messages`)
  res = await res.json()
  return res.data ? res.data[0] : null
}

export async function createMessage({ groupId, body, images, documents, parentId }) {
  if (groupId == null) return

  const data = { body }
  if (parentId) data.parentMessageId = parentId
  if (images) data.images = images
  if (documents) data.documents = documents

  const response = await apiPost(data, `groups/group/${groupId}/messages`)
  if (response.ok === false) return

  const message = await response.json()
  return message
}

export async function readAllGroupMessages(group) {
  try {
    await store.dispatch(updateGroupRedux({ ...group, unread: 0 }))
    await apiPut({}, `groups/group/${group.id}/messages/readAll`)
    return true
  } catch (error) {
    if (__DEV__) console.error(error)
    return false
  }
}

export async function like(groupId, groupMessageId) {
  const res = await apiPost({}, `groups/${groupId}/messages/${groupMessageId}/likes`)
  const resJson = await res.json()
  return resJson.msg
}

export async function removeLike(groupId, groupMessageId) {
  const res = await apiDelete({}, `groups/${groupId}/messages/${groupMessageId}/likes`)
  return res.status
}

export async function whoLiked(groupId, groupMessageId) {
  return await apiGet(`groups/${groupId}/messages/${groupMessageId}/likes`)
}

export async function pinGroupPost(groupId, groupMessageId) {
  const res = await apiPost({}, `groups/${groupId}/pin/${groupMessageId}`)
  const resJson = await res.json()
  return resJson.msg
}

export async function unpinGroupPost(groupId, groupMessageId) {
  const res = await apiDelete({}, `groups/${groupId}/pin/${groupMessageId}`)
  return res.status
}

export async function createGroup(data) {
  const res = await apiPost(data, 'groups/create')
  const resJson = await res.json()
  return resJson.group
}

export async function closeGroup(groupId) {
  const res = await apiPut({}, `groups/group/${groupId}/close`)
  if (!res) return
  return res
}

export async function getTempFileLink(groupId, fileId) {
  const res = await apiGet(`groups/group/${groupId}/files/${fileId}`)
  if (!res) return null

  return Paths.apiBaseUrl + 'groups/group/files/' + res.tempFileId
}

export async function updateNotificationSetting(groupId, notificationSetting) {
  const body = { notificationSetting }
  const res = await apiPut(body, `groups/group/${groupId}/notifications`)

  if (!res || res.ok === false) return null

  const data = await res.json()
  return data?.notificationSetting ?? null
}