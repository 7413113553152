import Colors from '../../../constants/Colors'
import { StyleSheet, View } from 'react-native'
import { CustomText } from '../../text/StyledText'
import { txt } from '../../../locales/i18n'
import React from 'react'

export default function TextLabel({ style, junior = false, first = false, text }) {
  return (
    <View
      style={[
        style,
        styles.container,
        { backgroundColor: junior ? Colors.juniorLabelBackground : first ? Colors.greenLight : Colors.labelBackground },
      ]}
    >
      <CustomText style={{ color: junior ? Colors.juniorLabelText : first ? Colors.greenText : Colors.labelText }}
                  font="small">
        {junior ? txt('learnDanish.junior.juniorLabel') : first ? txt('learnDanish.volunteer.firstSession') : text}
      </CustomText>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    alignSelf: 'flex-start',
    paddingHorizontal: 6,
    paddingVertical: 4,
    borderRadius: 4,
  },
})
