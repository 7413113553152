import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import Cookies from 'js-cookie'
import CustomDialog from '../dialogs/CustomDialog'
import { CustomText } from '../text/StyledText'
import { Platform, StyleSheet, View } from 'react-native'
import Colors from '../../constants/Colors'
import NormalIcon from '../icons/NormalIcon'
import { ButtonPrimary } from '../buttons/StyledButtons'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { getOwnPosts } from '../../services/api/Posts'
import { txt } from '../../locales/i18n'
import CustomPressable from '../pressables/CustomPressable'
import { handlePaymentClick } from "../../utils/handlePaymentClick"

export default function SubscriptionDialog() {
	const userToken = useSelector((state) => state.userToken)
	const features = useSelector((state) => state.user?.features)
	const [visible, setVisible] = useState(false)

	useEffect(() => {
		if (userToken) {
			const dialogTimeout = setTimeout(openDialog, 5 * 60000)
			return () => clearTimeout(dialogTimeout)
		}
	}, [userToken, features])

	const openDialog = async () => {
		let subscriptionFlag
		if (Platform.OS === 'web') subscriptionFlag = Cookies.get('hideSubscriptionDialog')
		else subscriptionFlag = await AsyncStorage.getItem('hideSubscriptionDialog')
		const subscriptionFlagDate = new Date(subscriptionFlag)
		const laterSubscriptionFlagDate = new Date(subscriptionFlagDate)?.setDate(new Date().getDate() + 7)

		const posts = await getOwnPosts()

		if (userToken === null || posts.length === 0 || features?.includes(1)) return
		if (subscriptionFlag && laterSubscriptionFlagDate >= new Date()) return
		setVisible(true)
	}

	async function handleOpenUrl() {
		if (Platform.OS !== 'web') {
			await AsyncStorage.setItem('hideSubscriptionDialog', new Date().toISOString())
		} else {
			Cookies.set('hideSubscriptionDialog', new Date().toISOString())
			window.plausible('SubscriptionClick', { props: { origin: 'global-subscription-popup' } })
		}
		setVisible(false)
		await handlePaymentClick()
	}

	async function handleCloseDialog() {
		if (Platform.OS !== 'web') await AsyncStorage.setItem('hideSubscriptionDialog', new Date().toISOString())
		else Cookies.set('hideSubscriptionDialog', new Date().toISOString())
		setVisible(false)
	}

	return (
		<CustomDialog visible={visible}>
			<View style={styles.dialogContainer}>
				<CustomPressable style={styles.closeIcon} accessibilityRole="button" onPress={handleCloseDialog}>
					<NormalIcon name="times" color={Colors.inactive} />
				</CustomPressable>
				<CustomText style={styles.title}>{txt('subscription.dialogs.subscriptionDialog.title')}</CustomText>
				<CustomText style={{ marginTop: 12 }}>{txt('subscription.dialogs.subscriptionDialog.intro')}</CustomText>
				<View style={{ flexDirection: 'row', alignItems: 'center', marginTop: 18, marginLeft: 10 }}>
					<View style={styles.point}>
						<NormalIcon name="check" size={14} color={Colors.greenIcon} />
					</View>
					<CustomText style={styles.pointText}>{txt('subscription.dialogs.subscriptionDialog.point1')}</CustomText>
				</View>
				<View style={styles.pointContainer}>
					<View style={styles.point}>
						<NormalIcon name="check" size={14} color={Colors.greenIcon} />
					</View>
					<CustomText style={styles.pointText}>{txt('subscription.dialogs.subscriptionDialog.point2')}</CustomText>
				</View>
				<View style={styles.pointContainer}>
					<View style={styles.point}>
						<NormalIcon name="check" size={14} color={Colors.greenIcon} />
					</View>
					<CustomText style={styles.pointText}>{txt('subscription.dialogs.subscriptionDialog.point3')}</CustomText>
				</View>
				<View style={styles.pointContainer}>
					<View style={styles.point}>
						<NormalIcon name="check" size={14} color={Colors.greenIcon} />
					</View>
					<CustomText style={styles.pointText}>{txt('subscription.dialogs.subscriptionDialog.point4')}</CustomText>
				</View>
				<View style={styles.pointContainer}>
					<View style={styles.point}>
						<NormalIcon name="check" size={14} color={Colors.greenIcon} />
					</View>
					<CustomText style={styles.pointText}>{txt('subscription.dialogs.subscriptionDialog.point5')}</CustomText>
				</View>
				<View style={styles.pointContainer}>
					<View style={styles.point}>
						<NormalIcon name="check" size={14} color={Colors.greenIcon} />
					</View>
					<CustomText style={styles.pointText}>{txt('subscription.dialogs.subscriptionDialog.point6')}</CustomText>
				</View>
			</View>
			<View style={{ paddingBottom: 24 }}>
				<ButtonPrimary
					onPress={handleOpenUrl}
					style={{ backgroundColor: Colors.greenBtn }}
					title={txt('subscription.dialogs.subscriptionDialog.button')}
					titleStyle={{ paddingHorizontal: 12 }}
				/>
			</View>
		</CustomDialog>
	)
}

const styles = StyleSheet.create({
	dialogContainer: {
		margin: 24,
	},
	closeIcon: {
		alignSelf: 'flex-end',
		position: 'absolute',
		paddingLeft: 24,
		zIndex: 1,
	},
	title: {
		fontSize: 24,
		fontFamily: 'raleway-semibold',
		paddingRight: 25,
	},
	pointContainer: {
		flexDirection: 'row',
		alignItems: 'center',
		marginTop: 8,
		marginLeft: 10,
	},
	point: {
		padding: 3,
		backgroundColor: Colors.greenExtraLight,
		borderRadius: 10,
	},
	pointText: {
		paddingLeft: 6,
	},
})
