import React, { useState } from 'react'
import { URLOpener } from '../../../../utils/URLOpener'
import { CustomText } from '../../../text/StyledText'
import { StyleSheet, View } from 'react-native'
import Layout from '../../../../constants/Layout'
import Colors from '../../../../constants/Colors'
import NormalIcon from '../../../icons/NormalIcon'
import { txt } from '../../../../locales/i18n'
import { ShadowStyles } from '../../../../styles'
import CustomPressable from "../../../pressables/CustomPressable"

export default function Materials() {
  const [sub1Visible, setSub1Visible] = useState(false)
  const [sub2Visible, setSub2Visible] = useState(false)
  const [sub3Visible, setSub3Visible] = useState(false)
  const [sub4Visible, setSub4Visible] = useState(false)
  const [sub5Visible, setSub5Visible] = useState(false)
  const [beginnerVisible, setBeginnerVisible] = useState(false)
  const [intermediateVisible, setIntermediateVisible] = useState(false)
  const [practicedVisible, setPracticedVisible] = useState(false)
  const [podcastVisible, setPodcastVisible] = useState(false)
  const [juniorVisible, setJuniorVisible] = useState(false)
  const [games1Visible, setGames1Visible] = useState(false)
  const [games2Visible, setGames2Visible] = useState(false)
  const [games3Visible, setGames3Visible] = useState(false)
  const [games4Visible, setGames4Visible] = useState(false)
  const [games5Visible, setGames5Visible] = useState(false)

  const Separator = () => <View style={{ marginTop: 18 }} />

  const Point = ({ title, link }) => (
    <CustomPressable style={styles.linkContainer} onPress={() => link && URLOpener(link)}>
      <NormalIcon name="circle" stroke="fas" size={6} style={{ marginHorizontal: 12 }}
                  color={Colors.inactive} />
      <CustomText font="smallBold" style={[styles.linkText, { textDecorationLine: 'underline' }]}>{title}</CustomText>
    </CustomPressable>
  )

  const PointText = ({ title, text, link }) => (
    <>
      <CustomPressable style={styles.linkContainer} onPress={() => link && URLOpener(link)}>
        <NormalIcon name="circle" stroke="fas" size={6} style={{ marginHorizontal: 24 }}
                    color={Colors.inactive} />
        <CustomText font="smallBold" style={[styles.linkText, {maxWidth: '90%'}]}>{title}</CustomText>
      </CustomPressable>
      <CustomText font="small" style={{ marginLeft: 54 }}>{text}</CustomText>
    </>
  )

  const Header = ({ header }) => (
    <CustomText font="largeBold" style={{ padding: 12 }}>
      {header}
    </CustomText>
  )

  const SubHeader = ({ subHeader, onPress, visible, ...props }) => (
    <CustomPressable onPress={onPress} style={[styles.headerContainer, props.style]}>
      <CustomText font="bold" style={{ color: Colors.inactive }}>{subHeader}</CustomText>
      <NormalIcon name={visible ? "chevron-up" : "chevron-down"} size={16} color={Colors.redCrossRed} />
    </CustomPressable>
  )

  return (
    <View style={styles.container}>
      {/*Help for volunteers*/}
      <Header header={txt('learnDanish.volunteer.materials.help.header')} />
      <View style={styles.cardContainer}>
        <SubHeader
          subHeader={txt('learnDanish.volunteer.materials.help.sub1')}
          onPress={() => setSub1Visible(!sub1Visible)}
          visible={sub1Visible}
        />
        {sub1Visible && (
          <>
            <Point
              title="Frivilligguide til Sprogland"
              link={'https://filer.boblberg.dk/Files/Sprogland/frivilligguide-til-sprogland.pdf'}
            />
            <Point
              title="Frivilligguide til Sprogland Junior"
              link={'https://filer.boblberg.dk/Files/Sprogland/frivilligguide-til-sprogland-junior.pdf'}
            />
            <Point
              title="Håndbog til online sprogtrænere"
              link={'https://filer.boblberg.dk/Files/Sprogland/haandbog-til-online-sprogtraenere.pdf'}
            />
            <Point
              title="Ofte stillede spørgsmål"
              link={'https://filer.boblberg.dk/Files/Sprogland/faq-frivillige.pdf'}
            />
            <Point
              title="Henvisningsmuligheder"
              link={'https://filer.boblberg.dk/Files/Sprogland/henvisningsmuligheder.pdf'}
            />
            <Point
              title="How to-Video om Sprogland"
              link={'https://video.rodekors.dk/secret/90281751/13472c8d38ae835b24917d36dad56731'}
            />
            <Point
              title="Kontaktliste"
              link={'https://filer.boblberg.dk/Files/Sprogland/kontaktliste.pdf'}
            />		  
            <Separator />
          </>
        )}

        <SubHeader
          subHeader={txt('learnDanish.volunteer.materials.help.sub2')}
          onPress={() => setSub2Visible(!sub2Visible)}
          visible={sub2Visible}
          style={{ paddingTop: 24 }}
        />
        {sub2Visible && (
          <>
            <PointText
              title="Dansk og Prøver"
              link="https://danskogproever.dk/"
              text="Portal med masser af viden. Både om specifikke krav og man kan høre eksempler på mundtlige eksaminer ud fra karakterer."
            />
            <PointText
              title="Prøve i Dansk 1 - Danskuddannelse"
              link="https://vskdansk.dk/proever/pd1/"
              text="Information om prøven, samt eksempler på de fleste af prøvens elementer."
            />
            <PointText
              title="Prøve i Dansk 2 - Danskuddannelse"
              link="https://vskdansk.dk/proever/pd2/"
              text="Informationsvideoer om prøven, samt eksempler på de fleste af prøvens elementer."
            />
            <PointText
              title="Prøve i Dansk 3 - Danskuddannelse"
              link="https://vskdansk.dk/proever/pd3/"
              text="Information om prøven, samt eksempler på de fleste af prøvens elementer, herunder en video af den mundtlige del."
            />
            <PointText
              title="Mundtlig PD3"
              link="https://vskdansk.dk/wp-content/uploads/2021/11/Eksempel-Mundtlig-PD3.pdf"
              text="Eksempel på de billeder, man skal tale om til en mundtlig PD3 prøve."
            />
            <PointText
              title="Mundtlig PD3"
              link="https://filer.boblberg.dk/Files/Sprogland/pd3-eksempel.pdf"
              text="Andet eksempel på de billeder, man skal tale om til en mundtlig PD3 prøve."
            />
            <Separator />
          </>
        )}

        <SubHeader
          subHeader={txt('learnDanish.volunteer.materials.help.sub3')}
          onPress={() => setSub3Visible(!sub3Visible)}
          visible={sub3Visible}
          style={{ paddingTop: 24 }}
        />
        {sub3Visible && (
          <>
            <PointText
              title="Nyidanmark.dk"
              link="https://nyidanmark.dk"
              text="Den officielle portal for udlændinge, der ønsker at bo eller arbejde i Danmark, med information om reglerne for indrejse og ophold."
            />
            <PointText
              title="Medborgerskabsprøven.pdf"
              link="https://www.ft.dk/samling/20151/almdel/uui/bilag/115/1610678.pdf"
              text="Grundig introduktion til indholdet i medborgerskabsprøven."
            />
            <Separator />
          </>
        )}


        <SubHeader
          subHeader={txt('learnDanish.volunteer.materials.help.sub4')}
          onPress={() => setSub4Visible(!sub4Visible)}
          visible={sub4Visible}
          style={{ paddingTop: 24 }}
        />
        {sub4Visible && (
          <>
            <PointText
              title="Microsoft Word - ordbog-slovnik-final.docx"
              link="https://filer.boblberg.dk/Files/Sprogland/Ukrainian-Danish-English-dictionary.pdf"
              text="Ordbog lavet til kommunikation mellem danskere og ukrainere. Der er også meget information på engelsk."
            />
            <PointText
              title="Mikrogrammatik"
              link="https://filer.boblberg.dk/Files/Sprogland/mikrogrammatik.pdf"
              text="Meget kort introduktion, der omhandler de punkter i den danske grammatik, som traditionelt volder ukrainere (og andre slaviske folk) de største problemer."
            />
            <Separator />
          </>
        )}

        <SubHeader
          subHeader={txt('learnDanish.volunteer.materials.help.sub5')}
          onPress={() => setSub5Visible(!sub5Visible)}
          visible={sub5Visible}
          style={{ paddingTop: 24 }}
        />
        {sub5Visible && (
          <>
            <PointText
              title="Sprogtræningsmateriale om mad"
              link="https://filer.boblberg.dk/Files/Sprogland/sprogtræningsmateriale-mad-2017.pdf"
              text="Billeder og navne på en række forskellige madvarer. Kan bruges som udgangspunkt til samtale om madvaner og madlavning."
            />
            <PointText
              title="JUMP-kort - Dialog om job, uddannelse og medborgerskab | emu danmarks læringsportal"
              link="https://emu.dk/eud/erhvervsfag-1-3/samfund-og-sundhed/jump-kort-dialog-om-job-uddannelse-og-medborgerskab"
              text="Samlet materiale, med 48 forskellige billeder, der kan tales ud fra, og en lærervejledning, der kan tjene som inspiration til, hvordan billederne kan bruges i sprogtræning."
            />
            <PointText
              title="Samtaleinspiration og billedmateriale"
              link="https://filer.boblberg.dk/Files/Sprogland/samtaleinspiration-i-billedformat-til-sprogland.pdf"
              text="Samlet fil med en masse forskellige billeder af hverdagsliv og kultur i Danmark. Kan bruges til sprogtræning med begyndere og som inspiration til samtaleemner."
            />
            <PointText
              title="Sprogtræningsmateriale om jul, nytår og fødselsdag i Danmark"
              link="https://filer.boblberg.dk/Files/Sprogland/sprogtraeningsmateriale-jul-nytaar-foedselsdag-i-danmark.pdf"
              text="Samling af billeder og video omkring hvordan man typisk fejrer jul, nytår og fødselsdag i Danmark."
            />
            <PointText
              title="Sprogtræningsmateriale om sygehus og arbejdet som sygeplejerske"
              link="https://filer.boblberg.dk/Files/Sprogland/sprogtraeningsmateriale-sygehus-sygeplejerske.pdf"
              text="Samling af billeder og et par videoer om at være sygeplejerske og at arbejde på et sygehus "
            />
            <Separator />
          </>
        )}


      </View>

      {/*Language training*/}
      <Header header={txt('learnDanish.volunteer.materials.danish.header')} />
      <View style={styles.cardContainer}>
        <SubHeader
          subHeader={txt('learnDanish.volunteer.materials.danish.beginner')}
          onPress={() => setBeginnerVisible(!beginnerVisible)}
          visible={beginnerVisible}
        />
        {beginnerVisible && (
          <>
            <PointText
              title="Dansk her og nu Forside"
              link="https://danskherognu.dk/"
              text="Gratis online kursus i dansk for begyndere. 12 lektioner med basale ord og vendinger, samt info om dansk kultur."
            />
            <PointText
              title="Lexin Billedtema"
              link="https://bildetema.oslomet.no/dk/#/dan"
              text="Interaktiv billedordbog, som præsenterer objekter inden for mange forskellige temaer med illustrationer, tekst og lyd."
            />
            <PointText
              title="Jeg er håndværker"
              link="http://toolbox.danskherognu.dk/"
              text="Fokuserer på ord og fagbegreber som håndværkere kan have brug for."
            />
            <PointText
              title="Jeg er SoSu"
              link="https://sosu.danskherognu.dk/language/en-GB/Forside"
              text="Fokuserer på ord og fagbegreber rettet mod at skulle begå sig som SoSu-hjælper."
            />
            <Separator />
          </>
        )}

        <SubHeader
          subHeader={txt('learnDanish.volunteer.materials.danish.intermediate')}
          onPress={() => setIntermediateVisible(!intermediateVisible)}
          visible={intermediateVisible}
          style={{ paddingTop: 24 }}
        />
        {intermediateVisible && (
          <>
            <PointText
              title="sproget.dk"
              link="https://sproget.dk/"
              text="Under ”Leg og lær” er der blandt andet en introduktion til ”Grammatik for dummies” samt quizzer og øvelser."
            />
            <PointText
              title="Dansk-Tjek - Opgaver"
              link="https://dansk.tjek.net/opgaver"
              text="Hjemmeside med opgaver, kun om retskrivning og stavning."
            />
            <Separator />
          </>
        )}

        <SubHeader
          subHeader={txt('learnDanish.volunteer.materials.danish.practiced')}
          onPress={() => setPracticedVisible(!practicedVisible)}
          visible={practicedVisible}
          style={{ paddingTop: 24 }}
        />
        {practicedVisible && (
          <>
            <PointText
              title="Vores fællessprog (dansk.nu)"
              link="https://vfs.dansk.nu/"
              text="Grammatisk opslagsværk, hvor dansk grammatik forklares på dansk og andet sprog, f.eks. arabisk, tyrkisk eller bosnisk."
            />
            <PointText
              title="Fjern-uv"
              link="http://fjern-uv.dk/"
              text="Stor platform, hvor der både gås i dybden med grammatiske regler, der er også spil og øvelser samt gloser til hvert grammatisk område."
            />
            <Separator />
          </>
        )}

        <SubHeader
          subHeader={txt('learnDanish.volunteer.materials.danish.sub1')}
          onPress={() => setPodcastVisible(!podcastVisible)}
          visible={podcastVisible}
          style={{ paddingTop: 24 }}
        />
        {podcastVisible && (
          <>
            <PointText
              title="Dansk i ørerne"
              link="https://danskioererne.dk/"
              text="Podcast der er lavet til folk, der er ved at lære dansk, korte afsnit hvor der på tydeligt dansk fortælles om emner med relation til Danmark og danskere."
            />
            <PointText
              title="Simple Danish podcast"
              link="https://denmarkandme.com/podcast/"
              text="Podcast på enkelt og tydeligt dansk der fortæller om emner relevant for livet i Danmark."
            />
            <PointText
              title="Lydfiler om sundhedsvæsnet"
              link="https://sygehusdansk.dk/lydfiler/"
              text="Lydfiler på et ret højt niveau om specifikke medicinske problemer."
            />
            <PointText
              title="Sunhedsdansk"
              link="https://sundhedsdansk.dk/"
              text="Sprogundervisningsmateriale om krop, sundhed og sygdom, som er målrettet danskuddannelse til voksne udlændinge. Der er videoer, ordlister og opgaver. "
            />
            <PointText
              title="Ligetil | DR"
              link="https://www.dr.dk/ligetil"
              text="Nyheder på et mere simpelt dansk, hvor svære ord også bliver forklaret."
            />
            <PointText
              title="Duolingo"
              link="https://www.duolingo.com/course/da/en/Learn-Danish?fbclid=IwAR0WsCM_rQ4KhjB_L4enExNKRXcwKZANwxBY4VEOM0_hti-zEF-iH7iq9g0"
              text="Sprogtræningsapp – indeholder mange forskellige sprog."
            />
            <PointText
              title="Dansk Udtale - YouTube"
              link="https://www.youtube.com/@danskudtale2749/featured"
              text="Forskellige videoer hvor der forklares udtale og særlige vokallyde på dansk."
            />
            <PointText
              title="Velkommen til Danmark"
              link="https://velkommentildanmark.dk/"
              text="Her kan nytilkommen i Danmark læse og se film om det danske samfund og om livet i Danmark generelt. Der er tekst og videoer på flere forskellige sprog."
            />
            <PointText
              title="Fagsprog for social- og sundhedsassistent"
              link="https://uddannelse.kk.dk/for-elever/dit-oplaeringsforloeb/sosu/sprogstoette-i-oplaeringen/bedre-til-fagsprog/fagsprog-for-social-og-sundhedsassistent"
              text="Hjemmesiden med tekst og lydfiler lavet til både social- og sundhedsassistenter og social- og sundhedshjælpere, ret højt niveau, kun forklaret på dansk."
            />
            <PointText
              title="Koen på isen"
              link="https://www.buzzsprout.com/2012221"
              text="Podcast, der forklarer ord, udtryk og talemåder, som kan være ekstra svære at forstå for ikke-dansksprogede. Er på et langsomt og tydeligt dansk."
            />
            <Separator />
          </>
        )}
      </View>

      {/*Help for sprogland junior*/}
      <Header header={txt('learnDanish.volunteer.materials.junior.header')} />
      <View style={styles.cardContainer}>
        <SubHeader
          subHeader={txt('learnDanish.volunteer.materials.junior.sub1')}
          onPress={() => setJuniorVisible(!juniorVisible)}
          visible={juniorVisible}
        />
        {juniorVisible && (
          <>
            <PointText
              title="Dr.dk/lær"
              link="https://www.dr.dk/dr-laer"
              text="Forskellige undervisningsmaterialer, leg- og læringsforløb baseret på indhold fra DR, materialerne er opdelt i alder og skoletrin."
            />
            <PointText
              title="www.duda.dk"
              link="https://duda.dk/"
              text="Opgaver og videoer delt op i temaer, fag og klassetrin både for folkeskolen og ungdomsuddannelserne."
            />
            <PointText
              title="Opgaveskyen.dk"
              link="https://www.opgaveskyen.dk/"
              text="Opgavebank med opgavehæfter delt op i klassetrin og andre temaer. Både dansk og mange andre fag."
            />
            <PointText
              title="Sprogland Junior spillet"
              link="https://filer.boblberg.dk/Files/Sprogland/Sprogland-Junior-spillet.pdf"
              text="Samtalespillet Sprogland Junior er et redskab udviklet til at facilitere sprogtræning med børn over video."
            />
            <PointText
              title="Multidansk.dk"
              link="https://www.danish-asl.eu/"
              text="Hjemmeside med videoer, spil, eventyr og billeder, kan bruges til de yngste. Siden bliver ikke opdateret længere "
            />
            <PointText
              title="Materialer til tosprogede | Leg og Lektie"
              link="https://www.legoglektie.dk/2022/08/30/materialer-til-tosproede-undervisningen-dansk-2-sprog/"
              text="Her kan du finde materialer der kvit og frit kan hentes. Vær opmærksom på det er meningen at nogle af materialerne skal printes, mens andre godt kan bruges online."
            />
            <PointText
              title="Aktivitetsfolder"
              link="https://filer.boblberg.dk/Files/Sprogland/aktivitetsfolder-sprogland-junior.pdf"
              text="Folder med ideer til konkrete aktiviteter, viden og værktøjer. Folderen bliver løbende opdateret."
            />
            <PointText
              title="Hanneduebak.dk"
              link="https://www.hanneduebak.dk/"
              text="Hjemmeside med materialer til undervisning i tal og matematik. Er lavet til skolebørn, men kan måske bruges til at øve tal på dansk med deltagerne."
            />
            <Separator />
          </>
        )}
      </View>


      {/*Samtalespil*/}
      <Header header={txt('learnDanish.volunteer.materials.games.header')} />
      <View style={styles.cardContainer}>
        <SubHeader
          subHeader={txt('learnDanish.volunteer.materials.games.sub1')}
          onPress={() => setGames1Visible(!games1Visible)}
          visible={games1Visible}
        />
        {games1Visible && (
          <>
            <PointText
              title="Introduktion til ”Rejsekammerater”"
              link="https://filer.boblberg.dk/Files/Sprogland/Samtalespil/Introduktion-til-spillene/introduktion-til-rejsekammerater.pdf"
              text="”Rejsekammerater” er et samtalespil for voksne. Her er regler og inspiration til brug af spillet. "
            />
            <PointText
              title="Introduktion til ”På Sporet af det Tabte Sprog”"
              link="https://filer.boblberg.dk/Files/Sprogland/Samtalespil/Introduktion-til-spillene/introduktion-til-på-sporet-af-det-tabte-sprog.pdf"
              text="”På Sporet af det Tabte Sprog” er et samtalespil for børn. Her er regler og inspiration til brug af spillet."
            />
            <Separator />
          </>
        )}

        <SubHeader
          subHeader={txt('learnDanish.volunteer.materials.games.sub2')}
          onPress={() => setGames2Visible(!games2Visible)}
          visible={games2Visible}
          style={{ paddingTop: 24 }}
        />
        {games2Visible && (
          <>
            <Point
              title="Biografen"
              link="https://filer.boblberg.dk/Files/Sprogland/Samtalespil/Rejsekammerater-børn-og-fritid/Biografen.pdf"
            />
            <Point
              title="Fødselsdag"
              link="https://filer.boblberg.dk/Files/Sprogland/Samtalespil/Rejsekammerater-børn-og-fritid/Fødselsdag.pdf"
            />
            <Point
              title="Hallen"
              link="https://filer.boblberg.dk/Files/Sprogland/Samtalespil/Rejsekammerater-børn-og-fritid/Hallen.pdf"
            />
            <Point
              title="Legeplads"
              link="https://filer.boblberg.dk/Files/Sprogland/Samtalespil/Rejsekammerater-børn-og-fritid/Legeplads.pdf"
            />
            <Point
              title="Museum"
              link="https://filer.boblberg.dk/Files/Sprogland/Samtalespil/Rejsekammerater-børn-og-fritid/Museum.pdf"
            />
            <Point
              title="Naturen"
              link="https://filer.boblberg.dk/Files/Sprogland/Samtalespil/Rejsekammerater-børn-og-fritid/Naturen.pdf"
            />
            <Point
              title="Skole"
              link="https://filer.boblberg.dk/Files/Sprogland/Samtalespil/Rejsekammerater-børn-og-fritid/Skole.pdf"
            />
            <Point
              title="Stranden"
              link="https://filer.boblberg.dk/Files/Sprogland/Samtalespil/Rejsekammerater-børn-og-fritid/Stranden.pdf"
            />
            <Separator />
          </>
        )}

        <SubHeader
          subHeader={txt('learnDanish.volunteer.materials.games.sub3')}
          onPress={() => setGames3Visible(!games3Visible)}
          visible={games3Visible}
          style={{ paddingTop: 24 }}
        />
        {games3Visible && (
          <>
            <Point
              title="Aftenskole"
              link="https://filer.boblberg.dk/Files/Sprogland/Samtalespil/Rejsekammerater-det-danske-samfund/Aftenskole.pdf"
            />
            <Point
              title="Apotek"
              link="https://filer.boblberg.dk/Files/Sprogland/Samtalespil/Rejsekammerater-det-danske-samfund/Apotek.pdf"
            />
            <Point
              title="Bibliotek"
              link="https://filer.boblberg.dk/Files/Sprogland/Samtalespil/Rejsekammerater-det-danske-samfund/Bibliotek.pdf"
            />
            <Point
              title="Bondegård"
              link="https://filer.boblberg.dk/Files/Sprogland/Samtalespil/Rejsekammerater-det-danske-samfund/Bondegaarden.pdf"
            />
            <Point
              title="Café"
              link="https://filer.boblberg.dk/Files/Sprogland/Samtalespil/Rejsekammerater-det-danske-samfund/Cafe.pdf"
            />
            <Point
              title="Lægehus"
              link="https://filer.boblberg.dk/Files/Sprogland/Samtalespil/Rejsekammerater-det-danske-samfund/Lægehus.pdf"
            />
            <Point
              title="Togstation"
              link="https://filer.boblberg.dk/Files/Sprogland/Samtalespil/Rejsekammerater-det-danske-samfund/Togstation.pdf"
            />
            <Point
              title="Villavej"
              link="https://filer.boblberg.dk/Files/Sprogland/Samtalespil/Rejsekammerater-det-danske-samfund/Villavej.pdf"
            />
            <Separator />
          </>
        )}

        <SubHeader
          subHeader={txt('learnDanish.volunteer.materials.games.sub4')}
          onPress={() => setGames4Visible(!games4Visible)}
          visible={games4Visible}
          style={{ paddingTop: 24 }}
        />
        {games4Visible && (
          <>
            <Point
              title="Frokostpause"
              link="https://filer.boblberg.dk/Files/Sprogland/Samtalespil/Rejsekammerater-hverdag-og-hjemmet/Frokostpause.pdf"
            />
            <Point
              title="Genbrugspladsen"
              link="https://filer.boblberg.dk/Files/Sprogland/Samtalespil/Rejsekammerater-hverdag-og-hjemmet/Genbrugspladsen.pdf"
            />
            <Point
              title="Gæster"
              link="https://filer.boblberg.dk/Files/Sprogland/Samtalespil/Rejsekammerater-hverdag-og-hjemmet/Gæster.pdf"
            />
            <Point
              title="Haven"
              link="https://filer.boblberg.dk/Files/Sprogland/Samtalespil/Rejsekammerater-hverdag-og-hjemmet/Haven.pdf"
            />
            <Point
              title="Køkken"
              link="https://filer.boblberg.dk/Files/Sprogland/Samtalespil/Rejsekammerater-hverdag-og-hjemmet/Køkken.pdf"
            />
            <Point
              title="Stuen"
              link="https://filer.boblberg.dk/Files/Sprogland/Samtalespil/Rejsekammerater-hverdag-og-hjemmet/Stuen.pdf"
            />
            <Point
              title="Supermarked"
              link="https://filer.boblberg.dk/Files/Sprogland/Samtalespil/Rejsekammerater-hverdag-og-hjemmet/Supermarked.pdf"
            />
            <Point
              title="Trafikken"
              link="https://filer.boblberg.dk/Files/Sprogland/Samtalespil/Rejsekammerater-hverdag-og-hjemmet/Trafikken.pdf"
            />
            <Separator />
          </>
        )}

        <SubHeader
          subHeader={txt('learnDanish.volunteer.materials.games.sub5')}
          onPress={() => setGames5Visible(!games5Visible)}
          visible={games5Visible}
          style={{ paddingTop: 24 }}
        />
        {games5Visible && (
          <>
            <Point
              title="Bibliotek"
              link="https://filer.boblberg.dk/Files/Sprogland/Samtalespil/Paa-sporet-af-det-tabte-sprog/Biblioteket.pdf"
            />
            <Point
              title="Gadekær"
              link="https://filer.boblberg.dk/Files/Sprogland/Samtalespil/Paa-sporet-af-det-tabte-sprog/Gadekær.pdf"
            />
            <Point
              title="Hovedgade"
              link="https://filer.boblberg.dk/Files/Sprogland/Samtalespil/Paa-sporet-af-det-tabte-sprog/Hovedgade.pdf"
            />
            <Point
              title="Musens hus"
              link="https://filer.boblberg.dk/Files/Sprogland/Samtalespil/Paa-sporet-af-det-tabte-sprog/Musens_hus.pdf"
            />
            <Point
              title="Skolen"
              link="https://filer.boblberg.dk/Files/Sprogland/Samtalespil/Paa-sporet-af-det-tabte-sprog/Skolen.pdf"
            />
            <Point
              title="Stranden"
              link="https://filer.boblberg.dk/Files/Sprogland/Samtalespil/Paa-sporet-af-det-tabte-sprog/Stranden.pdf"
            />
            <Point
              title="Tidsmaskinen"
              link="https://filer.boblberg.dk/Files/Sprogland/Samtalespil/Paa-sporet-af-det-tabte-sprog/Tidsmaskinen.pdf"
            />
            <Separator />
          </>
        )}
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    width: '100%',
    maxWidth: Layout.maxFeedWidth,
    alignSelf: 'center',
    marginVertical: 24,
  },
  linkText: {
    color: Colors.blueAzure,
  },
  linkContainer: {
    marginTop: 18,
    flexDirection: 'row',
    alignItems: 'center',
  },
  cardContainer: {
    marginHorizontal: 12,
    backgroundColor: Colors.white,
    borderRadius: 2, ...ShadowStyles.liftedShadow,
    padding: 12,
    marginBottom: 24,
  },
  headerContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    // paddingTop: 24,
  },
})