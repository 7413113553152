import { Platform } from 'react-native'
import Colors from '../../constants/Colors'
import React from 'react'
import CommunitySlider from '@react-native-community/slider'

export default function Slider(props) {
  return (
    <CommunitySlider
      tapToSeek={true}
      thumbTintColor={Platform.OS !== 'ios'? Colors.blue : undefined}
      minimumTrackTintColor={Colors.blue}
      maximumTrackTintColor={Platform.OS !== 'ios'? Colors.greyLight : undefined}
      style={{flexGrow: 1, height: 40}}
      {...props}
    />

  )
}
