import React from 'react'
import { View, ScrollView, StyleSheet, Linking } from 'react-native'
import { txt } from '../../../locales/i18n'
import Colors from '../../../constants/Colors'
import { CustomText } from '../../text/StyledText'
import { SpacingStyles, TypographyStyles } from '../../../styles'
import TxtTMD from '../../../constants/TxtTMD'
import PageTitle from '../../tags/PageTitle'
import CustomPressable from "../../pressables/CustomPressable"

class Panel extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      // title: props.title,
      expanded: false,
    }
  }
  togglePanel(e) {
    this.setState({ expanded: !this.state.expanded })
  }

  

  render() {
    return (
      <View>
        <PageTitle title={TxtTMD.conditions.title[TxtTMD.lang]} />

        <CustomPressable
          onPress={this.togglePanel.bind(this)}
          underlayColor={Colors.white}
        >
          <CustomText style={[styles.panelHeader, styles.flex]}>
            {this.props.title}
          </CustomText>
        </CustomPressable>
        <View>
          {this.state.expanded && this.props.title === txt('conditions.deletingMembershipTitle') ? (
            <CustomText style={styles.panelText}>
              {txt('conditions.deletingMembership1')}
              <CustomPressable
                onPress={() => Linking.openURL('mailto:support@boblberg.dk')}
              >
                <CustomText style={{ color: Colors.blue }}>{txt('aboutUs.supportMail')}</CustomText>
              </CustomPressable>
			
              {txt('conditions.deletingMembership2')}
            </CustomText>
          ) : this.state.expanded ? (
            <CustomText style={styles.panelText}>{this.props.text}</CustomText>
          ) : null}
        </View>
      </View>
    )
  }
}

class MappedData extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <ScrollView style={styles.flex}>
        {this.props.data.map((subjectText, i) => {
          return (
            <Panel
              key={i}
              title={txt(`conditions.${subjectText[0]}`)}
              text={txt(`conditions.${subjectText[1]}`)}
            />
          )
        })}
      </ScrollView>
    )
  }
}

const fillings = [
  ['aboutRKandBoblbergTitle', 'aboutRKandBoblberg'],
  ['generalTitle', 'general'],
  ['membersInfoTitle', 'membersInfo'],
  ['infoInPostsTitle', 'infoInPosts'],
  ['infoInMessagesTitle', 'infoInMessages'],
  ['consentToScreeningTitle', 'consentToScreening'],
  ['picturesTitle', 'pictures'],
  ['harassmentTitle', 'harassment'],
  ['logFilesTitle', 'logFiles'],
  ['deletingMembershipTitle', 'deletingMembership1'],
  ['otherConditionsTitle', 'otherConditions'],
  ['changesTitle', 'changes'],
  ['questionsTitle', 'questions'],
]

export default class Conditions extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <View style={styles.widthHeight}>
        <View style={[styles.container, styles.flex]}>
          <View style={styles.textContainer}>
            <CustomText font="large">{txt('conditions.header')}</CustomText>
          </View>

          <View style={styles.listContainer}>
            <MappedData data={fillings} />
          </View>
          <View style={styles.container} />
        </View>
      </View>
    )
  }
}

const styles = StyleSheet.create({
  container: { backgroundColor: Colors.background },
  flex: { flex: 1 },
  listContainer: {
    backgroundColor: Colors.white,
    borderRadius: 10,
    margin: 10,
    padding: 10,
  },
  panelHeader: {
    color: Colors.text,
    padding: 10,
    ...TypographyStyles.textBold,
  },
  panelText: {
    padding: 10,
    ...TypographyStyles.text,
  },
  textContainer: {
    backgroundColor: Colors.white,
    borderRadius: 10,
    margin: 10,
    padding: 10,
  },
  widthHeight: { ...SpacingStyles.widthAndHeight },
})
