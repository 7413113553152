// Libraries
import React, { useState, useEffect } from 'react'
import { Dimensions, StyleSheet, View } from 'react-native'
import { useNavigation, useRoute } from '@react-navigation/core'
import { CommonActions } from '@react-navigation/routers'
import { useSelector } from 'react-redux'

// Api
import { getFavoriteActivities, getOwnActivities } from '../../../services/api/Activities'

// Components
import MainContainer from '../../../components/MainContainer/MainContainer'
import MyActivities from '../../../components/views/Activities/MyActivities'
import FavoriteActivities from '../../../components/views/Activities/FavoriteActivities'
import CreateActivity from '../../../components/views/Activities/CreateActivity'
import ActivityFeed from '../../../components/views/Activities/ActivityFeed'
import LoadingSpinner from '../../../components/activityIndicators/LoadingSpinner'
import PageTitle from '../../../components/tags/PageTitle'

import { txt } from '../../../locales/i18n'
import { CustomChip } from '../../chips/Chip'
import TxtTMD from '../../../constants/TxtTMD'

export default function Activities(props) {
  const user = useSelector((state) => state.user)
  const [pins, setPins] = useState([])
  const [selectedMenuItem, setSelectedMenuItem] = useState('')
  const [date, setDate] = useState(new Date())
  const [selectedCategories, setSelectedCategories] = useState([])
  const [municipality, setMunicipality] = useState()
  const [isLoading, setIsLoading] = useState(true)
  const isSmallDevice = Dimensions.get('window').width < 375
  const navigation = useNavigation()
  const route = useRoute()

  useEffect(() => {
    const chip = route.params?.chip
    setSelectedMenuItem(chip ? chip : '')
  }, [route.params?.chip])

  useEffect(() => {
    const municipalityParam = route.params?.municipality
    const userMunicipality = user?.municipality_id
    const isUniverse = Boolean(route.params?.universe)

    if (isUniverse === true) {
      setMunicipality(String(userMunicipality))
      setIsLoading(false)
      return
    }

    if (municipalityParam !== undefined) {
      setMunicipality(String(municipalityParam))
      setIsLoading(false)
      return
    }

    if (userMunicipality !== undefined) {
      setMunicipality(String(userMunicipality))
    }

    setIsLoading(false)
  }, [route.params?.municipality])

  useEffect(() => {
    if (municipality === undefined) return
    navigation.dispatch(CommonActions.setParams({ municipality }))
  }, [municipality])

  const setChip = (chip) => {
    navigation.dispatch(CommonActions.setParams({ chip }))
  }

  const getActivities = async ({ search, skip, amount, isSearch }) => {
    const { activities, pins } = await props.getActivities({
      search,
      skip,
      amount,
      isSearch,
      date,
      categories: selectedCategories,
      mapBounds: null,
      municipality,
    })
    setPins(pins)
    return activities
  }

  function displayStyle(menuItem) {
    return selectedMenuItem === menuItem ? {} : { display: 'none' }
  }

  const loadMenuOption = () => {
    return (
      <>
        <ActivityFeed
          navigation={navigation}
          navigateToActivity={props.navigateToActivity}
          date={date}
          handleDateChange={setDate}
          selectedCategories={selectedCategories}
          setCategories={(categories) => setSelectedCategories(categories)}
          setMunicipality={setMunicipality}
          municipality={municipality}
          pins={pins}
          getActivities={getActivities}
          backgroundColor={props.backgroundColor}
          route={route}
          renderChips={renderChips}
          style={displayStyle('')}
        />
        <FavoriteActivities
          navigation={navigation}
          navigateToActivity={props.navigateToActivity}
          getFavoriteActivities={getFavoriteActivities}
          route={route}
          renderChips={renderChips}
          style={displayStyle(txt('activities.chipParams.favorites'))}
        />
        {user && (
          <>
            <MyActivities
              navigation={navigation}
              navigateToActivity={props.navigateToActivity}
              getOwnActivities={getOwnActivities}
              route={route}
              renderChips={renderChips}
              style={displayStyle(txt('activities.chipParams.mine'))}
            />
            <CreateActivity
              navigation={navigation}
              navigateToMyActivities={props.navigateToMyActivities}
              createActivity={props.createActivity}
              renderChips={renderChips}
              style={displayStyle(txt('activities.chipParams.create'))}
            />
          </>
        )}
      </>
    )
  }

  const renderChips = () => (
    <View style={styles.center}>
      <View style={styles.row}>
        {/* Activities */}
        <View style={styles.chipContainer}>
          <View style={styles.chip}>
            <CustomChip
              icon="calendar-day"
              selected={selectedMenuItem === ''}
              accessibilityLabel={txt('activities.menu.feed')}
              onPress={() => setChip('')}
              text={txt('activities.menu.feed')}
            />
          </View>

          {/* My activities */}
          {user && (
            <View style={styles.chip}>
              <CustomChip
                icon="user"
                selected={selectedMenuItem === txt('activities.chipParams.mine')}
                accessibilityLabel={txt('activities.menu.editActivity')}
                onPress={() => setChip(txt('activities.chipParams.mine'))}
                style={styles.icon}
                text={txt('activities.menu.editActivity')}
              />
            </View>
          )}

          {/* Favorite activities */}
          <View style={styles.chip}>
            <CustomChip
              icon="star"
              selected={selectedMenuItem === txt('activities.chipParams.favorites')}
              accessibilityLabel={txt('activities.menu.favoriteActivities')}
              onPress={() => setChip(txt('activities.chipParams.favorites'))}
              text={!isSmallDevice && txt('activities.menu.favoriteActivities')}
            />
          </View>

          {/* Create Activity */}
          {user && (
            <View style={styles.chip}>
              <CustomChip
                icon="plus-circle"
                selected={selectedMenuItem === txt('activities.chipParams.create')}
                accessibilityLabel={txt('activities.menu.createActivity')}
                onPress={() => setChip(txt('activities.chipParams.create'))}
                text={txt('activities.menu.createActivity')}
              />
            </View>
          )}

          {!user && (
            <View style={styles.chip}>
              <CustomChip
                onPress={() => navigation.navigate('Login')}
                accessibilityLabel={txt('loginField.loginButtonText')}
                icon="user"
                text={txt('loginField.loginButtonText')}
                // icon={() => (
                //   <NormalIcon
                //     name="user"
                //     size={Fontsizes.s}
                //     color={Colors.inactive}
                //     stroke="fas"
                //   />
                // )}
              />
            </View>
          )}
        </View>
      </View>
    </View>
  )

  return (
    <MainContainer noScroll backgroundColor={props.backgroundColor}>
      <PageTitle title={TxtTMD.activities.title[TxtTMD.lang]} />
      {isLoading ? <LoadingSpinner /> : loadMenuOption()}
    </MainContainer>
  )
}

const styles = StyleSheet.create({
  center: { alignItems: 'center' },
  chip: {
    marginBottom: 5,
    marginLeft: 3,
    marginRight: 3,
  },
  chipContainer: {
    alignSelf: 'center',
    flexDirection: 'row',
  },
  iconStyle: {
    marginRight: -12,
  },
  row: {
    flexDirection: 'row',
  },
})
