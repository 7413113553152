import React from 'react'
import { Platform, StyleSheet, View } from 'react-native'

import CustomDialog, { ContentContainer } from './CustomDialog'
import { CustomText } from '../text/StyledText'
import { txt } from '../../locales/i18n'
import Colors from '../../constants/Colors'
import { ButtonPrimary } from '../buttons/StyledButtons'
import NormalIcon from '../icons/NormalIcon'
import { handlePaymentClick } from "../../utils/handlePaymentClick"
import CustomPressable from "../pressables/CustomPressable"

export default function ContactUnavailableDialog({ visible, hide, post, username }) {

	const handleClick = async () => {
		const origin = post ? 'bobl-send-message' : 'conversation-send-message';
		if (Platform.OS === 'web') window.plausible('SubscriptionClick', { props: { origin } });
		
		hide()
		await handlePaymentClick(post || null, false);
	}

	return (
		<CustomDialog onDismiss={hide} visible={visible}>
			<ContentContainer>
				<CustomPressable style={styles.closeIcon} accessibilityRole='button' onPress={hide}>
					<NormalIcon size={22} name="times" color={Colors.inactive} />
				</CustomPressable>

				<CustomText font="largeBold" style={styles.title}>{txt('subscription.dialogs.contactUnavailable.title', { username })}</CustomText>
				<CustomText style={{ marginTop: 12 }}>{txt('subscription.dialogs.subscriptionDialog.intro')}</CustomText>
				
				<View style={{ flexDirection: 'row', alignItems: 'center', marginTop: 18 }}>
					<View style={styles.point}>
						<NormalIcon name="check" size={14} color={Colors.greenIcon} />
					</View>
					<CustomText style={styles.pointText}>{txt('subscription.dialogs.subscriptionDialog.point1')}</CustomText>
				</View>
				<View style={styles.pointContainer}>
					<View style={styles.point}>
						<NormalIcon name="check" size={14} color={Colors.greenIcon} />
					</View>
					<CustomText style={styles.pointText}>{txt('subscription.dialogs.subscriptionDialog.point2')}</CustomText>
				</View>
				<View style={styles.pointContainer}>
					<View style={styles.point}>
						<NormalIcon name="check" size={14} color={Colors.greenIcon} />
					</View>
					<CustomText style={styles.pointText}>{txt('subscription.dialogs.subscriptionDialog.point3')}</CustomText>
				</View>
				<View style={styles.pointContainer}>
					<View style={styles.point}>
						<NormalIcon name="check" size={14} color={Colors.greenIcon} />
					</View>
					<CustomText style={styles.pointText}>{txt('subscription.dialogs.subscriptionDialog.point4')}</CustomText>
				</View>
				<View style={styles.pointContainer}>
					<View style={styles.point}>
						<NormalIcon name="check" size={14} color={Colors.greenIcon} />
					</View>
					<CustomText style={styles.pointText}>{txt('subscription.dialogs.subscriptionDialog.point5')}</CustomText>
				</View>
				<View style={styles.pointContainer}>
					<View style={styles.point}>
						<NormalIcon name="check" size={14} color={Colors.greenIcon} />
					</View>
					<CustomText style={styles.pointText}>{txt('subscription.dialogs.subscriptionDialog.point6')}</CustomText>
				</View>
			</ContentContainer>

			<ButtonPrimary
				accessibilityRole='button'
				style={styles.paymentBtn}
				onPress={handleClick}
			>
				{txt('subscription.dialogs.subscriptionDialog.button')}
			</ButtonPrimary>
		</CustomDialog>
	)
}

const styles = StyleSheet.create({
	closeIcon: {
		alignSelf: 'flex-end',
		position: 'absolute',
		paddingLeft: 24,
		zIndex: 1,
	},
	title: {
		fontSize: 24,
		fontFamily: 'raleway-semibold',
		paddingRight: 25,
	},
	pointContainer: {
		flexDirection: 'row',
		alignItems: 'center',
		marginTop: 8,
	},
	point: {
		padding: 3,
		backgroundColor: Colors.greenExtraLight,
		borderRadius: 10,
	},
	pointText: {
		paddingLeft: 10,
	},
	paymentBtn: {
		minWidth: 200,
		marginBottom: 24,
		paddingVertical: 12,
		backgroundColor: Colors.greenBtn,
	},
})
