import { getSubscription, paymentClick } from '../services/api/User'
import { Platform } from 'react-native'
import Cookies from 'js-cookie'
import * as WebBrowser from 'expo-web-browser'
import * as Updates from 'expo-updates'
import * as AuthSession from 'expo-auth-session'
import * as Linking from 'expo-linking'

import Paths from '../constants/Paths'

import { jotaiStore, deepLinkingStatusAtom } from '../atoms'
import { getRegion } from "../services/localization"

export async function _handlePaymentClick(post = null, profile = false) {
	if (profile === false) paymentClick()
	const subscriptionBefore = await getSubscription()

	const url = Paths.getBaseUrl() + 'abonnement/oversigt'

	if (Platform.OS !== 'web') {
		//IOS only - inkognito - because it gives a sketchy popup without it
		const options = { preferEphemeralSession: true }

		// RedirectUrl is same as Landing page button url when pressing back button (Deep Linking).
		const redirectUrl = 'boblberg://close-web-browser'
		const browser = await WebBrowser.openAuthSessionAsync(url + '?app', redirectUrl, options)

		if (browser?.type?.match(/cancel|dismiss/) === false) return

		const subscriptionAfter = await getSubscription()
		const isNewSubscription = subscriptionAfter?.handle !== subscriptionBefore?.handle

		if (isNewSubscription === false) return
		return await Updates.reloadAsync()
	}

	if (post) {
		const config = { expires: 1 }
		if (!__DEV__) config.domain = 'boblberg.' + getRegion()
		Cookies.set('subscription-bobl', post?.id, config)
	}

	return window.open(url, '_self')
}

export const handlePaymentClick = async (post = null, profile = false) => {
	if (profile === false) paymentClick()

	const url = Paths.getBaseUrl() + 'abonnement/oversigt'
 
	if (Platform.OS === 'web') {
		if (post) {
			const config = { expires: 1 }
			if (!__DEV__) config.domain = 'boblberg.' + getRegion()
			Cookies.set('subscription-bobl', post?.id, config)
		}

		return window.open(url, '_self')
	}

	jotaiStore.set(deepLinkingStatusAtom, 'pending')

	const queries = []

	const appDeeplink = AuthSession.makeRedirectUri()
	queries.push('appDeeplink=' + encodeURIComponent(appDeeplink))

	await Linking.openURL(url + '?' + queries.join('&'))
}
