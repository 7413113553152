import React, { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { Dimensions, Image, StyleSheet, View, Platform, AppState } from 'react-native'
import { CommonActions } from '@react-navigation/routers'
import { useNavigation, useRoute } from '@react-navigation/core'
import AsyncStorage from '@react-native-async-storage/async-storage'
import moment from 'moment'
import { useAtomValue } from 'jotai'
import { txt } from '../../locales/i18n'

import {
	createPost as createPostApi,
	getFavoritePosts as getFavoritePostsApi,
	getOwnPosts as getOwnPostsApi,
	getPostFeedIds,
	getPostsFromIds,
} from '../../services/api/Posts'
import { getSubscription } from '../../services/api/User'
import { handlePaymentClick } from '../../utils/handlePaymentClick'

import MainContainer from '../../components/MainContainer/MainContainer'
import MyPosts from '../../components/views/Post/MyPosts'
import FavoritePosts from '../../components/views/Post/FavoritePosts'
import PostFeed from '../../components/views/Post/PostFeed'
import CreateEditPost from '../../components/views/Post/CreateEditPost'
import { CustomChip } from '../../components/chips/Chip'
import { CustomText } from '../../components/text/StyledText'
import NormalIcon from '../../components/icons/NormalIcon'
import CustomPressable from '../../components/pressables/CustomPressable'

import Colors from '../../constants/Colors'
import Layout from '../../constants/Layout'

import boblbergLogo from '../../assets/images/boblberg.png'
import boblbergRKLogo from '../../assets/images/icon.png'
import { deepLinkingStatusAtom } from '../../atoms'
import SubscriptionWinBackDialog from "../../components/dialogs/SubscriptionWinBackDialog"

export default function HomeScreen() {
	const [selectedMenuItem, setSelectedMenuItem] = useState('')
	const [renderSubscriptionBanner, setRenderSubscriptionBanner] = useState(false)
	const [expirationText, setExpirationText] = useState('')

	const status = useAtomValue(deepLinkingStatusAtom)

	const user = useSelector(({ user }) => user)
	const appState = useRef(AppState.currentState)
	const childFunc = useRef(null)
	const navigation = useNavigation()
	const router = useRoute()

	moment.locale(user?.language ?? 'da')

	const chips = {
		all: txt('post.menu.feed'),
		mine: txt('posts.chipParams.mine'),
		favorites: txt('posts.chipParams.favorites'),
		create: txt('posts.chipParams.create'),
		mineTitle: txt('post.menu.myPosts'),
		favoritesTitle: txt('post.menu.favoritePosts'),
		createTitle: txt('post.menu.createPost'),
	}

	useEffect(() => {
		const nonDKUser = user.country !== 'Denmark'
		const boblbergLogoStyle = { height: 34, width: 200 }
		const boblbergRKLogoStyle = { height: 54, width: 200 }

		navigation.setOptions({
			headerTitle: () => (
				<Image
					style={nonDKUser ? boblbergLogoStyle : boblbergRKLogoStyle}
					source={nonDKUser ? boblbergLogo : boblbergRKLogo}
					resizeMode="contain"
					accessibilityLabel={txt('home.logo')}
					accessibilityIgnoresInvertColors
					accessibilityRole="image"
				/>
			),
		})
	}, [])

	useEffect(() => {
		if (status !== 'idle' && status !== 'success') return

		getSubscription().then((subscription) => {
			if (subscription?.is_cancelled === true) {
				const expirationDate = moment(subscription?.expires)
				const now = moment().format()
				const diffInDays = expirationDate.diff(now, 'days')

				setExpirationText(() => {
					if (diffInDays === 1) return txt('subscription.expiresTomorrowTitle', { days: diffInDays })
					if (diffInDays > 1) return txt('subscription.expiresInXDaysTitle', { days: diffInDays })
					return txt('subscription.expiresSoonTitle')
				})
				return setRenderSubscriptionBanner(diffInDays <= 20)
			}

			setRenderSubscriptionBanner(false)
		})
	}, [status])

	useEffect(() => {
		if (Platform.OS === 'web') return
		const subscription = AppState.addEventListener('change', async (nextAppState) => {
			if (appState.current.match(/inactive|background/) && nextAppState === 'active') {
				await handleLastRendered()
			}
			appState.current = nextAppState
		})

		return () => subscription?.remove()
	}, [])

	const handleLastRendered = async () => {
		const dateNow = Date.now()
		const key = 'feedLastRendered'
		const lastRendered = await AsyncStorage.getItem(key)

		if (lastRendered === null) {
			return await AsyncStorage.setItem(key, String(dateNow))
		}

		const oneDay = 1000 * 60 * 60 * 24
		if (dateNow < Number(lastRendered) + oneDay) return

		await AsyncStorage.setItem(key, String(dateNow))
		await childFunc?.current?.()
	}

	useEffect(() => {
		const chip = router.params?.chip
		setSelectedMenuItem(chip ? chip : '')
	}, [navigation, router.params])

	const navigateToPost = (id) => {
		navigation.navigate('Post', { id })
	}

	function navigateToEditPost(id) {
		navigation.navigate('EditPost', { id })
	}

	const navigateToCreatePost = (plausibleOrigin) => {
		if (Platform.OS === 'web') {
			window.plausible('createBobl', {
				props: { source: plausibleOrigin },
			})
		}
		setChipParam(txt('posts.chipParams.create'))
	}

	const createPost = async (data) => {
		return await createPostApi(data)
	}

	const getOwnPosts = async () => {
		return await getOwnPostsApi()
	}

	const getFavoritePosts = async () => {
		return await getFavoritePostsApi()
	}

	async function getNewPostIds(filters) {
		const { postIds } = await getPostFeedIds(filters)
		return postIds
	}

	const setChipParam = (chip) => {
		navigation.dispatch(CommonActions.setParams({ chip }))
	}

	async function handleOpenUrl() {
		if (Platform.OS === 'web') window.plausible('SubscriptionClick', { props: { origin: 'expires-soon-banner' } })
		await handlePaymentClick()
	}

	const renderChips = () => {
		const isSmallDevice = Dimensions.get('window').width < 375
		return (
			<View style={styles.center}>
				{renderSubscriptionBanner === true && (
					<CustomPressable accessibilityRole="button" onPress={handleOpenUrl} style={styles.subscriptionBanner}>
						<NormalIcon
							name="exclamation-triangle"
							stroke="fas"
							size={20}
							color={Colors.white}
							style={{ alignSelf: 'flex-start' }}
						/>
						<View style={styles.subscriptionBannerTextContainer}>
							<CustomText font="smallBold" style={[styles.subscriptionBannerText, { marginBottom: 2 }]}>
								{expirationText}
							</CustomText>
							<CustomText font="small" style={styles.subscriptionBannerText}>
								{txt('subscription.expiresSoonBody')}
							</CustomText>
						</View>
					</CustomPressable>
				)}
				<View style={styles.row}>
					<View style={styles.chipContainer}>
						<View style={styles.chip}>
							<CustomChip
								icon="bobl"
								selected={selectedMenuItem === ''}
								accessibilityLabel={chips.all}
								onPress={() => setChipParam('')}
								text={chips.all}
							/>
						</View>

						<View style={styles.chip}>
							<CustomChip
								icon="user"
								selected={selectedMenuItem === chips.mine}
								accessibilityLabel={chips.mine}
								onPress={() => setChipParam(chips.mine)}
								text={chips.mineTitle}
							/>
						</View>

						<View style={styles.chip}>
							<CustomChip
								icon="star"
								selected={selectedMenuItem === chips.favorites}
								accessibilityLabel={chips.favorites}
								onPress={() => setChipParam(chips.favorites)}
								text={!isSmallDevice && chips.favoritesTitle}
							/>
						</View>

						<View style={styles.chip}>
							<CustomChip
								icon="plus-circle"
								selected={selectedMenuItem === chips.create}
								accessibilityLabel={chips.create}
								backgroundColor={'#b3cfe1'}
								onPress={() => {
									if (Platform.OS === 'web') {
										window.plausible('createBobl', { props: { source: 'chip' } })
									}
									setChipParam(chips.create)
								}}
								text={chips.createTitle}
							/>
						</View>
					</View>
				</View>
			</View>
		)
	}

	function isVisible(chipName) {
		return router.params?.chip === chipName
	}

	return (
		<MainContainer noScroll>
			<SubscriptionWinBackDialog />
			<PostFeed
				childFunc={childFunc}
				style={isVisible(undefined) || isVisible('') ? {} : { display: 'none' }}
				navigateToPost={navigateToPost}
				getNewPostIds={getNewPostIds}
				getPosts={getPostsFromIds}
				navigateToCreatePost={navigateToCreatePost}
				renderChips={renderChips}
			/>
			<MyPosts
				style={isVisible(chips.mine) ? {} : { display: 'none' }}
				navigateToCreatePost={navigateToCreatePost}
				getOwnPosts={getOwnPosts}
				navigateToPost={navigateToPost}
				navigateToEditPost={navigateToEditPost}
				renderChips={renderChips}
			/>
			<FavoritePosts
				style={isVisible(chips.favorites) ? {} : { display: 'none' }}
				navigateToPost={navigateToPost}
				getFavoritePosts={getFavoritePosts}
				renderChips={renderChips}
			/>
			<CreateEditPost
				style={isVisible(chips.create) ? {} : { display: 'none' }}
				route={router}
				navigateToMyPosts={() => {
					navigation.navigate('Home', {
						params: { chip: txt('posts.chipParams.mine') },
					})
				}}
				createPost={createPost}
				renderChips={renderChips}
			/>
		</MainContainer>
	)
}

const styles = StyleSheet.create({
	center: {
		alignItems: 'center',
		paddingHorizontal: 12,
	},
	chip: {
		marginBottom: 5,
		marginLeft: 3,
		marginRight: 3,
	},
	chipContainer: {
		alignSelf: 'center',
		flexDirection: 'row',
		marginTop: 5,
	},
	row: {
		flexDirection: 'row',
	},
	subscriptionBanner: {
		flexDirection: 'row',
		alignItems: 'center',
		alignSelf: 'center',
		width: '100%',
		maxWidth: Layout.maxFeedWidth,
		backgroundColor: Colors.redLight,
		padding: 12,
		marginVertical: 6,
		borderRadius: 8,
	},
	subscriptionBannerTextContainer: {
		flexGrow: 1,
		flex: 1,
	},
	subscriptionBannerText: {
		paddingLeft: 12,
		color: Colors.white,
	},
})
