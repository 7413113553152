// Libraries
import React, { useState, useEffect } from 'react'
import { useRoute } from '@react-navigation/core'

// Api
import {
  createActivity as createActivityApi,
  getActivities as getActivitiesApi,
} from '../../../services/api/Activities'
import { getUniverseConfig, getUniverseId } from '../../../universe/universeController'

// Components
import Activities from '../../../components/views/Activities/Activities'
import { txt } from '../../../locales/i18n'

export default function ActivitiesUniverseScreen(props) {
  const [universe, setUniverse] = useState(null)
  const [universeObject, setUniverseObject] = useState(null)
  const route = useRoute()

  let ignoreSkip = 0
  let useSeparator = true

  useEffect(() => {
    let universeName = props.route.params?.universe?.toLowerCase()

    const universeId = getUniverseId(universeName)
    setUniverse({ id: universeId, name: universeName })
    setUniverseObject(getUniverseConfig(universeName))

    ignoreSkip = 0
  }, [props.navigation, props.route.params])

  const navigateToActivity = (activityId, date) => {
    props.navigation.push('Activity', {
      id: activityId,
      date,
      universe: universe.name,
    })
  }

  const navigateToMyActivities = () => {
    props.navigation.replace('Activities', {
      chip: txt('activities.chipParams.mine'),
      universe: universe.name,
    })
  }

  const getActivities = async ({ isSearch, ...params }) => {
    // resets values if a search is executed
    if (isSearch) {
      ignoreSkip = 0
      useSeparator = true
    }
    params = {
      ...params,
      universeId: universe.id,
		}
		
		// Remove params.municipality for Krifa universe to avoid getting pins from users municipality
		if (universe.id === 13) delete params.municipality
    
		const data = await getActivitiesApi(params)
    ignoreSkip += data.activities.length

    // Get local activities if no more universe activities
    const listOfActivities = async () => {
      if (data.activities.length === 0) {
        delete params.universeId
        const dataLocal = await getActivitiesApi({
          ...params,
          skip: params.skip - ignoreSkip,
          municipalityId: route.params.municipalityId,
        })

        const response = {
          activities: dataLocal.activities,
          pins: [...data.pins, ...dataLocal.pins],
        }

        if (useSeparator && dataLocal.activities.length) {
          useSeparator = false
          const separator = { noCount: true, isSeparator: true, id: Date.now() }
          response.activities.unshift(separator)
        }
        return response
      }
      ignoreSkip += data.activities.length
      return { activities: data.activities, pins: data.pins }
    }

    return listOfActivities()
  }

  const createActivity = async (data) => {
    return await createActivityApi({
      ...data,
      universeId: universe.id,
    })
  }

  return (
    <Activities
      getActivities={getActivities}
      createActivity={createActivity}
      navigateToActivity={navigateToActivity}
      navigateToMyActivities={navigateToMyActivities}
      backgroundColor={universeObject?.backgroundColor}
      isUniverse
    />
  )
}
