import React, { useState, useEffect, useRef } from 'react'
import { View, StyleSheet, Image, Platform } from 'react-native'
import MainContainer from '../../../components/MainContainer/MainContainer'
import { CustomChip } from '../../../components/chips/Chip'
import { txt } from '../../../locales/i18n'
import MyPosts from '../../../components/views/Post/MyPosts'
import FavoritePosts from '../../../components/views/Post/FavoritePosts'
import CreateEditPost from '../../../components/views/Post/CreateEditPost'
import {
  getPostFeedIds,
  getPostsFromIds,
  createPost as createPostApi,
  getFavoritePosts as getFavoritePostsApi,
  getOwnPosts as getOwnPostsApi,
} from '../../../services/api/Posts'
import { getUniverseConfig } from '../../../universe/universeController'
import PostFeed from '../../../components/views/Post/PostFeed'
import { CommonActions } from '@react-navigation/native'
import LoadingSpinner from '../../../components/activityIndicators/LoadingSpinner'
import { Dimensions } from 'react-native'
import { UniversityDialog } from '../UniversityDialog'

export default function BoblsUniverseScreen(props) {
  const [selectedMenuItem, setSelectedMenuItem] = useState('')
  const [universe, setUniverse] = useState(null)
  const [isLoading, setIsLoading] = useState(true)

  const idLastUniversePost = useRef(undefined)
  const numUniversePosts = useRef(0)
  const createPostShown = useRef(false)

  const chips = {
    all: txt('post.menu.feed'),
    mine: txt('posts.chipParams.mine'),
    favorites: txt('posts.chipParams.favorites'),
    create: txt('posts.chipParams.create'),
    mineTitle: txt('post.menu.myPosts'),
    favoritesTitle: txt('post.menu.favoritePosts'),
    createTitle: txt('post.menu.favoritePosts'),
  }

  useEffect(() => {
    let currentUniverse = props.route.params?.universe?.toLowerCase()
    const _universe = getUniverseConfig(currentUniverse)
    setUniverse(_universe)
    if (_universe?.logo) {
      props.navigation.setOptions({
        headerTitle: () => (
          <Image
            style={styles.headerLogo}
            resizeMode="contain"
            source={{ uri: _universe?.logo }}
            accessibilityLabel={_universe?.logoLabel}
            accessibilityIgnoresInvertColors
            accessibilityRole="image"
            accessible
          />
        ),
        headerBackTitleVisible: false,
        headerLeft: () => null,
      })
    }
    const chip = props.route.params?.chip
    setSelectedMenuItem(chip ? chip : '')
    setIsLoading(false)
  }, [props.navigation, props.route.params])

  const navigateToPost = (postId) => {
    props.navigation.navigate('Post', {
      id: postId,
      universe: universe.name,
    })
  }

  function navigateToEditPost(id) {
    props.navigation.navigate('EditPost', { id, universe: universe.name })
  }

  const navigateToCreatePost = (plausibleOrigin) => {
    if (Platform.OS === 'web') {
      window.plausible('createBobl', {
        props: { source: plausibleOrigin },
      })
    }
    setChipParam(txt('posts.chipParams.create'))
  }

  const renderUniversityDialog = () => {
    if (universe?.name === 'aauucn') {
      return <UniversityDialog />
    }
  }

	const createPost = async (data) => {
		// Don't set universeId, if universe is 'Krifabobler' (id = 13)
		if (universe.id !== 13) data.universeId = universe.id
    return await createPostApi(data)
  }

  const getOwnPosts = async () => {
    return await getOwnPostsApi(universe.id)
  }

  const getFavoritePosts = async () => {
    return await getFavoritePostsApi(universe.id)
  }

  const setChipParam = (chip) => {
    props.navigation.dispatch(CommonActions.setParams({ chip }))
  }

  async function getPosts(ids) {
    let prepend = []
    if (!createPostShown.current) {
      prepend = [{ noCount: true, isCreatePostItem: true }]
      createPostShown.current = true
    }
    let posts = await getPostsFromIds(ids)
    // Separate universe posts and regular posts
    if (idLastUniversePost !== undefined) {
      const index = ids.indexOf(idLastUniversePost.current)
      if (index !== -1) {
        const universePosts = posts.filter(p => p.universe_id === universe.id)
        const regularPosts = posts.filter(p => p.universe_id !== universe.id)
        const separator = { noCount: true, isSeparator: true, id: Date.now() }
        posts = [...universePosts, separator, ...regularPosts]
      }
    }
    return [...prepend, ...posts]
  }

  async function getPostIds(filters) {
    // Reset counters if offset is 0
    if (filters.offset === 0) {
      numUniversePosts.current = 0
      idLastUniversePost.current = undefined
    }

    // Adjust filters to Universe
    filters.offset -= numUniversePosts.current
    if (idLastUniversePost.current === undefined) {
      filters.universeId = universe.id
    }

    const { universePostIds, postIds } = await getPostFeedIds(filters)
    numUniversePosts.current += universePostIds?.length || 0
    idLastUniversePost.current = universePostIds?.[universePostIds.length-1] || idLastUniversePost.current
    return [...universePostIds || [], ...postIds]
  }

  const renderChips = () => (
    <View style={styles.center}>
      <View style={styles.row}>
        <View style={styles.chipContainer}>
          <View style={styles.chip}>
            <CustomChip
              // icon={() => (
              //   <BoblbergIcon
              //     accessibilityLabel={txt('fullPost.usernameIcon')}
              //     name="bobl"
              //     size={Fontsizes.s}
              //     color={universe?.chipTextColor ?? Colors.inactive}
              //     stroke="fas"
              //   />
              // )}
              icon="bobl"
              selected={selectedMenuItem === ''}
              accessibilityLabel={chips.all}
              onPress={() => setChipParam('')}
              backgroundColor={universe?.chipUnselectedColor ?? null}
              textColor={universe?.chipTextColor ?? null}
              text={chips.all}
            />
          </View>

          <View style={styles.chip}>
            <CustomChip
              // icon={() => (
              //   <NormalIcon
              //     name="user"
              //     size={Fontsizes.s}
              //     color={universe?.chipTextColor ?? Colors.inactive}
              //     stroke="fas"
              //   />
              // )}
              icon="user"
              selected={selectedMenuItem === chips.mine}
              accessibilityLabel={chips.mine}
              onPress={() => setChipParam(chips.mine)}
              style={styles.icon}
              backgroundColor={universe?.chipUnselectedColor ?? null}
              textColor={universe?.chipTextColor ?? null}
              text={chips.mineTitle}
            />
          </View>

          <View style={styles.chip}>
            <CustomChip
              // icon={() => (
              //   <NormalIcon
              //     name="star"
              //     size={Fontsizes.s}
              //     color={universe?.chipTextColor ?? Colors.inactive}
              //     style={isSmallDevice ? styles.iconStyle : null}
              //     stroke="fas"
              //   />
              // )}
              icon="star"
              selected={selectedMenuItem === chips.favorites}
              accessibilityLabel={chips.favorites}
              onPress={() => setChipParam(chips.favorites)}
              backgroundColor={universe?.chipUnselectedColor ?? null}
              textColor={universe?.chipTextColor ?? null}
              text={!isSmallDevice && chips.favoritesTitle}
            />
          </View>

          <View style={styles.chip}>
            <CustomChip
              // icon={() => (
              //   <NormalIcon
              //     name="plus-circle"
              //     size={Fontsizes.s}
              //     color={universe?.chipTextColor ?? Colors.inactive}
              //     stroke="fas"
              //   />
              // )}
              icon="plus-circle"
              selected={selectedMenuItem === txt('posts.chipParams.create')}
              accessibilityLabel={txt('post.menu.createPost')}
              onPress={() => {
                if (Platform.OS === 'web') {
                  window.plausible('createBobl', { props: { source: 'chip' }})
                }
                setChipParam(txt('posts.chipParams.create'))
              }}
              backgroundColor={universe?.chipUnselectedColor ?? '#b3cfe1'}
              textColor={universe?.chipTextColor ?? null}
              text={txt('post.menu.createPost')}
            />
          </View>
        </View>
      </View>
    </View>
  )

  function isVisible(chipName) {
    return props.route.params?.chip === chipName
  }

  const isSmallDevice = Dimensions.get('window').width < 375
  return (
    <MainContainer noScroll backgroundColor={universe?.backgroundColor}>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          {renderUniversityDialog()}
          <PostFeed
            style={
              isVisible(undefined) || isVisible('') ? {} : { display: 'none' }
            }
            navigateToPost={navigateToPost}
            getPosts={getPosts}
            getNewPostIds={getPostIds}
            navigation={props.navigation}
            renderChips={renderChips}
            backgroundColor={universe?.backgroundColor}
            navigateToCreatePost={navigateToCreatePost}
            universe={universe}
          />
          <MyPosts
            style={isVisible(chips.mine) ? {} : { display: 'none' }}
            getOwnPosts={getOwnPosts}
            navigateToPost={navigateToPost}
            navigateToEditPost={navigateToEditPost}
            renderChips={renderChips}
            navigateToCreatePost={navigateToCreatePost}
            universe={universe}
          />

          <FavoritePosts
            style={isVisible(chips.favorites) ? {} : { display: 'none' }}
            navigation={props.navigation}
            navigateToPost={navigateToPost}
            getFavoritePosts={getFavoritePosts}
            renderChips={renderChips}
          />

          <CreateEditPost
            style={isVisible(chips.create) ? {} : { display: 'none' }}
            navigation={props.navigation}
            route={props.route}
            navigateToMyPosts={() => {
              props.navigation.replace('Bobls', {
                chip: txt('posts.chipParams.mine')
              })
            }}
            createPost={createPost}
            renderChips={renderChips}
          />
        </>
      )}
    </MainContainer>
  )
}

const styles = StyleSheet.create({
  center: { alignItems: 'center' },
  chip: { marginBottom: 5, marginLeft: 3, marginRight: 3 },
  chipContainer: { alignSelf: 'center', flexDirection: 'row', marginTop: 5 },
  headerLogo: { height: 42, width: 200 },
  iconStyle: { marginRight: -12 },
  row: { flexDirection: 'row' },
})
