import React, { useEffect, useState } from 'react'
import { View, Linking, StyleSheet } from 'react-native'
import Colors from '../../constants/Colors'
import { txt } from '../../locales/i18n'
import { CustomText } from '../../components/text/StyledText'
import OptionsList from '../../components/lists/OptionsList'
import { URLOpener } from '../../utils/URLOpener'
import TxtTMD from '../../constants/TxtTMD'
import PageTitle from '../../components/tags/PageTitle'
import { SpacingStyles, TypographyStyles } from '../../styles'
import MainContainer from '../../components/MainContainer/MainContainer'
import { useNavigation } from '@react-navigation/core'
import { getSubscription } from '../../services/api/User'
import { getRegion } from '../../services/localization'
import CustomPressable from "../../components/pressables/CustomPressable"

export default function ContactScreen() {
  const navigation = useNavigation()
  const [data, setData] = useState([
    {
      body: txt('help.title'),
      onPress: () => URLOpener('https://boblberg.dk/hjaelp'),
      icon: { name: "info-circle" },
    },
    {
      body: txt('conditions.title'),
      onPress: () => navigation.navigate('Conditions'),
      icon: { name: "file-check" },
    },
  ])

  useEffect(() => {
    getSubscription().then(async (sub) => {
      const region = getRegion()

      const privacy = {
        body: txt('contact.privacypolicy'),
        onPress: () => URLOpener(`https://filer.boblberg.dk/Files/Privacy_Policy/boblberg_privacy_policy_${region}.pdf`),
        icon: { name: "user-shield" },
      }

      const cookie = {
        body: txt('contact.cookiepolicy'),
        onPress: () => URLOpener(`https://filer.boblberg.dk/Files/Cookie_Policy/boblberg_cookiepolicy_${region}.pdf`),
        icon: { name: "cookie-bite" },
      }

      if (!sub) return setData((prev) => [...prev, privacy, cookie])

      const subscription = {
        body: txt('conditions.subscription'),
        onPress: () => URLOpener(`https://filer.boblberg.dk/Files/Conditions/subscription_conditions_${region}.pdf`),
        icon: { name: "file-check" },
      }

      setData((prev) => [...prev, subscription, privacy, cookie])
    })
  }, [])

  return (
    <MainContainer>
      <PageTitle title={TxtTMD.contact.title[TxtTMD.lang]} />

      <View style={styles.widthHeight}>
        <View style={styles.textContainer}>
          <CustomText>
            {txt('help.startText1')}
            <CustomText style={styles.textBold}>
              {txt('help.title')}
            </CustomText>
            {txt('help.startText2')}
          </CustomText>
          <CustomPressable onPress={() => Linking.openURL('mailto:support@boblberg.dk')}>
            <CustomText style={styles.linkText}>
              {txt('supportMail')}
            </CustomText>
          </CustomPressable>
        </View>
        <OptionsList data={data} chevron={true} />
      </View>
    </MainContainer>
  )
}

const styles = StyleSheet.create({
  linkText: {
    color: Colors.tintColor,
    ...TypographyStyles.text,
    marginTop: 5,
  },
  textBold: { ...TypographyStyles.textBold },
  textContainer: {
    backgroundColor: Colors.white,
    borderRadius: 10,
    margin: 12,
    padding: 12,
  },
  widthHeight: { ...SpacingStyles.widthAndHeight },
})
