import React, { useState } from 'react'
import MainContainer from '../../components/MainContainer/MainContainer'
import { CustomText } from '../../components/text/StyledText'
import { StyleSheet, View } from 'react-native'
import { txt } from '../../locales/i18n'
import Layout from '../../constants/Layout'
import Colors from '../../constants/Colors'
import Icon from 'react-native-vector-icons/MaterialCommunityIcons'
import Fontsizes from '../../constants/Fontsizes'
import { useNavigation } from '@react-navigation/core'
import CustomPressable from "../../components/pressables/CustomPressable"

export default function FAQScreenChristmas() {
  const [descriptionExpanded, setDescriptionExpanded] = useState(false)
  const [signupExpanded, setSignupExpanded] = useState(false)
  const [contactExpanded, setContactExpanded] = useState(false)
  const [editExpanded, setEditExpanded] = useState(false)
  const [closeExpanded, setCloseExpanded] = useState(false)
  const [deadlineExpanded, setDeadlineExpanded] = useState(false)
  const [contactUsExpanded, setContactUsExpanded] = useState(false)
  const navigation = useNavigation()

  const RenderItem = ({ title, body, onPress, expanded, link }) => {
    return (
      <View style={styles.container}>
        <CustomPressable
          onPress={onPress}
          style={{ flexDirection: 'row', justifyContent: 'space-between' }}
        >
          <CustomText font="bold" accessibilityRole="heading" aria-level={2}>
            {title}
          </CustomText>
          <Icon
            name={expanded ? 'chevron-up' : 'chevron-down'}
            size={Fontsizes.xl}
          />
        </CustomPressable>
        {expanded && <CustomText style={{ marginTop: 12 }}>{body}</CustomText>}
        {expanded && link && (
          <CustomPressable onPress={() => navigation.navigate('Contact')}>
            <CustomText style={{ color: Colors.blue, marginTop: 6 }}>
              {link}
            </CustomText>
          </CustomPressable>
        )}
      </View>
    )
  }

  return (
    <MainContainer>
      <RenderItem
        title={txt('christmasFriends.faq.descriptionTitle')}
        body={txt('christmasFriends.faq.description')}
        onPress={() => setDescriptionExpanded(!descriptionExpanded)}
        expanded={descriptionExpanded}
      />

      <RenderItem
        title={txt('christmasFriends.faq.signupTitle')}
        body={txt('christmasFriends.faq.signup')}
        onPress={() => setSignupExpanded(!signupExpanded)}
        expanded={signupExpanded}
      />

      <RenderItem
        title={txt('christmasFriends.faq.contactTitle')}
        body={txt('christmasFriends.faq.contact')}
        onPress={() => setContactExpanded(!contactExpanded)}
        expanded={contactExpanded}
      />

      <RenderItem
        title={txt('christmasFriends.faq.editTitle')}
        body={txt('christmasFriends.faq.edit')}
        onPress={() => setEditExpanded(!editExpanded)}
        expanded={editExpanded}
      />

      <RenderItem
        title={txt('christmasFriends.faq.matchTitle')}
        body={txt('christmasFriends.faq.match')}
        onPress={() => setCloseExpanded(!closeExpanded)}
        expanded={closeExpanded}
      />

      <RenderItem
        title={txt('christmasFriends.faq.deadlineTitle')}
        body={txt('christmasFriends.faq.deadline')}
        onPress={() => setDeadlineExpanded(!deadlineExpanded)}
        expanded={deadlineExpanded}
      />

      <RenderItem
        title={txt('christmasFriends.faq.contactUsTitle')}
        body={txt('christmasFriends.faq.contactUs')}
        link={txt('christmasFriends.faq.contactLink')}
        onPress={() => setContactUsExpanded(!contactUsExpanded)}
        expanded={contactUsExpanded}
      />
    </MainContainer>
  )
}

const styles = StyleSheet.create({
  container: {
    alignSelf: 'center',
    backgroundColor: Colors.white,
    marginTop: 24,
    maxWidth: Layout.isSmallDevice ? '90%' : '60%',
    padding: 12,
    width: '100%',
    borderRadius: 4
  },
})
