import React, { useEffect, useState } from 'react'
import { View, StyleSheet } from 'react-native'
import GroupsAll from '../../components/views/Groups/GroupsAll'
import GroupsJoined from '../../components/views/Groups/GroupsJoined'
import { txt } from '../../locales/i18n'
import PageTitle from '../../components/tags/PageTitle'
import TxtTMD from '../../constants/TxtTMD'
import { CustomChip } from '../../components/chips/Chip'
import { useSelector } from 'react-redux'
import { useRoute } from '@react-navigation/native'
import { useNavigation } from '@react-navigation/core'
import GroupCreate from '../../components/views/Groups/GroupCreate'
import { URLOpener } from '../../utils/URLOpener'

export default function GroupsOverviewScreen(props) {
  const all = txt('groups.chipParams.all')
  const mine = txt('groups.chipParams.mine')
  const create = txt('groups.chipParams.create')

  const joinedGroups = useSelector((state) => state.groups)
  const userFeatures = useSelector((state) => state.user?.features)
  const [chipSelected, setChipSelected] = useState(all)
  const navigation = useNavigation()
  const route = useRoute()

  useEffect(() => {
    let chip = route.params?.chip
    if (Number(chip)) {
      navigation.navigate('Group', {
        id: chip,
      })
    } else if (!chip) {
      chip = joinedGroups?.length ? mine : all
      setChipParam(chip)
    }
  }, [])

  const setChipParam = (chip) => {
    setChipSelected(chip)
    navigation.setParams({ chip })
  }

  function renderChips(chip) {
    return (
      <View style={styles.chipContainer}>

        {/* All Groups */}
        <View style={styles.chip}>
          <CustomChip
            icon="users"
            selected={chip === all}
            onPress={() => setChipParam(all)}
            accessibilityLabel={txt('groups.allGroupsTitle')}
            text={txt('groups.allGroupsTitle')}
            />
        </View>

        {/* My Groups */}
        <View style={styles.chip}>
          <CustomChip
            icon="user"
            selected={chip === mine}
            onPress={() => setChipParam(mine)}
            accessibilityLabel={txt('groups.myGroupsTitle')}
            text={txt('groups.myGroupsTitle')}
          />
        </View>

        {/* Create Group */}
        {userFeatures.includes(5) && (
          <View style={styles.chip}>
            <CustomChip
              icon="users-medical"
              selected={chip === create}
              onPress={() => setChipParam(create)}
              accessibilityLabel={txt('groups.createGroupsTitle')}
              text={txt('groups.createGroupsTitle')}
            />
          </View>
        )}

        <View style={styles.chip}>
          <CustomChip
            icon="paperclip"
            iconStroke={'fal'}
            onPress={() => URLOpener('https://filer.boblberg.dk/Files/Guides/Guide_Grupper.pdf')}
            accessibilityLabel={txt('groups.guideGroupsTitle')}
            text={txt('groups.guideGroupsTitle')}
          />
        </View>
      </View>
    )
  }

  switch (chipSelected) {
    case mine:
      return (
        <>
          <PageTitle title={TxtTMD.myGroups.title[TxtTMD.lang]} />
          {renderChips(chipSelected)}
          <GroupsJoined navigateToGroup={props.navigateToGroup} groups={joinedGroups} />
        </>
      )
    case create:
      return (
        <>
          <PageTitle title={TxtTMD.createGroup.title[TxtTMD.lang]} />
          {renderChips(chipSelected)}
          <GroupCreate navigateToGroup={props.navigateToGroup} />
        </>
      )
    case all:
      return (
        <>
          <PageTitle title={TxtTMD.allGroups.title[TxtTMD.lang]} />
          <GroupsAll
            getGroups={props.getGroups}
            navigation={props.navigation}
            navigateToGroup={props.navigateToGroup}
            renderChips={() => renderChips(chipSelected)}
          />
        </>
      )
    default:
      return (
        <>
          <PageTitle title={TxtTMD.allGroups.title[TxtTMD.lang]} />
          <GroupsAll
            getGroups={props.getGroups}
            navigation={props.navigation}
            navigateToGroup={props.navigateToGroup}
            renderChips={() => renderChips(chipSelected)}
          />
        </>
      )
  }
}

const styles = StyleSheet.create({
  chip: {
    marginBottom: 5,
    marginLeft: 5,
    marginRight: 5,
  },
  chipContainer: {
    alignSelf: 'center',
    flexDirection: 'row',
  },
})
