import React, { useEffect, useState } from 'react'
import { View, Text, FlatList, Platform, StyleSheet, Pressable, Image } from 'react-native'
import ConversationListItem from './ConversationListItem'
import { txt } from '../../../locales/i18n'
import Colors from '../../../constants/Colors'
import { SpacingStyles, TypographyStyles } from '../../../styles'
import { useSelector } from 'react-redux'
import { useNavigation } from '@react-navigation/core'
import ConversationCreate from './ConversationCreate'
import { CustomText } from '../../text/StyledText'
import { isUserVolunteer } from '../../../services/api/volunteerApi'
import { handlePaymentClick } from '../../../utils/handlePaymentClick'

export default function InboxOverview(props) {
	const conversations = useSelector((state) => state.conversations)
	const user = useSelector((state) => state.user)
	const [isVolunteer, setIsVolunteer] = useState(false)
	const navigation = useNavigation()
	const [showSubscriptionCard, setShowSubscriptionCard] = useState(false)

	useEffect(() => {
		const hideSubscriptionCard = props.hideSubscriptionCard
		const hasFeatureOne = user?.features?.includes(1)
		setShowSubscriptionCard((hasFeatureOne || hideSubscriptionCard || isVolunteer) === false)
	}, [user, isVolunteer])

	useEffect(() => {
		isUserVolunteer().then(setIsVolunteer)
		navigation.setOptions({
			headerRight: () => <ConversationCreate />,
		})
	}, [])

	const renderConversationListItem = ({ item }) => (
		<ConversationListItem
			title={item.title}
			body={item.messages?.[0]?.body}
			user={user}
			unread_messages={item.unread_messages}
			image={item.img_filename}
			isChristmas={!!item.christmas_post_id_initiater}
			{...item}
			onPressAction={() => props.navigateToConversation(item.conversation_id)}
			goToPost={() => props.goToPost(item.post_id)}
			goToChristmasPost={props.goToChristmasPost}
			goToGroup={props.goToGroup}
			goToInformation={() => props.goToInformation(item.conversation_id)}
		/>
	)

	const SubscriptionCard = () => {
		async function openSubscriptionPage() {
			if (Platform.OS === 'web') window.plausible('SubscriptionClick', { props: { origin: 'inbox-card' } })
			await handlePaymentClick()
		}

		return (
			<Pressable
				accessibilityRole="button"
				accessibilityLabel={txt('subscription.inboxCard.text')}
				onPress={async () => openSubscriptionPage()}
				style={styles.subscriptionCardContainer}
			>
				<Image
					source={{ uri: 'https://filer.boblberg.dk/Assets/Img/employees/Emilie.jpg' }}
					style={styles.image}
					accessibilityIgnoresInvertColors
				/>
				<View style={{ marginLeft: 12, flex: 1, flexWrap: 'wrap' }}>
					<CustomText font="smallBold">{txt('subscription.inboxCard.title')}</CustomText>
					<CustomText font="small" style={styles.textRegular}>
						{txt('subscription.inboxCard.text')}
					</CustomText>
				</View>
			</Pressable>
		)
	}

	// no conversations view
	
	if (conversations?.length === 0) {
		return (
			<View style={[styles.container, styles.flex]}>
				{showSubscriptionCard && <SubscriptionCard />}
				<View style={styles.conversationContainer}>
					<View style={styles.margin10}>
						<Text style={styles.text}>{txt('conversations.noConversationsTitle')}</Text>
					</View>
					<View style={styles.margin10}>
						<Text style={styles.text}>{txt('conversations.noConversationsText')}</Text>
					</View>
				</View>
			</View>
		)
	}

	return (
		<View style={styles.flex}>
			<FlatList
				data={conversations}
				renderItem={renderConversationListItem}
				style={styles.container}
				keyExtractor={(item, index) => index.toString()}
				extraData={props}
				ListHeaderComponent={() => (showSubscriptionCard ? <SubscriptionCard /> : <ConversationCreate card />)}
			/>
		</View>
	)
}

const styles = StyleSheet.create({
	container: { ...SpacingStyles.widthAndHeight },
	conversationContainer: {
		flex: 2,
		justifyContent: Platform.OS === 'web' ? 'flex-start' : 'center',
		margin: 20,
	},
	flex: { flex: 1 },
	margin10: { margin: 10 },
	text: {
		...TypographyStyles.title,
		color: Colors.inactive,
		lineHeight: 35,
		textAlign: 'center',
	},
	subscriptionCardContainer: {
		padding: 6,
		marginHorizontal: 6,
		flexDirection: 'row',
		alignItems: 'center',
		borderWidth: 1,
		borderColor: Colors.greenLight,
		borderRadius: 10,
	},
	textRegular: {
		color: Colors.inactive,
		lineHeight: 22,
	},
	image: {
		borderRadius: 40,
		height: 66,
		width: 66,
	},
})
