import React, { useEffect, useState } from 'react'
import MainContainer from '../../../components/MainContainer/MainContainer'
import { getUniverseConfig } from '../../../universe/universeController'
import { useSelector } from 'react-redux'
import { Modules } from '../../../constants/Modules'
import { Group } from '../../../components/views/Groups/Group'

export default function GroupUniverseScreen(props) {
  const [universe, setUniverse] = useState({})
  const universeObject = getUniverseConfig(props.route?.params?.universe)
  const universes = useSelector((state) => state.user.universes)

  useEffect(() => {
    const universeName = props.route.params?.universe?.toLowerCase()
    const universe = universes.find((uni) => uni.name === universeName)
    setUniverse(universe)
    if (universe && !universe.modules.includes(Modules.GROUPS)) {
      props.navigation.popToTop()
    }
  }, [props, universes])

  function navigateToConversation(conversationId) {
    props.navigation.navigate('UniverseConversationStack', {
      screen: 'Conversation',
      initial: false,
      params: {
        universe: universe.name,
        id: conversationId,
      },
    })
  }

  const navigateToNewConversation = (userId) => {
    props.navigation.navigate('UniverseConversationStack', {
      screen: 'Conversation',
      initial: false,
      params: {
        universe: universe.name,
        id: 'new',
        receiverId: userId,
        groupId: props.route.params.id,
      },
    })
  }

  function navigateToGroups() {
    props.navigation.replace('Groups', {
      universe: universe.name,
    })
  }

  return (
    <MainContainer noScroll backgroundColor={universeObject?.backgroundColor}>
      <Group
        navigateToGroups={navigateToGroups}
        navigateToNewConversation={navigateToNewConversation}
        navigateToConversation={navigateToConversation}
      />
    </MainContainer>
  )
}
