import React from 'react'
import CustomDialog from '../dialogs/CustomDialog'
import { CustomText } from '../text/StyledText'
import { Platform, StyleSheet, TouchableOpacity, View } from 'react-native'
import Colors from '../../constants/Colors'
import NormalIcon from '../icons/NormalIcon'
import { ButtonPrimary } from '../buttons/StyledButtons'
import { txt } from "../../locales/i18n"
import { handlePaymentClick } from "../../utils/handlePaymentClick"

export default function PostFilterSubscriptionDialog({visible, close}) {

  async function handleOpenUrl() {
    if (Platform.OS === 'web') window.plausible('SubscriptionClick', { props: { origin: 'filter-popup' }})
    close()
	await handlePaymentClick()
  }

  return (
    <CustomDialog visible={visible}>
      <View style={styles.dialogContainer}>
        <TouchableOpacity style={styles.closeIcon} accessibilityRole="button" onPress={close}>
          <NormalIcon name="times" color={Colors.inactive} />
        </TouchableOpacity>
        <CustomText style={styles.title}>
          {txt('subscription.dialogs.subscriptionFilterDialog.title')}
        </CustomText>
        <CustomText style={{ marginTop: 12 }}>
          {txt('subscription.dialogs.subscriptionFilterDialog.intro')}
        </CustomText>
        <CustomText style={{ marginTop: 12 }}>
          {txt('subscription.dialogs.subscriptionFilterDialog.benefits')}
        </CustomText>
        <View style={{flexDirection: 'row', alignItems: 'center', marginTop: 18, marginLeft: 10}}>
          <View style={styles.point}>
            <NormalIcon name="check" size={14} color={Colors.greenIcon} />
          </View>
          <CustomText style={styles.pointText}>
            {txt('subscription.dialogs.subscriptionDialog.point1')}
          </CustomText>
        </View>
        <View style={styles.pointContainer}>
          <View style={styles.point}>
            <NormalIcon name="check" size={14} color={Colors.greenIcon} />
          </View>
          <CustomText style={styles.pointText}>
            {txt('subscription.dialogs.subscriptionDialog.point2')}
          </CustomText>
        </View>
        <View style={styles.pointContainer}>
          <View style={styles.point}>
            <NormalIcon name="check" size={14} color={Colors.greenIcon} />
          </View>
          <CustomText style={styles.pointText}>
            {txt('subscription.dialogs.subscriptionDialog.point3')}
          </CustomText>
        </View>
        <View style={styles.pointContainer}>
          <View style={styles.point}>
            <NormalIcon name="check" size={14} color={Colors.greenIcon} />
          </View>
          <CustomText style={styles.pointText}>
            {txt('subscription.dialogs.subscriptionDialog.point4')}
          </CustomText>
        </View>
        <View style={styles.pointContainer}>
          <View style={styles.point}>
            <NormalIcon name="check" size={14} color={Colors.greenIcon} />
          </View>
          <CustomText style={styles.pointText}>
            {txt('subscription.dialogs.subscriptionDialog.point5')}
          </CustomText>
        </View>
        <View style={styles.pointContainer}>
          <View style={styles.point}>
            <NormalIcon name="check" size={14} color={Colors.greenIcon} />
          </View>
          <CustomText style={styles.pointText}>
            {txt('subscription.dialogs.subscriptionDialog.point6')}
          </CustomText>
        </View>
      </View>
      <View style={{ paddingBottom: 24 }}>
        <ButtonPrimary
          onPress={handleOpenUrl}
          style={{ backgroundColor: Colors.greenBtn }}
          title={txt('subscription.dialogs.subscriptionDialog.button')}
          titleStyle={{ paddingHorizontal: 12 }}
        />
      </View>
    </CustomDialog>
  )
}

const styles = StyleSheet.create({
  dialogContainer: {
    margin: 24
  },
  closeIcon: {
    alignSelf: 'flex-end',
    position: 'absolute',
    paddingLeft: 24,
    zIndex: 1
  },
  title: {
    fontSize: 24,
    fontFamily: 'raleway-semibold',
    paddingRight: 25
  },
  pointContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 8,
    marginLeft: 10
  },
  point: {
    padding: 3,
    backgroundColor: Colors.greenExtraLight,
    borderRadius: 10
  },
  pointText: {
    paddingLeft: 6
  }
})