import React, { useState } from 'react'
import { Platform, StyleSheet, View } from 'react-native'

import { CustomText } from '../../../text/StyledText'
import CustomDialog from '../../../dialogs/CustomDialog'
import { ButtonPrimary } from '../../../buttons/StyledButtons'

import NormalIcon from '../../../icons/NormalIcon'

import { handlePaymentClick } from '../../../../utils/handlePaymentClick'
import Colors from '../../../../constants/Colors'
import { txt } from '../../../../locales/i18n'
import CustomPressable from "../../../pressables/CustomPressable"

export const PostFeedSubscriptionDialog = ({ visible, close }) => {
  const [disabled, setDisabled] = useState(false)

  const handleOpenSubScription = async () => {
    setDisabled(true)
    if (Platform.OS === 'web') window.plausible('SubscriptionClick', { props: { origin: 'post-feed' } })

    close()
    await handlePaymentClick()
    setDisabled(false)
  }

  return (
    <CustomDialog visible={visible} style={styles.dialog} onDismiss={close}>
      <View style={styles.contentContainer}>
        <CustomPressable style={styles.closeIcon} accessibilityRole="button" onPress={close}>
          <NormalIcon name="times" color={Colors.inactive} />
        </CustomPressable>
        <View>
          <CustomText style={styles.title} font="largeBold">
            {txt('subscription.dialogs.subscriptionFeedDialog.title')}
          </CustomText>

          <CustomText style={{ marginTop: 12 }}>
            {txt('subscription.dialogs.subscriptionFeedDialog.intro')}
          </CustomText>

          <View style={{ flexDirection: 'row', alignItems: 'center', marginTop: 18, marginLeft: 10 }}>
            <View style={styles.point}>
              <NormalIcon name="check" size={14} color={Colors.greenIcon} />
            </View>
            <CustomText style={styles.pointText}>{txt('subscription.dialogs.subscriptionDialog.point1')}</CustomText>
          </View>
          <View style={styles.pointContainer}>
            <View style={styles.point}>
              <NormalIcon name="check" size={14} color={Colors.greenIcon} />
            </View>
            <CustomText style={styles.pointText}>{txt('subscription.dialogs.subscriptionDialog.point2')}</CustomText>
          </View>
          <View style={styles.pointContainer}>
            <View style={styles.point}>
              <NormalIcon name="check" size={14} color={Colors.greenIcon} />
            </View>
            <CustomText style={styles.pointText}>{txt('subscription.dialogs.subscriptionDialog.point3')}</CustomText>
          </View>
          <View style={styles.pointContainer}>
            <View style={styles.point}>
              <NormalIcon name="check" size={14} color={Colors.greenIcon} />
            </View>
            <CustomText style={styles.pointText}>{txt('subscription.dialogs.subscriptionDialog.point4')}</CustomText>
          </View>
          <View style={styles.pointContainer}>
            <View style={styles.point}>
              <NormalIcon name="check" size={14} color={Colors.greenIcon} />
            </View>
            <CustomText style={styles.pointText}>{txt('subscription.dialogs.subscriptionDialog.point5')}</CustomText>
          </View>
          <View style={styles.pointContainer}>
            <View style={styles.point}>
              <NormalIcon name="check" size={14} color={Colors.greenIcon} />
            </View>
            <CustomText style={styles.pointText}>{txt('subscription.dialogs.subscriptionDialog.point6')}</CustomText>
          </View>
        </View>
      </View>

      <ButtonPrimary
        disabled={disabled}
        style={styles.button}
        onPress={handleOpenSubScription}
        title={txt('posts.promoteSubscriptionDialog.subscriptions')}
        accessibilityHint={txt('posts.promoteSubscriptionDialog.accessibilityHint')}
        accessibilityLabel={txt('posts.promoteSubscriptionDialog.accessibilityLabel')}
      />
    </CustomDialog>
  )
}

const styles = StyleSheet.create({
  dialog: {
    padding: 24,
  },
  contentContainer: {
    marginBottom: 24,
  },
  title: {
    color: Colors.blueAzure,
    marginBottom: 12,
    fontSize: 20,
  },
  button: {
    paddingHorizontal: 12,
    backgroundColor: Colors.greenBtn,
  },
  closeIcon: {
    alignSelf: 'flex-end',
    position: 'absolute',
    paddingLeft: 24,
    zIndex: 1,
  },
  pointContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 8,
    marginLeft: 10,
  },
  point: {
    padding: 3,
    backgroundColor: Colors.greenExtraLight,
    borderRadius: 10,
  },
  pointText: {
    paddingLeft: 6,
  },
})
