import React, { useCallback, useEffect, useState } from 'react'
import { Image, StyleSheet, TouchableOpacity, View, Platform } from 'react-native'
import { useSelector } from 'react-redux'
import { useRoute } from '@react-navigation/core'
import * as AuthSession from 'expo-auth-session'
import * as Linking from 'expo-linking'
import moment from 'moment'

import PageTitle from '../../components/tags/PageTitle'
import MainContainer from '../../components/MainContainer/MainContainer'
import { formatForMetaTitle } from '../../components/text/TextFormatter'
import LoadingSpinner from '../../components/activityIndicators/LoadingSpinner'
import { CustomText } from '../../components/text/StyledText'
import { ButtonPrimary } from '../../components/buttons/StyledButtons'

import NormalIcon from '../../components/icons/NormalIcon'

import Paths from '../../constants/Paths'
import Layout from '../../constants/Layout'
import Colors from '../../constants/Colors'

import * as EventService from '../../services/api/Events'
import { openMap } from '../../utils/openMap'
import { txt } from '../../locales/i18n'
import { URLOpener } from '../../utils/URLOpener'
import { addLinkToText } from '../../utils/addLinkToText.js'
import { getUniverseConfig } from '../../universe/universeController'
import { useDeepLinkingStatus } from '../../hooks/DeepLinkingStatus'

export const EventScreen = () => {
	const deeplinkStatus = useDeepLinkingStatus()
	const user = useSelector(({ user }) => user)
	moment.locale(user.language)

	const route = useRoute()
	const { eventId, universe } = route.params

	const universeObject = getUniverseConfig(universe?.toLowerCase())
	const [event, setEvent] = useState(null)
	const [isLoading, setIsLoading] = useState(true)
	const imageSource = Paths.imageEventUrlBase + event?.imageSource

	const handleOpenMap = () => {
		openMap(event.address, event.postalCode)
	}

	const getEvent = useCallback(async () => {
		setIsLoading(true)

		const event = await EventService.getEvent(eventId)
		setEvent(event)

		setIsLoading(false)
	}, [eventId])

	useEffect(() => {
		getEvent()
	}, [getEvent])

	const handleOpenEvent = async () => {
		const totalTickets = event?.totalTickets ?? null
		if (totalTickets === null) return URLOpener(event.bookingLink)

		const url = Paths.getBaseUrl() + '/oplev/' + eventId + '/checkout'
		if (Platform.OS === 'web') return window.open(url, '_blank')

		deeplinkStatus.set('pending')

		const appDeeplink = AuthSession.makeRedirectUri()
		const urlQuery = '?appDeeplink=' + encodeURIComponent(appDeeplink)
		await Linking.openURL(url + urlQuery)
	}

	return (
		<>
			<PageTitle title={event ? formatForMetaTitle(event?.title) : 'placeholder'}/>
			<MainContainer backgroundColor={universeObject?.backgroundColor}>
				<View style={styles.eventCardItem}>
					{event && (
						<View>
							<View style={{ position: 'relative' }}>
								<Image
									resizeMode="cover"
									style={styles.eventImage}
									accessibilityIgnoresInvertColors
									source={{ uri: imageSource }}
								/>

								{event?.totalTickets && (Number(event?.soldTickets) === Number(event.totalTickets)) ? (
									<View
										style={[styles.ticketStatus, { backgroundColor: 'rgba(227,32,32,0.85)' }]}>
										<CustomText font="smallBold" style={{ color: Colors.white }}>
											{txt('event.soldOut')}
										</CustomText>
									</View>
								) : (Number(event?.soldTickets) > (Number(event?.totalTickets) / 2)) ? (
									<View style={[styles.ticketStatus, { backgroundColor: 'rgba(241,201,170,0.85)' }]}>
										<CustomText font="smallBold" style={{ color: Colors.text }}>
											{txt('event.fewTicketsLeft')}
										</CustomText>
									</View>
								) : null}
							</View>

							<View style={styles.eventTextContentContainer}>

								<CustomText font="bold">{event.title}</CustomText>

								<CustomText font="small">
									{moment(event.dateStart).format('dddd lll')} - {moment(event.dateEnd).format('LT')}
								</CustomText>

								<View style={styles.gap}>
									<CustomText font="smallBold">{txt('event.description')}</CustomText>
									<CustomText font="small">{addLinkToText(event.body)}</CustomText>
								</View>

								<View style={styles.gap}>
									<CustomText font="smallBold">
										{txt('event.organizer')}
									</CustomText>
									<CustomText font="small">
										{event.organizer}
									</CustomText>
								</View>

								<View style={styles.gap}>
									<CustomText font="smallBold">
										{txt('event.address')}
									</CustomText>
									<CustomText font="small">
										{event.address}, {event.postalCode} {event.city}
									</CustomText>
									<TouchableOpacity
										accessibilityRole="button"
										accessibilityLabel={txt('event.openAddressLabel')}
										accessibilityHint={txt('event.openAddressHint')}
										onPress={handleOpenMap}
										style={{ flexDirection: 'row', alignItems: 'center' }}
									>
										<NormalIcon
											name="map-marker-alt"
											color={Colors.blueAzure}
											size={13}
											style={{ paddingRight: 3 }}
										/>
										<CustomText font="xsmallBold" style={{ color: Colors.blueAzure }}>{txt('event.showMap')}</CustomText>
									</TouchableOpacity>
								</View>
							</View>
						</View>
					)}

					<View style={{ backgroundColor: universeObject?.backgroundColor }}>
						{isLoading && <LoadingSpinner/>}
					</View>
				</View>
			</MainContainer>

			<View style={styles.stickyBottomTab}>

				<View>
					<CustomText font="smallBold">{txt('event.ticketPrice')}</CustomText>
					<CustomText font="smallBold" style={{ color: Colors.blueAzure }}>{event?.price}{txt('event.currency')}</CustomText>
				</View>

				<ButtonPrimary
					accessibilityRole="button"
					accessibilityLabel={txt('event.openBookingLinkLabel')}
					accessibilityHint={txt('event.openBookingLinkHint')}
					title={txt('event.buyTicket')}
					onPress={handleOpenEvent}
					style={styles.eventPriceButton}
					loading={deeplinkStatus.status === 'pending'}
					disabled={event?.totalTickets && (Number(event?.soldTickets) === Number(event?.totalTickets))}
				/>
			</View>

		</>
	)
}

const styles = StyleSheet.create({
	ticketStatus: {
		position: 'absolute',
		paddingHorizontal: 10,
		paddingVertical: 6,
		bottom: 6,
		left: 6,
		borderRadius: 18
	},

	eventCardItem: {
		padding: 12,
		alignSelf: 'center',
		maxWidth: Layout.maxFeedWidth,
		backgroundColor: Colors.white,
		borderTopRightRadius: 10,
		borderTopLeftRadius: 10,
	},

	eventImage: {
		height: Layout.small ? 200 : 350,
		borderRadius: 10,
	},

	eventTextContentContainer: {
		marginBottom: 75,
		padding: 12,
		gap: 24,
	},

	gap: {
		gap: 10,
	},

	stickyBottomTab: {
		padding: 12,
		alignSelf: 'center',
		width: '100%',
		backgroundColor: Colors.white,
		position: 'absolute',
		bottom: 0,
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-evenly',
		shadowColor: Colors.darkGrey, // IOS
		shadowOffset: { height: 1, width: 0 }, // IOS
		shadowOpacity: 0.15, // IOS
		shadowRadius: 15, //IOS
		elevation: 15, //Android
	},

	eventPriceButton: {
		minWidth: 200,
		backgroundColor: Colors.greenBtn,
		paddingVertical: 13,
		paddingHorizontal: 24,
		borderRadius: 10,
	},
})
