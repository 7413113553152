import CustomDialog, { ContentContainer } from '../../components/dialogs/CustomDialog'
import { View } from 'react-native'
import NormalIcon from '../../components/icons/NormalIcon'
import Colors from '../../constants/Colors'
import { CustomText } from '../../components/text/StyledText'
import { txt } from '../../locales/i18n'
import { ButtonPrimary } from '../../components/buttons/StyledButtons'
import React from 'react'
import CustomPressable from "../../components/pressables/CustomPressable"

export default function MatchDialog({ visible, close, title, description }) {
  return (
    <CustomDialog visible={visible} onDismiss={close}>
      <ContentContainer>
        <CustomPressable
          style={{ alignSelf: 'flex-end', position: 'absolute', paddingLeft: 24, zIndex: 1 }}
          accessibilityRole="button"
          onPress={close}
        >
          <NormalIcon name="times" color={Colors.inactive} />
        </CustomPressable>
        <CustomText style={{ fontSize: 20, fontFamily: 'raleway-semibold', marginVertical: 12 }}>
          {title}
        </CustomText>
        <CustomText>
          {description}
        </CustomText>
      </ContentContainer>
      <View style={{ paddingBottom: 16 }}>
        <ButtonPrimary
          onPress={close}
          style={{ backgroundColor: Colors.blueAzure }}
          title={txt('global.close')}
          titleStyle={{ paddingHorizontal: 12 }}
        />
      </View>
    </CustomDialog>
  )
}