import React, { useState, useEffect, useRef } from 'react'
import { txt } from '../../../locales/i18n'
import ActivityListItem from './ActivityListItem'
import SearchList, { ListSeparator } from '../../lists/SearchList'
import DatePicker from '../../../components/dates/DatePicker'
import { StyleSheet, View } from 'react-native'
import { CustomText } from '../../text/StyledText'
import NormalIcon from '../../icons/NormalIcon'
import moment from 'moment'
import CategoryDialog from '../../dialogs/CategoryDialog'
import Colors from '../../../constants/Colors'
import ActivitiesMap from '../../maps/ActivitiesMap'
import { ShadowStyles } from '../../../styles'
import Layout from '../../../constants/Layout'
import MunicipalityFilter from '../../shared/MunicipalityFilter'

import { useRoute } from '@react-navigation/core'
import CustomPressable from "../../pressables/CustomPressable"

export default function ActivityFeed(props) {
  moment.locale('da')
  const isUniverse = Boolean(props.route.params?.universe)
  const [showMap, setShowMap] = useState(false)
  const [renderMap, setRenderMap] = useState(false)
  const [selectedActivity, setSelectedActivity] = useState()
  const [municipalityFocus, setMunicipalityFocus] = useState(false)
  const route = useRoute()

  useEffect(() => {
    const chip = route.params?.chip
    setRenderMap(Boolean(chip) === false)
  }, [route.params])

  const date = useRef()
  const selectedCategories = useRef()
  const municipality = useRef()

  useEffect(() => {
    date.current = props.date
    selectedCategories.current = props.selectedCategories
    municipality.current = props.municipality
  }, [props])

  const triggerUpdate = () => {
    const isTriggable = shouldUpdate()
    if (isTriggable) {
      date.current = props.date
      selectedCategories.current = props.selectedCategories
      municipality.current = props.municipality
      return isTriggable
    }
    return isTriggable
  }

  const shouldUpdate = () => {
    if (date.current !== props.date) return true
    if (selectedCategories.current !== props.selectedCategories) return true
    if (municipality.current !== props.municipality) return true
    return false
  }

  const isInitialDate = () => {
    const date = new Date()
    return (
      date.getDate() === props.date.getDate() &&
      date.getMonth() === props.date.getMonth() &&
      date.getFullYear() === props.date.getFullYear()
    )
  }

  const CustomInput = ({ value, onClick }) => (
    <CustomPressable accessibilityRole="button" onPress={onClick} style={styles.customInput}>
      <NormalIcon style={{ marginRight: 6 }} name="calendar-alt" size={15} color={Colors.text} />
      {isInitialDate() ? <CustomText>{txt('activities.date')}</CustomText> : <CustomText>{value}</CustomText>}
    </CustomPressable>
  )

  const renderFilters = () => {
    return (
      <View style={styles.filterContainer}>
        <CategoryDialog selectedCategories={props.selectedCategories} setCategories={props.setCategories} />
        <DatePicker
          date={props.date}
          dateFormat={'dd-MM-yyyy'}
          handleDateChange={props.handleDateChange}
          minDate={new Date()}
          maxDate={new Date().setFullYear(new Date().getFullYear() + 5)}
          CustomInput={CustomInput}
        />
        {isUniverse === false && (
          <MunicipalityFilter
            onChange={props.setMunicipality}
            municipalityId={props.municipality}
            focus={municipalityFocus}
            setFocus={setMunicipalityFocus}
            description={txt('activities.municipalityDialog.body')}
            feature={2}
          />
        )}
      </View>
    )
  }

  const renderItem = ({ item, index }) => {
    if (item.isSeparator) {
      return <ListSeparator key={index} text={txt('listSeparators.activities')} />
    }

    return (
      <ActivityListItem
        item={item}
        onPressAction={() => {
          props.navigateToActivity(item.id, item.date_start)
        }}
      />
    )
  }

  const toggleMap = () => {
    setShowMap((prev) => !prev)
    setSelectedActivity(null)
  }

  const getListVisibility = () => {
    const isLargeDevice = Layout.isSmallDevice === false
    const hideMap = showMap === false
    return isLargeDevice || hideMap
  }

  function getMapVisibility() {
    const isLargeDevice = Layout.isSmallDevice === false
    return (isLargeDevice || showMap) && renderMap
  }

  return (
    <View style={[styles.webView, props.style]}>
      <View style={[styles.webSearchList, getListVisibility() ? {} : { display: 'none' }]}>
        <SearchList
          renderItem={renderItem}
          getData={props.getActivities}
          route={props.route}
          filterBelow={renderFilters}
          triggerUpdate={triggerUpdate}
          renderChips={props.renderChips}
          backgroundColor={props.backgroundColor}
        />
      </View>

      {getMapVisibility() && (
        <View style={Layout.isSmallDevice ? { flex: 1 } : styles.map}>
          <ActivitiesMap
            selectedActivity={selectedActivity}
            setSelectedActivity={setSelectedActivity}
            navigateToActivity={props.navigateToActivity}
            pins={props.pins}
          />
        </View>
      )}

      {Layout.isSmallDevice && (
        <CustomPressable style={[styles.mapBtn, { bottom: selectedActivity ? 148 : 16 }]} onPress={toggleMap}>
          <NormalIcon
            name={showMap ? "list" : "map"}
            style={{ color: Colors.white }}
            size={30}
            color={Colors.white}
            stroke="fal"
          />
        </CustomPressable>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  customInput: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
    marginHorizontal: 8,
  },
  filterContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    marginVertical: 8,
  },
  map: {
    flex: 1,
    marginBottom: 10,
    marginLeft: 10,
    marginRight: 10,
    maxWidth: 800,
  },
  mapBtn: {
    alignItems: 'center',
    backgroundColor: Colors.blue,
    borderRadius: 100,
    height: 64,
    justifyContent: 'center',
    position: 'absolute',
    right: 16,
    width: 64,
    ...ShadowStyles.dropShadow,
  },
  webSearchList: {
    flex: 1,
    maxWidth: 800,
  },
  webView: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    marginHorizontal: Layout.isSmallDevice ? 0 : 12,
  },
})
