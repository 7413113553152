import { useSelector } from 'react-redux'
import React, { useEffect, useRef, useState } from 'react'
import { View, Platform, TextInput, StyleSheet, TouchableOpacity, Keyboard } from 'react-native'
import * as Updates from 'expo-updates'
import Colors from '../../../constants/Colors'
import { txt } from '../../../locales/i18n'
import { CustomText } from '../../text/StyledText'
import { ButtonPrimary, RoundSquareBtn } from '../../buttons/StyledButtons'
import { SelectList } from '../../modalSelectors/SelectList'
import KeyboardSpacer from 'react-native-keyboard-spacer'
import {
	changePassword,
	createPasswordToken,
	getSubscription,
	getUserImage,
	updateUser,
} from '../../../services/api/User'
import { getActiveMunicipalities } from '../../../services/api/Municipalities'
import NormalIcon from '../../icons/NormalIcon'
import { NormalSnackbar } from '../../snackbars/Snackbar'
import { TypographyStyles, SpacingStyles } from '../../../styles'
import Fontsizes from '../../../constants/Fontsizes'
import { Picker } from '@react-native-picker/picker'
import { FormInput } from '../../text/StyledTextinputs'
import { InvalidInputNoticeCentered } from '../../notices/InvalidInputNotices'
import { MunicipalityInfoDialog, ReloadDialog } from './ProfileDialog'
import RegexPatterns from '../../../constants/RegexPatterns'
import { scaleImage } from '../../../utils/ImageHelper'
import ImagePicker from '../../ImagePicker/ImagePicker'
import { PhoneNumberInput } from '../../formElements/PhoneNumberInput'
import { countries } from '../../../services/localization'
import { handlePaymentClick } from '../../../utils/handlePaymentClick'
import { PasswordChanger } from './PasswordChanger'

function getDefaultCountryCode(countryName) {
	const country = countries.find((country) => country.name === countryName)
	return country?.region.toUpperCase() ?? 'DK'
}

export default function Profile(props) {
	const phoneInputRef = useRef(null)
	const userInfo = useSelector((state) => state.user)
	const municipalities = useSelector((state) => state.municipalities)
	const genderList = txt('genders')

	const [loading, setLoading] = useState(true)
	const [feedback, setFeedback] = useState({
		profileSaved: false,
		profileDidNotSave: false,
		showBackupLogOutBtn: false,
		passwordDidChange: false,
		expanded: false,
		usernameTooShort: false,
		emailNotValid: false,
		phoneNotValid: false,
		resetSend: false,
		activeMunicipality: false,
		showMunicipalityInfo: false,
		showMunicipalityChanged: false,
		isButtonDisabled: false,
		modalVisible: false,
	})
	const [passwordStatus, setPasswordStatus] = useState({
		passwordOld: '',
		passwordNew: '',
		passwordNewConfirm: '',
		passwordHiddenOld: true,
		passwordHiddenNew: true,
		passwordHiddenNewConfirm: true,
		newPasswordError: false,
		newPasswordErrorCode: 0,
	})
	const [newUserInfo, setNewUserInfo] = useState({ ...userInfo, phone: `${userInfo.phone ?? ''}` })
	const [birthyearList, setBirthyearList] = useState([])
	const [activeMunicipalities, setActiveMunicipalities] = useState([])
	const [imageInfo, setImageInfo] = useState({})
	const defaultCountryCode = getDefaultCountryCode(userInfo.country)
	const [image, setImage] = useState(null)
	const [subscription, setSubscription] = useState(null)
	const [invalidUsernameSymbolsVisible, setInvalidUsernameSymbolsVisible] = useState(false)
	const [reloadDialogVissible, setReloadDialogVissible] = useState(false)

	const active =
		subscription?.state === 'active' && subscription?.pending_invoices === 0 && subscription?.dunning_invoices === 0
	const newyearUniverses = ['nytaarsvenner', 'nyarsvanner']

	useEffect(() => {
		if (phoneInputRef.current !== null) {
			updateFeedback({
				phoneNotValid: !phoneInputRef.current.isValid(),
				isButtonDisabled: false,
			})
		}
	}, [newUserInfo.phone, newUserInfo.phone_prefix])

	useEffect(() => {
		getProfileImage()
		getYearList()
		getActiveMunicipalities().then(setActiveMunicipalities)
		getSubscription().then(setSubscription)

		// failsafe for people to logout even if profile isnt loaded
		setTimeout(() => {
			if (loading) updateFeedback({ showBackupLogOutBtn: true })
		}, 2000)

		setLoading(false)
	}, [userInfo])

	const getProfileImage = async () => {
		const image = await getUserImage(userInfo.id)
		if (image !== undefined) {
			setImageInfo((prev) => ({
				...prev,
				filename: image.filename,
				base64: image.base64,
			}))
		}
	}

	function updateFeedback(values) {
		setFeedback((preFeedback) => ({
			...preFeedback,
			...values,
		}))
	}

	function updatePasswordStatus(values) {
		setPasswordStatus((preStatus) => ({
			...preStatus,
			...values,
		}))
	}

	function updateNewUserInfo(values) {
		setNewUserInfo((preInfo) => ({
			...preInfo,
			...values,
		}))
	}

	function getYearList() {
		let yearStart = 1900
		let yearEnd = new Date().getFullYear() - 15
		let years = Array(yearEnd - yearStart + 1)
			.fill()
			.map(() => yearStart++)
		years.reverse()
		setBirthyearList(years)
	}

	function validEmail(email) {
		const pattern = RegexPatterns.email
		const conditions = pattern.map((rule) => new RegExp(rule, 'g'))
		const results = conditions.map((condition) => condition.test(email))
		return !results.includes(false)
	}

	function insertMunicipality() {
		return (
			<SelectList
				accessibilityLabel={txt('profile.municipality')}
				accessibilityHint={txt('profile.municipalityHint')}
				selectedValue={newUserInfo.municipality_id}
				onValueChange={(value) => {
					const id = value.id ? value.id : value
					updateNewUserInfo({ municipality_id: id })
				}}
				enabled={checkForActiveMunicipality() || active}
				list={municipalities}
				initValue={municipalities.find((m) => m.id === parseInt(newUserInfo.municipality_id)) || 'København'}
				specialID={true}
			>
				{municipalities.map((item) => {
					return <Picker.Item label={item.name} value={item.id} key={item.id} />
				})}
			</SelectList>
		)
	}

	function checkForActiveMunicipality() {
		if (userInfo.country !== 'Denmark') return true

		return activeMunicipalities.some((m) => m.id === parseInt(newUserInfo.municipality_id))
	}

	function insertEyeIcon(target, event) {
		return (
			<TouchableOpacity
				hitSlop={{
					top: 40,
					left: 40,
					bottom: 40,
					right: 40,
				}}
				accessibilityLabel={
					passwordStatus.passwordHiddenOld ? txt('profile.passwordChangeShow') : txt('profile.passwordChangeHide')
				}
				accessibilityHint={txt('profile.passwordChangeHint')}
				accessibilityLiveRegion="polite"
				accessibilityRole="button"
				onPress={() => updatePasswordStatus(event)}
			>
				<NormalIcon
					name={target ? "eye-slash" : "eye"}
					size={Fontsizes.l}
					color={Colors.inactive}
					stroke="fas"
				/>
			</TouchableOpacity>
		)
	}

	async function prepareProfileData() {
		Keyboard.dismiss()
		if (image?.uri) {
			const scaledImage = await scaleImage(image)
			if (scaledImage) return await saveProfile(scaledImage)
		}

		if (imageInfo?.base64) return await saveProfile(false)
		return await saveProfile()
	}

	async function saveProfile(image) {
		const data = {
			username: newUserInfo.username,
			email: newUserInfo.email,
			birth_year: newUserInfo.birth_year,
			gender_id: newUserInfo.gender_id,
			municipality_id: newUserInfo.municipality_id,
			phone: newUserInfo.phone,
			phone_prefix: newUserInfo.phone_prefix,
			image: null,
		}

		if (image) data.image = image
		if (image === false) data.image = false

		const inValidPhone = !phoneInputRef.current.isValid()
		if (data.username.length < 2 || data.email.length < 5 || !data.email.includes('@') || inValidPhone) {
			updateFeedback({
				isButtonDisabled: true,
				profileDidNotSave: !feedback.profileDidNotSave,
				phoneNotValid: inValidPhone,
			})
			return
		}

		if (data.username.includes('@')) {
			return setInvalidUsernameSymbolsVisible(true)
		}

		try {
			const didUpdate = await updateUser(data)
			if (!didUpdate) return updateFeedback({ profileDidNotSave: true })
			updateFeedback({ profileSaved: true })

			if (userInfo.municipality_id !== newUserInfo.municipality_id) {
				setReloadDialogVissible(true)

				setTimeout(() => {
					Platform.OS === 'web' ? window.location.reload() : Updates.reloadAsync()
				}, 20000)
			}
		} catch (error) {
			if (__DEV__) console.log('updating profile failed ' + error)
			updateFeedback({ profileDidNotSave: true })
		}
	}

	function showPasswordErrorMessage() {
		switch (passwordStatus.newPasswordErrorCode) {
			case 0:
				return txt('profile.passwordErrors.code0')
			case 1:
				return txt('profile.passwordErrors.code1')
			case 2:
				return txt('profile.passwordErrors.code2')
			case 3:
				return txt('profile.passwordErrors.code3')
			default:
				return txt('profile.passwordErrors.code0')
		}
	}

	function keyboardSpacerShow() {
		if (Platform.OS === 'ios') {
			return <KeyboardSpacer />
		}
	}

	const handleOpenSubscription = async () => {
		await handlePaymentClick(null, true)
	}

	function subscriptionStatus() {
		if (subscription?.is_cancelled) return txt('subscription.cancelled')
		if (subscription?.pending_invoices > 0 || subscription?.dunning_invoices > 0) return txt('subscription.missingPayment')
		if (subscription?.state === 'expired')  return txt('subscription.expired')
		if (subscription?.state === 'active' && !subscription?.is_cancelled) return txt('subscription.active')
		return
	}

	function subscriptionIsActive() {
		if (
			subscription?.state === 'active' &&
			subscription?.is_cancelled === false &&
			subscription?.pending_invoices === 0 &&
			subscription?.dunning_invoices === 0
		) {
			return true
		}
		return false
	}

	function SubscriptionBanner() {
		return (
			<TouchableOpacity
				onPress={handleOpenSubscription}
				accessibilityRole="button"
				style={[
					styles.subscription,
					newyearUniverses.includes(props.universe?.name) && { borderWidth: 0.5, borderColor: Colors.greyLightLight },
					!subscriptionIsActive() && { backgroundColor: Colors.redLight },
				]}
			>
				<CustomText style={!subscriptionIsActive() && { color: Colors.white }}>
					{subscriptionStatus()}
				</CustomText>
				<NormalIcon name="chevron-right" color={!subscriptionIsActive() && Colors.white} size={16} />
			</TouchableOpacity>
		)
	}

	return (
		<View style={styles.widthHeight}>
			{!loading && (
				<View>
					<View style={styles.imagePickerContainer}>
						<ImagePicker
							editMode
							setImage={setImage}
							defaultImage={imageInfo?.base64 ?? null}
							onDelete={() => setImageInfo((imageInfo) => ({ ...imageInfo, base64: null }))}
							placeholder={{ uri: 'https://filer.boblberg.dk/Assets/Img/avatar.jpg' }}
							placeholderText={false}
						/>
					</View>

					<CustomText font="small" style={styles.instructionText}>
						{txt('profile.instructions')}
					</CustomText>

					{subscription?.handle && (
						<SubscriptionBanner />
					)}

					{/* Username */}
					<View
						style={[
							styles.cornersTop,
							styles.inputContainer,
							newyearUniverses.includes(props.universe?.name) && {
								borderWidth: 0.5,
								borderColor: Colors.greyLightLight,
							},
						]}
					>
						<View>
							<CustomText style={styles.smallTitle}>{txt('profile.username')}</CustomText>

							<View>
								<TextInput
									accessibilityLabel={txt('profile.username')}
									accessibilityHint={txt('profile.usernameHint')}
									style={[styles.text, styles.marginBottom5]}
									value={newUserInfo.username}
									onChangeText={(text) => {
										if (text.length < 2) {
											updateNewUserInfo({ username: text })
											updateFeedback({
												usernameTooShort: true,
												isButtonDisabled: true,
											})
										} else {
											setInvalidUsernameSymbolsVisible(false)
											updateNewUserInfo({ username: text })
											updateFeedback({
												usernameTooShort: false,
												isButtonDisabled: false,
											})
										}
									}}
								/>
								{feedback.usernameTooShort ? (
									<CustomText style={styles.error}>{txt('profile.usernameTooShort')}</CustomText>
								) : null}

								{invalidUsernameSymbolsVisible && (
									<CustomText style={styles.error}>{txt('profile.usernameInvalidSymbol')}</CustomText>
								)}
							</View>
						</View>
					</View>

					{/* Email new */}

					<View
						style={[
							styles.inputContainer,
							newyearUniverses.includes(props.universe?.name) && {
								borderWidth: 0.5,
								borderColor: Colors.greyLightLight,
							},
						]}
					>
						<View>
							<CustomText style={styles.smallTitle}>{txt('profile.email')}</CustomText>

							<View>
								<TextInput
									accessibilityLabel={txt('profile.email')}
									accessibilityHint={txt('profile.emailHint')}
									autoComplete={'email'}
									autoCapitalize="none"
									style={[styles.text, styles.marginBottom5]}
									inputMode="email"
									value={newUserInfo.email}
									onChangeText={(email) => {
										const valid = validEmail(email)
										updateNewUserInfo({ email })
										updateFeedback({
											emailNotValid: !valid,
											isButtonDisabled: !valid,
										})
									}}
								/>
								{feedback.emailNotValid ? (
									<CustomText style={styles.error}>{txt('profile.emailNotValid')}</CustomText>
								) : null}
							</View>
						</View>
					</View>

					{/* Birthyear */}
					<View
						style={[
							styles.inputContainer,
							styles.spaceBetween,
							newyearUniverses.includes(props.universe?.name) && {
								borderWidth: 0.5,
								borderColor: Colors.greyLightLight,
							},
						]}
					>
						<CustomText style={styles.smallTitle}>{txt('profile.birthYear')}</CustomText>
						<View style={styles.flex}>
							<SelectList
								accessibilityLabel={txt('profile.birthYear')}
								accessibilityHint={txt('profile.birthYearHint')}
								selectedValue={newUserInfo.birth_year}
								onValueChange={(birth_year) => {
									updateNewUserInfo({ birth_year })
								}}
								list={birthyearList}
								initValue={newUserInfo.birth_year.toString() || '1970'}
							>
								{birthyearList.map((item) => {
									return <Picker.Item label={item} value={item} key={item} />
								})}
							</SelectList>
						</View>
					</View>

					{/* Gender new */}
					<View
						style={[
							styles.inputContainer,
							styles.spaceBetween,
							newyearUniverses.includes(props.universe?.name) && {
								borderWidth: 0.5,
								borderColor: Colors.greyLightLight,
							},
						]}
					>
						<CustomText style={styles.smallTitle}>{txt('profile.gender')}</CustomText>
						<View style={styles.flex}>
							<SelectList
								accessibilityLabel={txt('profile.gender')}
								accessibilityHint={txt('profile.genderHint')}
								selectedValue={newUserInfo.gender_id}
								onValueChange={(gender_id) => {
									if (Platform.OS === 'web') {
										updateNewUserInfo({ gender_id })
									} else {
										const id = (element) => element === gender_id
										updateNewUserInfo({ gender_id: genderList.findIndex(id) })
									}
								}}
								list={genderList}
								initValue={genderList[newUserInfo.gender_id] || genderList[0]}
							>
								{genderList.map((item, index) => {
									return <Picker.Item label={item} value={index} key={item} />
								})}
							</SelectList>
						</View>
					</View>

					{/* Municipality new */}
					<View
						style={[
							styles.inputContainer,
							styles.spaceBetween,
							newyearUniverses.includes(props.universe?.name) && {
								borderWidth: 0.5,
								borderColor: Colors.greyLightLight,
							},
						]}
					>
						<CustomText style={styles.smallTitle}>{txt('profile.municipality')}</CustomText>
						{checkForActiveMunicipality() || active ? (
							<View style={styles.flex}>{insertMunicipality()}</View>
						) : (
							<View style={styles.flex}>
								<TouchableOpacity
									onPress={() =>
										updateFeedback({
											showMunicipalityInfo: !feedback.showMunicipalityInfo,
										})
									}
									style={!feedback.activeMunicipality ? styles.disabled : ''}
								>
									{insertMunicipality()}
								</TouchableOpacity>
							</View>
						)}
					</View>

					{/* Phone new */}
					<View
						style={[
							styles.inputContainer,
							styles.cornerBottom,
							newyearUniverses.includes(props.universe?.name) && {
								borderWidth: 0.5,
								borderColor: Colors.greyLightLight,
							},
						]}
					>
						<CustomText style={styles.smallTitle}>{txt('profile.phone')}</CustomText>
						<PhoneNumberInput
							ref={phoneInputRef}
							phoneNumber={newUserInfo.phone}
							defaultCountryCode={defaultCountryCode}
							setPhoneNumber={(phone) => updateNewUserInfo({ phone })}
							callCode={newUserInfo.phone_prefix}
							setCallCode={(phone_prefix) => updateNewUserInfo({ phone_prefix })}
							style={{ shadowColor: Colors.transparent, elevation: 0, paddingHorizontal: 0 }}
							placeholder={txt('profile.noPhone')}
							accessibilityLabel={txt('profile.phone')}
							accessibilityHint={txt('profile.phoneHint')}
						/>
						{feedback.phoneNotValid && (
							<CustomText style={styles.error}>{txt('invalidNotice.invalidPhone')}</CustomText>
						)}
					</View>

					{/* Password */}
					<View
						style={[
							styles.inputContainer,
							styles.column,
							styles.cornersTop,
							styles.cornerBottom,
							newyearUniverses.includes(props.universe?.name) && {
								borderWidth: 0.5,
								borderColor: Colors.greyLightLight,
							},
						]}
					>
						{Platform.OS !== 'web' && <PasswordChanger />}

						{Platform.OS === 'web' && (
							<TouchableOpacity
								accessibilityLabel={feedback.expanded ? txt('profile.collapse') : txt('profile.expand')}
								accessibilityHint={txt('profile.expandHint')}
								accessibilityRole="button"
								accessibilityLiveRegion="polite"
								onPress={() => updateFeedback({ expanded: !feedback.expanded })}
							>
								<View style={styles.passwordTitleContainer}>
									<CustomText style={styles.text}>{txt('profile.changePassword')}</CustomText>
									<NormalIcon
										name={feedback.expanded ? "arrow-circle-up" : "arrow-circle-down"}
										color={Colors.inactive}
										size={Fontsizes.xl}
									/>
								</View>
							</TouchableOpacity>
						)}

						{feedback.expanded ? (
							<View style={styles.marginTop}>
								<View style={styles.marginTop}>
									<CustomText style={styles.smallTitle}>{txt('profile.password')}</CustomText>
									<FormInput
										accessibilityLabel={txt('profile.password')}
										accessibilityHint={txt('profile.passwordHint')}
										value={passwordStatus.passwordOld}
										placeholder={''}
										onChangeText={(text) => {
											updatePasswordStatus({
												passwordOld: text,
											})
										}}
										secureTextEntry={passwordStatus.passwordHiddenOld}
									>
										{insertEyeIcon(passwordStatus.passwordHiddenOld, {
											passwordHiddenOld: !passwordStatus.passwordHiddenOld,
										})}
									</FormInput>
								</View>
								<View style={styles.marginTop}>
									<CustomText style={[styles.smallTitle, styles.marginTop]}>{txt('profile.passwordNew')}</CustomText>
									<FormInput
										accessibilityLabel={txt('profile.passwordNew')}
										accessibilityHint={txt('profile.passwordHintNew')}
										value={passwordStatus.passwordNew}
										placeholder={''}
										onChangeText={(text) => {
											updatePasswordStatus({
												passwordNew: text,
											})
										}}
										secureTextEntry={passwordStatus.passwordHiddenNew}
									>
										{insertEyeIcon(passwordStatus.passwordHiddenNew, {
											passwordHiddenNew: !passwordStatus.passwordHiddenNew,
										})}
									</FormInput>
								</View>
								<View style={styles.marginTop}>
									<CustomText style={[styles.smallTitle, styles.marginTop]}>
										{txt('profile.passwordNewConfirm')}
									</CustomText>
									<FormInput
										accessibilityLabel={txt('profile.passwordNewConfirm')}
										accessibilityHint={txt('profile.passwordHintConfirm')}
										value={passwordStatus.passwordNewConfirm}
										placeholder={''}
										onChangeText={(text) => {
											updatePasswordStatus({
												passwordNewConfirm: text,
											})
										}}
										secureTextEntry={passwordStatus.passwordHiddenNewConfirm}
									>
										{insertEyeIcon(passwordStatus.passwordHiddenNewConfirm, {
											passwordHiddenNewConfirm: !passwordStatus.passwordHiddenNewConfirm,
										})}
									</FormInput>
								</View>
								<View style={styles.passwordContainer}>
									<RoundSquareBtn
										title={txt('profile.changePassword')}
										onPress={async () => {
											if (
												passwordStatus.passwordNew.trim().length === 0 ||
												passwordStatus.passwordNewConfirm.trim().length === 0
											) {
												updatePasswordStatus({
													newPasswordError: true,
													newPasswordErrorCode: 1,
												})
												return
											}

											if (passwordStatus.passwordNew === passwordStatus.passwordNewConfirm) {
												const didChangePassword = await changePassword(
													passwordStatus.passwordOld,
													passwordStatus.passwordNew
												)
												if (didChangePassword.status_code === 200) {
													updatePasswordStatus({
														passwordOld: '',
														passwordNew: '',
														passwordNewConfirm: '',
														newPasswordError: false,
														newPasswordErrorCode: 0,
														passwordDidChange: true,
													})
												} else if (didChangePassword.status_msg === 'Invalid password') {
													updatePasswordStatus({
														newPasswordError: true,
														newPasswordErrorCode: 2,
													})
												} else {
													updatePasswordStatus({
														newPasswordError: true,
														newPasswordErrorCode: 3,
													})
												}
											} else {
												updatePasswordStatus({
													newPasswordError: true,
													newPasswordErrorCode: 0,
												})
												return
											}
										}}
									/>

									<CustomText font="small" style={{ marginTop: 24, marginBottom: 6 }}>
										{!feedback.resetSend ? txt('profile.forgottenPassword') : txt('profile.forgottenPasswordSend')}
									</CustomText>

									<TouchableOpacity
										accessible
										accessibilityRole="button"
										onPress={async () => {
											createPasswordToken(newUserInfo.email)
											updateFeedback({ resetSend: true })
										}}
									>
										<CustomText font="smallbold" style={{ textAlign: 'center' }}>
											{txt('profile.forgottenPasswordReset')}
										</CustomText>
									</TouchableOpacity>

									{passwordStatus.newPasswordError ? (
										<InvalidInputNoticeCentered>{showPasswordErrorMessage()}</InvalidInputNoticeCentered>
									) : null}
								</View>
							</View>
						) : null}
					</View>

					{/* Save changes */}
					<View style={{ paddingVertical: 10, flexDirection: 'row', justifyContent: 'space-evenly' }}>
						<ButtonPrimary
							title={txt('profile.seeProfile')}
							iconRight="chevron-right"
							iconSize={16}
							onPress={props.navigateToProfileView}
						/>
						<ButtonPrimary
							title={txt('profile.saveChanges')}
							disabled={feedback.isButtonDisabled}
							onPress={prepareProfileData}
						/>
					</View>

					{/* Reload dialog */}
					<ReloadDialog visible={reloadDialogVissible} />

					{/* MunicipalityInfo */}
					<MunicipalityInfoDialog
						visible={!!feedback.showMunicipalityInfo}
						onPress={() =>
							updateFeedback({
								showMunicipalityInfo: !feedback.showMunicipalityInfo,
							})
						}
					/>

					<NormalSnackbar visible={!!feedback.profileSaved} onDismiss={() => updateFeedback({ profileSaved: false })}>
						{txt('post.savePostSnackBar')}
					</NormalSnackbar>

					<NormalSnackbar
						visible={!!feedback.profileDidNotSave}
						onDismiss={() => updateFeedback({ profileDidNotSave: false })}
					>
						{txt('post.savePostSnackBarFailed')}
					</NormalSnackbar>

					<NormalSnackbar
						visible={!!feedback.passwordDidChange}
						onDismiss={() => updateFeedback({ passwordDidChange: false })}
					>
						{txt('profile.passwordDidChange')}
					</NormalSnackbar>
					{keyboardSpacerShow()}
				</View>
			)}
		</View>
	)
}

const styles = StyleSheet.create({
	column: { flexDirection: 'column' },
	cornerBottom: {
		borderBottomLeftRadius: 10,
		borderBottomRightRadius: 10,
		marginBottom: 2,
	},
	cornersTop: { borderTopLeftRadius: 10, borderTopRightRadius: 10 },
	disabled: { opacity: 0.7 },
	error: {
		...TypographyStyles.textSmall,
		color: Colors.errorBackground,
		marginBottom: 5,
		marginLeft: 10,
	},
	inputContainer: {
		backgroundColor: Colors.white,
		flexDirection: 'column',
		justifyContent: 'space-between',
		marginTop: 1,
		padding: 12,
	},
	instructionText: {
		color: Colors.inactive,
		marginBottom: 12,
		marginTop: 12,
		textAlign: 'center',
	},
	marginBottom5: { marginBottom: 5 },
	marginTop: { marginTop: 12 },
	passwordContainer: {
		alignSelf: 'center',
		marginBottom: 20,
		marginRight: 10,
		marginTop: 20,
	},
	passwordTitleContainer: {
		alignItems: 'center',
		flexDirection: 'row',
		justifyContent: 'space-between',
	},
	smallTitle: {
		...TypographyStyles.textSmall,
		color: Colors.inactive,
	},
	spaceBetween: { justifyContent: 'space-between' },
	text: { ...TypographyStyles.text },
	widthHeight: {
		flex: 1,
		...SpacingStyles.widthAndHeight,
		padding: 12,
	},
	imagePickerContainer: {
		display: 'flex',
		alignItems: 'center',
	},
	subscription: {
		backgroundColor: Colors.white,
		padding: 12,
		borderRadius: 10,
		marginBottom: 12,
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
})
