import CustomDialog, { ContentContainer } from '../../dialogs/CustomDialog'
import { CustomText } from '../../text/StyledText'
import { View } from 'react-native'
import { ButtonPrimary, ButtonSecondary } from '../../buttons/StyledButtons'
import React from 'react'
import { txt } from '../../../locales/i18n'

export default function SproglandJuniorWaitListDialog({ visible, onDismiss, submit }) {
  return (
    <CustomDialog visible={visible} onDismiss={onDismiss}>
      <ContentContainer>
        <CustomText>
          {txt('learnDanish.junior.waitList.dialogText')}
        </CustomText>
        <View style={{ marginTop: 12 }}>
          <ButtonSecondary
            title={txt('learnDanish.junior.waitList.cancel')}
            style={{ width: '100%', marginTop: 12 }}
            icon="times"
            stroke={'fal'}
            onPress={onDismiss}
          />
          <ButtonPrimary
            title={txt('learnDanish.junior.waitList.submit')}
            style={{ width: '100%', marginTop: 12 }}
            icon="check"
            stroke={'fal'}
            onPress={submit}
          />
        </View>
      </ContentContainer>
    </CustomDialog>
  )
}

export function SproglandWaitListDialog({ visible, onDismiss, submit }) {
  return (
    <CustomDialog visible={visible} onDismiss={onDismiss}>
      <ContentContainer>
        <CustomText>
          {txt('learnDanish.waitList.dialogText')}
        </CustomText>
        <View style={{ marginTop: 12 }}>
          <ButtonSecondary
            title={txt('learnDanish.waitList.cancel')}
            style={{ width: '100%', marginTop: 12 }}
            icon="times"
            stroke={'fal'}
            onPress={onDismiss}
          />
          <ButtonPrimary
            title={txt('learnDanish.waitList.submit')}
            style={{ width: '100%', marginTop: 12 }}
            icon="check"
            stroke={'fal'}
            onPress={submit}
          />
        </View>
      </ContentContainer>
    </CustomDialog>
  )
}