export const groupThemes = [
  {
    id: 0,
    primary: '#2a6592',
    // secondary: '#ffffff',
    background: '#EDF7F9FF',
  },
  {
    id: 1,
    primary: '#FEC71E',
    // secondary: '#FFF3D7',
    background: '#FEE9AD',
    buttonText: '#333333',
  },
  {
    id: 2,
    primary: '#EE7602',
    // secondary: '#FFF0E1',
    background: '#FFD7A9',
  },
  {
    id: 3,
    primary: '#d72d39',
    // secondary: '#FEE3E4',
    background: '#FFACAC',
  },
  {
    id: 4,
    primary: '#DD166B',
    // secondary: '#FEE3EE',
    background: '#FEBFD9',
  },
  {
    id: 5,
    primary: '#701EB9',
    // secondary: '#F1E0FF',
    background: '#D5D9FE',
  },
  {
    id: 6,
    primary: '#008766',
    // secondary: '#DEF9F2',
    background: '#C5F1E7',
  },
]