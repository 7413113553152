import React from 'react'
import { Image, Platform, StyleSheet, View } from 'react-native'
import moment from 'moment'

import { CustomText } from '../text/StyledText'
import NormalIcon from '../../components/icons/NormalIcon'

import Colors from '../../constants/Colors'
import { ShadowStyles } from '../../styles'
import { txt } from '../../locales/i18n'
import CustomPressable from "../pressables/CustomPressable"
import * as Linking from "expo-linking"
import { generateUUID } from "../../services/api/User"
import Layout from "../../constants/Layout"
import Paths from "../../constants/Paths"

export const EventListItem = ({ event, navigateToEvent }) => {
	const isPlusMemberEvent = event?.plusMembersOnly ?? false

	const handleOpenLink = async () => {
		const uuid = await generateUUID()

		const url = `${event.bookingLink}?plusMember=${uuid}`

		if (Platform.OS === 'web') {
			window.open(url, '_blank')
		} else await Linking.openURL(url)
	}

	return (
		<CustomPressable
			accessibilityHint={txt('events.eventListItemHint')}
			accessibilityLabel={txt('events.eventListItemLabel')}
			accessibilityRole="button"
			onPress={isPlusMemberEvent ? handleOpenLink : navigateToEvent}
			style={styles.cardContainer}
		>
			<View style={styles.eventCard}>
				<View style={{ position: 'relative' }}>
					<Image
						accessibilityIgnoresInvertColors
						style={styles.eventImage}
						source={{ uri: Paths.imageEventUrlBase + event.imageSource }}
					/>
					{event?.totalTickets && (Number(event?.soldTickets) === Number(event.totalTickets)) ? (
						<View style={[styles.ticketStatusContainer, { backgroundColor: 'rgba(227,32,32,0.85)' }]}>
							<CustomText font="xsmallBold" style={{ color: Colors.white }}>
								{txt('event.soldOut')}
							</CustomText>
						</View>
					) : (Number(event.soldTickets) > (Number(event.totalTickets) / 2) ? (
						<View style={[styles.ticketStatusContainer, { backgroundColor: 'rgba(241,201,170,0.85)' }]}>
							<CustomText font="xsmallBold" style={{ color: Colors.text }}>
								{txt('event.fewTicketsLeft')}
							</CustomText>
						</View>
					) : null)}
				</View>

				{isPlusMemberEvent ? (
					<View style={[styles.bannerContainer, { backgroundColor: 'rgba(255,125,0,0.85)' }]}>
						<CustomText font="xsmallBold" style={{ color: Colors.white }}>
							{txt('events.plusMember')}
						</CustomText>
					</View>
				) : (
					<View style={[styles.bannerContainer, { backgroundColor: Colors.whiteTransparent }]}>
						<CustomText font="xsmallBold" style={{ color: Colors.text }}>
							{moment(event.dateStart).format('ddd. LL')}
						</CustomText>
					</View>
				)}

				<View style={styles.eventTextContainer}>
					<CustomText font="smallBold" numberOfLines={2}>{event.title}</CustomText>

					<CustomText font="xsmall" numberOfLines={2}>
						{event.teaser ?? event.body.replace(/([\uE000-\uF8FF]|\uD83C[\uDF00-\uDFFF]|\uD83D[\uDC00-\uDDFF])/g, '')}
					</CustomText>

					{isPlusMemberEvent ? (
						<CustomText font="xsmallBold" style={{ color: Colors.orange }}>
							{txt('events.plusMemberPrice', { price: event.price })}
						</CustomText>
					) : (

						<View style={{ flexDirection: 'row', alignItems: 'center' }}>
							<NormalIcon
								stroke="fal"
								size={12}
								name="map-marker-alt"
								color={Colors.textLight}
								style={{ marginRight: 3 }}
							/>
							<CustomText font="xsmall" style={{ color: Colors.textLight }}>
								{event.postalCode}, {event.city}
							</CustomText>
						</View>
					)}
				</View>
			</View>
		</CustomPressable>
	)
}

const styles = StyleSheet.create({
	cardContainer: {
		width: Layout.small ? '100%' : '50%',
		paddingHorizontal: 12,
		paddingVertical: 8,
	},

	eventCard: {
		backgroundColor: Colors.white,
		borderRadius: 12,
		...ShadowStyles.cardShadow,
		flex: 1,
	},

	eventImage: {
		borderTopRightRadius: 6,
		borderTopLeftRadius: 6,
		height: 150,
	},

	ticketStatusContainer: {
		position: 'absolute',
		paddingHorizontal: 10,
		paddingVertical: 6,
		bottom: 6,
		left: 6,
		borderRadius: 18
	},

	bannerContainer: {
		position: 'absolute',
		backgroundColor: Colors.whiteTransparent,
		paddingHorizontal: 10,
		paddingVertical: 6,
		top: 6,
		right: 6,
		borderRadius: 18
	},

	eventTextContainer: {
		padding: 12,
		gap: 6,
		flex: 1,
		justifyContent: 'space-between',
	},

})
