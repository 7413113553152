import React, { useState } from 'react'
import { View, StyleSheet } from 'react-native'
import { deleteBooking } from '../../../services/api/LearnDanish'
import { CustomText } from '../../text/StyledText'
import { txt } from '../../../locales/i18n'
import Colors from '../../../constants/Colors'
import { ButtonPrimary, ButtonSecondary } from '../../buttons/StyledButtons'
import { ShadowStyles, TypographyStyles } from '../../../styles'
import moment from 'moment'
import CustomDialog, { ActionButton, ContentContainer } from '../../dialogs/CustomDialog'
import { getConversationId } from '../../../services/api/Inbox'
import TextLabel from './TextLabel'
import startVideoSession from '../../../utils/startVideoSession'
import { getJuniorConsent } from '../../../services/api/LearnDanish'
import { SproglandJuniorConsentDialog } from '../../dialogs/SproglandJuniorConsentDialog'

export default function OwnBookedSessions(props) {
  //sets locale to danish time
  moment.locale([txt('videoChat.locale')])

  const [currentItem, setCurrentItem] = useState('')
  const [dialogVisibleBooked, setDialogVisibleBooked] = useState(false)
  const [showJuniorConsentDialog, setShowJuniorConsentDialog] = useState(false)


  function formatUnixDate(date) {
    var d = moment(date).format('dddd [d.] DD/MM [kl.] HH:mm')
    return d.charAt(0).toUpperCase() + d.substr(1)
  }

  async function handleSendMessage(item) {
    const { bookerId, id } = item
    if (!bookerId || !id) return
    const conversationId = await getConversationId({
      slSessionId: id,
      receiverId: bookerId,
    })
    if (conversationId) props.navigateToConversation(conversationId)
    else props.navigateToNewConversation(bookerId, id)
  }

  async function handleStartPress(item) {
	if (item.junior) {
      const consentGiven = await getJuniorConsent()
      setShowJuniorConsentDialog(!consentGiven)
      if (!consentGiven) return
    }

    let junior = item.junior
    await startVideoSession(item.room_key, item.vonage_session_id, junior ? 'sproglandJunior' : 'sprogland')
  }

  return (
    <View style={styles.container}>
      {props.ownBookedSessions.length === 0 ? (
        <View>
          <CustomText
            font="largeBold"
            style={{ textAlign: 'center' }}
            accessibilityRole="header"
            aria-level={2}
          >
            {txt('videoChat.ownBookedSessions')}
          </CustomText>
          <CustomText font="small" style={styles.noSession}>{txt('videoChat.noOwnBookedSessions')}</CustomText>
        </View>
      ) : (
        <CustomText
          font="largeBold"
          style={{ textAlign: 'center' }}
          accessibilityRole="header"
          aria-level={2}
        >
          {txt('videoChat.ownBookedSessions')}
        </CustomText>
      )}

      {props.ownBookedSessions.map((item, key) => {
        var date = formatUnixDate(item.start_date)
        return (
          <View key={key} style={styles.sessionContainer}>
            <View style={styles.textContainer}>
              <CustomText font="small">{date}</CustomText>
              <CustomText
                font="small"
                text={txt('videoChat.duration') + ': ' +
                (new Date(item.end_date).getTime() - new Date(item.start_date).getTime()) / 1000 / 60 +
                ' min.'}
              />
              {!item.junior && (
                <CustomText
                  font="small"
                  text={txt('videoChat.bookedBy') + ': ' + item.username}
                  numberOfLines={1}
                />
              )}
              {item.junior && (
                <CustomText font="small" numberOfLines={1}>
                  {txt('videoChat.bookedBy') + ': ' + item.juniorName + ' - ' + item.juniorAge + ' ' + txt('learnDanish.junior.juniorAge')}
                </CustomText>
              )}
              <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
                {item.firstSession === true && <TextLabel first style={{ marginRight: 6, marginTop: 6 }} />}
                {item.categories?.map(id => (
                  <TextLabel
                    key={id}
                    text={txt(`learnDanish.categories.${id}`)}
                    style={{ marginRight: 6, marginTop: 6 }}
                  />
                ))}
                {item.junior && <TextLabel junior style={{ marginTop: 6 }} />}
              </View>
            </View>
            <View style={styles.buttonsContainer}>
              <View style={styles.buttonStartContainer}>
                <ButtonPrimary
                  title={txt('videoChat.start')}
                  titleStyle={{ ...TypographyStyles.textSmallBold }}
                  style={styles.button}
                  onPress={() => handleStartPress(item)}
                  icon="video"
                  iconSize={16}
                  stroke="fal"
                />
              </View>
              <View style={styles.buttonConversationContainer}>
                <ButtonSecondary
                  title={txt('conversations.messageSend')}
                  titleStyle={{ ...TypographyStyles.textSmallBold }}
                  style={[styles.button, { borderWidth: 1 }]}
                  onPress={() => handleSendMessage(item)}
                  icon="paper-plane"
                  iconSize={16}
                  stroke="fal"
                />
              </View>
              <View style={styles.buttonCancelContainer}>
                <ButtonSecondary
                  title={txt('videoChat.cancel')}
                  titleStyle={{ ...TypographyStyles.textSmallBold, color: Colors.redCrossRed }}
                  style={[styles.button, { borderWidth: 1, borderColor: Colors.redCrossRed }]}
                  onPress={() => {
                    setCurrentItem(item)
                    setDialogVisibleBooked(true)
                  }}
                  icon="times"
                  iconSize={16}
                  color={Colors.redCrossRed}
                  stroke="fal"
                />
              </View>
            </View>
          </View>
        )
      })}
      <CustomDialog visible={dialogVisibleBooked}>
        <ContentContainer>
          <CustomText>{txt('videoChat.deleteDialogText')}</CustomText>
        </ContentContainer>
        <ActionButton
          onPress={async () => {
            const res = await deleteBooking(currentItem.id, currentItem.junior)
            if (res) await props.reloadAll()
            setDialogVisibleBooked(false)
          }}
        >
          {txt('videoChat.dialogYes')}
        </ActionButton>
        <ActionButton onPress={() => setDialogVisibleBooked(false)}>{txt('videoChat.dialogNo')}</ActionButton>
      </CustomDialog>

      <SproglandJuniorConsentDialog
        visible={showJuniorConsentDialog}
        setVisible={setShowJuniorConsentDialog}
        onCancel={() => setShowJuniorConsentDialog(false)}
        acceptOnly
      />
    </View>
  )
}

const styles = StyleSheet.create({
  buttonCancelContainer: {
    flex: 1,
    justifyContent: 'center',
  },
  buttonConversationContainer: {
    flex: 1,
    justifyContent: 'center',
    paddingBottom: 10,
    paddingRight: 2,
  },
  buttonStartContainer: {
    flex: 1,
    justifyContent: 'center',
    paddingBottom: 10,
    paddingRight: 2,
  },
  buttonsContainer: {
    flexDirection: 'column',
    justifyContent: 'space-evenly',
  },
  button: {
    width: 150,
    paddingHorizontal: 8,
  },
  container: {
    margin: 10,
  },
  noSession: {
    color: Colors.inactive,
    marginTop: 10,
    textAlign: 'center',
    backgroundColor: Colors.white,
    borderRadius: 2,
    padding: 10,
    ...ShadowStyles.liftedShadow,
  },
  sessionContainer: {
    backgroundColor: Colors.white,
    borderRadius: 2,
    flexDirection: 'row',
    marginTop: 10,
    padding: 10,
    ...ShadowStyles.liftedShadow,
    justifyContent: 'space-between',
  },
  textContainer: {
    justifyContent: 'center',
    paddingRight: 6,
    flex: 2,
  },
})
