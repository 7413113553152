import React, { useState } from 'react'
import { TouchableOpacity, StyleSheet, Platform } from 'react-native'

import { CustomText } from '../../text/StyledText'
import { ButtonPrimary } from '../../buttons/StyledButtons'

import { txt } from '../../../locales/i18n'
import Colors from '../../../constants/Colors'
import Fontsizes from '../../../constants/Fontsizes'

import { handlePaymentClick } from '../../../utils/handlePaymentClick'
import { ShadowStyles } from '../../../styles'

export const SubscriptionPromoCard = ({ universe = null, title, description, origin }) => {
	const [disabled, setDisabled] = useState(false)

	const handleOpenSubScription = async () => {
		setDisabled(true)
		if (Platform.OS === 'web') window.plausible('SubscriptionClick', { props: { origin: origin } })
		await handlePaymentClick()
		setDisabled(false)
	}

	return (
		<TouchableOpacity
			disabled={disabled}
			accessible
			accessibilityRole="button"
			accessibilityHint={txt('posts.promoteSubscriptionCard.accessibilityHint')}
			accessibilityLabel={txt('posts.promoteSubscriptionCard.accessibilityLabel')}
			onPress={handleOpenSubScription}
			style={[styles.container, universe && { ...ShadowStyles.shadowInputs }]}
		>
			<CustomText style={styles.title} font="bold">
				{title}
			</CustomText>

			<CustomText font="small">{description}</CustomText>

			<ButtonPrimary
				disabled={disabled}
				style={styles.button}
				onPress={handleOpenSubScription}
				title={txt('posts.promoteSubscriptionCard.button')}
				titleStyle={{ fontSize: Fontsizes.s }}
				accessibilityHint={txt('posts.promoteSubscriptionCard.accessibilityHint')}
				accessibilityLabel={txt('posts.promoteSubscriptionCard.accessibilityLabel')}
			/>
		</TouchableOpacity>
	)
}

const styles = StyleSheet.create({
	container: {
		margin: 12,
		borderRadius: 12,
		paddingVertical: 12,
		paddingHorizontal: 24,
		backgroundColor: Colors.white,
	},

	title: {
		marginBottom: 6,
		color: Colors.blueAzure,
	},

	button: {
		marginTop: 12,
		paddingHorizontal: 12,
		backgroundColor: Colors.greenBtn,
	},
})
