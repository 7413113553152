import { universes } from './universeConfig'

export function isUniverse(name) {
  const universe = universes.find(
    (universe) => universe.name === name?.toLowerCase()
  )
  return !!universe
}

export function getUniverseId(name) {
  const universe = universes.find(
    (universe) => universe.name === name?.toLowerCase()
  )
  return universe?.id
}

export function getUniverseConfig(name) {
  const universe = universes.find(
    (universe) => universe.name === name?.toLowerCase()
  )
  return universe
}

export function getUniverseConfigById(id) {
  const universe = universes.find((universe) => universe.id === id)
  return universe
}
