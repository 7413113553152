import React, { useEffect, useState } from 'react'
import GroupsIntroScreen from '../../Groups/GroupsIntro'
import GroupsOverviewScreen from '../../Groups/GroupsOverviewScreen'
import { useSelector } from 'react-redux'
import MainContainer from '../../../components/MainContainer/MainContainer'
import { searchGroups } from '../../../services/api/Groups'
import { getUniverseConfig } from '../../../universe/universeController'
import { Modules } from '../../../constants/Modules'

export default function GroupsUniverseScreen(props) {
  const userToken = useSelector((state) => state.userToken)
  const universes = useSelector((state) => state.user.universes)
  const [universe, setUniverse] = useState(null)
  const universeObject = getUniverseConfig(props.route?.params?.universe)

  useEffect(() => {
    const universeName = props.route.params?.universe?.toLowerCase()
    const universe = universes.find((uni) => uni.name === universeName)
    setUniverse(universe)
    if (universe && !universe.modules?.includes(Modules.GROUPS)) {
      props.navigation.popToTop()
    }
  }, [props, universes])

  const navigateToGroup = (groupId) => {
    props.navigation.navigate('Group', {
      id: groupId,
      universe: universe.name,
    })
  }

  const getGroups = async ({search, skip, amount}) => {
    return searchGroups({ search, skip, amount })
  }

  return (
    <MainContainer noScroll backgroundColor={universeObject?.backgroundColor}>
      {userToken ? (
        <GroupsOverviewScreen
          getGroups={getGroups}
          navigation={props.navigation}
          navigateToGroup={navigateToGroup}
        />
      ) : (
        <GroupsIntroScreen navigation={props.navigation} />
      )}
    </MainContainer>
  )
}
