import React, { useState, useEffect } from 'react'
import { View, StyleSheet, Platform } from 'react-native'
import * as Application from 'expo-application'

import OptionsList from '../../components/lists/OptionsList'
import TxtTMD from '../../constants/TxtTMD'
import PageTitle from '../../components/tags/PageTitle'
import MainContainer from '../../components/MainContainer/MainContainer'
import { useSelector } from 'react-redux'
import { txt } from '../../locales/i18n'
import { selectUnreadGroupMessages } from '../../redux/selectors'
import layout from '../../constants/Layout'
import { getExtraTabs } from '../../navigation/MenuConfig'
import { joinUniverseById } from '../../services/api/Universe'
import { Features } from '../../constants/Features'
import { getRegion } from '../../services/localization'
import { URLOpener } from "../../utils/URLOpener"
import Paths from "../../constants/Paths"
import { handlePaymentClick } from "../../utils/handlePaymentClick"
import { getVolunteerAccess } from "../../services/api/volunteerApi"

export default function StackMenuScreen(props) {
	const [data, setData] = useState([])
	const universes = useSelector((state) => state.user?.universes)
	const features = useSelector((state) => state.user?.features)
	const joinedGroups = useSelector((state) => state?.groups)
	const groupsBadge = useSelector(selectUnreadGroupMessages)
	const extraTabs = getExtraTabs(features, joinedGroups?.length ?? 0)
	const region = getRegion()

	const handleOpenSubscriptionPage = async () => {
		await handlePaymentClick()
	}

	const handleOpenWhatIsBoblberg = async () => {
		const url = Paths.getBaseUrl()
		const page = region === 'de' ? url + '/was-ist-boblberg' : region === 'se' ? url + '/vad-ar-boblberg' : url + '/hvad-er-boblberg'
		await URLOpener(page)
	}

	const navigateToWhatIsBoblberg = () => {
		const nativeApplicationVersion = Application.nativeApplicationVersion

		if (nativeApplicationVersion === '4.4.0' || nativeApplicationVersion === '5.0.0' || Platform.OS === 'web') {
			return props.navigation.navigate('WhatIsBoblberg')
		}

		return handleOpenWhatIsBoblberg()
	}

	useEffect(() => {
		const stackList = [
			{
				body: txt('videoChat.title'),
				icon: { name: "comments" },
				onPress: async () => {
				const isVolunteer = await getVolunteerAccess('snaksammen') ?? false
				const isWeb = Platform.OS === 'web'
				if (isVolunteer?.isVolunteer) return props.navigation.navigate('SnakSammenVolunteer')

				if (isWeb === true) return window.location.replace(Paths.getBaseUrl() + 'snaksammen')
				props.navigation.navigate('SnakSammen')
				},
				hide: region !== 'dk',
			},
			{
				body: txt('learnDanish.title'),
				icon: { name: "globe-europe" },
				onPress: () => props.navigation.navigate('LearnDanish'),
				hide: region !== 'dk',
			},
			{
				body: txt('stackMenu.stories'),
				icon: { name: "book-open" },
				onPress: () => props.navigation.navigate('Stories'),
				hide: region !== 'dk',
			},
			{
				body: txt('about.title'),
				icon: { name: "bobl", boblbergIcon: true },
				onPress: () => props.navigation.navigate('About'),
				hide: region !== 'dk',
			},
			{
				body: txt('contact.title'),
				icon: { name: "info-circle" },
				onPress: () => props.navigation.navigate('Contact'),
				hide: false,
			},
			{
				body: txt('profile.title'),
				icon: { name: "user" },
				onPress: () => props.navigation.navigate('Profile'),
				hide: false,
			},
			{
				body: txt('settings.title'),
				icon: { name: "cog" },
				onPress: () => props.navigation.navigate('Settings'),
				hide: false,
			},
		]

		if (Platform.OS === 'web') {
			const cookieButton = {
				body: txt('cookieLabel'),
				onPress: () => showCookieBanner(),
				icon: { name: "cookie-bite" },
				hide: false,
			}

			stackList.splice(stackList.length - 2, 0, cookieButton)
		}

		if (extraTabs.includes('groups') === false) {
			stackList.unshift({
				badge: groupsBadge,
				body: txt('groups.title'),
				icon: { name: "users" },
				onPress: () => props.navigation.navigate('Groups'),
				hide: region !== 'dk',
			})
		}

		if (extraTabs.includes('activities') === false && features.includes(Features.SEARCH_ACTIVITIES)) {
			stackList.unshift({
				body: txt('activities.title'),
				icon: { name: "calendar-day" },
				onPress: () => props.navigation.navigate('Activities'),
				hide: region !== 'dk',
			})
		}

		if (extraTabs.includes('unions') === false) {
			stackList.unshift({
				body: txt('unions.title'),
				icon: { name: "users-class" },
				onPress: () => props.navigation.navigate('Unions'),
				hide: region !== 'dk',
			})
		}

		if (extraTabs.includes('events') === false) {
			stackList.unshift({
				body: txt('events.title'),
				icon: { name: "calendar-star" },
				onPress: () => props.navigation.navigate('Events'),
				hide: region !== 'dk',
			})
		}

		if (!features.includes(1)) {
			stackList.unshift({
				body: txt('subscription.title'),
				onPress: handleOpenSubscriptionPage,
				icon: { name: "plus-circle" },
				hide: false,
			})
		}

		stackList.unshift({
			body: txt('stackMenu.whatIs'),
			onPress: navigateToWhatIsBoblberg,
			icon: { name: "question-circle" },
			hide: false,
		})

		universes?.forEach((universe) => {
			const newYearUniverses = ['nytaarsvenner', 'nyarsvanner']
			if (newYearUniverses.includes(universe.name)) return

			stackList.unshift({
				body: txt('boblberg.goTo') + universe.title,
				icon: { name: "bubbles", boblbergIcon: true },
				hide: region !== 'dk',
				onPress: () => {
					props.navigation.push('universe', {
						screen: 'UniverseBoblStack',
						params: { universe: universe.name },
					})
				},
			})
		})

		// stackList.unshift({
		// 	body: txt('stackMenu.nytaarsvenner'),
		// 	hide: region !== 'dk',
		// 	onPress: async () => {
		// 		const universe = universes.find((uni) => uni.name === 'nytaarsvenner')
		// 		if (!universe) await joinUniverseById(10)
		// 		props.navigation.navigate('universe', {
		// 			screen: 'UniverseBoblStack',
		// 			params: {
		// 				screen: 'Universe',
		// 				initial: true,
		// 				params: { universe: 'nytaarsvenner' },
		// 			},
		// 		})
		// 	},
		// 	icon: { name: "glass-cheers" },
		// })

		// stackList.unshift({
		// 	body: txt('stackMenu.nytaarsvenner'),
		// 	hide: region !== 'se',
		// 	onPress: async () => {
		// 		const universe = universes.find((uni) => uni.name === 'nyarsvanner')
		// 		if (!universe) await joinUniverseById(14)
		// 		props.navigation.navigate('universe', {
		// 			screen: 'UniverseBoblStack',
		// 			params: {
		// 				screen: 'Universe',
		// 				initial: true,
		// 				params: { universe: 'nyarsvanner' },
		// 			},
		// 		})
		// 	},
		// 	icon: { name: "glass-cheers" },
		// })

		stackList.unshift({
			body: txt('christmasFriends.title'),
			onPress: () => props.navigation.navigate('christmasFriends'),
			icon: { name: "hat-santa" },
			hide: region !== 'dk',
		})

		setData(stackList)
	}, [universes, props.navigation, features, joinedGroups, groupsBadge])

	return (
		<MainContainer>
			<PageTitle title={TxtTMD.stackMenu.title[TxtTMD.lang]} />
			<View style={styles.container}>
				<OptionsList data={data.filter((options) => options.hide === false)} />
			</View>
		</MainContainer>
	)
}

const styles = StyleSheet.create({
	container: {
		alignSelf: 'center',
		width: '100%',
		maxWidth: layout.maxFeedWidth,
	},
})
