import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Image, Platform, StyleSheet, View } from 'react-native'
import * as Updates from 'expo-updates'
import ModalSelector from 'react-native-modal-selector'
import AsyncStorage from '@react-native-async-storage/async-storage'

import { txt } from '../../locales/i18n'
import Colors from '../../constants/Colors'
import { CustomText } from '../text/StyledText'
import { TypographyStyles } from '../../styles'
import { updateUserPreferences } from '../../services/api/User'
import { getLanguage } from '../../services/localization'
import CustomDialog from '../dialogs/CustomDialog'
import { ButtonPrimary } from '../buttons/StyledButtons'

const da = 'https://filer.boblberg.dk/Assets/Img/flags/da.png'
const en = 'https://filer.boblberg.dk/Assets/Img/flags/en.png'
const sv = 'https://filer.boblberg.dk/Assets/Img/flags/sv.png'
const fi = 'https://filer.boblberg.dk/Assets/Img/flags/fi.png'
const uk = 'https://filer.boblberg.dk/Assets/Img/flags/uk.png'
const de = 'https://filer.boblberg.dk/Assets/Img/flags/de.png'
const ar = 'https://filer.boblberg.dk/Assets/Img/flags/ar.png'

const Label = ({ img, label = null }) => (
  <View style={styles.img}>
    <Img img={img} />
    <CustomText style={{ paddingLeft: 6 }}>{label}</CustomText>
  </View>
)

const Img = ({ img }) => (
  <Image
    resizeMode="contain"
    accessibilityIgnoresInvertColors
    source={{ uri: img }}
    style={[
      styles.flag,
      img === 'https://filer.boblberg.dk/Assets/Img/flags/fi.png' && { borderColor: Colors.blackTransparent },
    ]}
  />
)

export function LanguagePicker({ showSelectedLabel = false, style = null }) {
  const preferences = useSelector((state) => state?.preferences ?? null)
  const [initValue, setInitValue] = useState(null)
  const [updateError, setUpdateError] = useState(null)

  useEffect(() => {
    getInitValue().then(setInitValue)
  }, [])

  const languages = [
    {
      lang: 'da',
      accessibilityLabel: txt('languages.danish'),
      label: <Label img={da} label={txt('languages.danish')} />,
      symbol: (
        <View style={styles.img}>
          <Img img={da} />
        </View>
      ),
    },
    {
      lang: 'en',
      accessibilityLabel: txt('languages.english'),
      label: <Label img={en} label={txt('languages.english')} />,
      symbol: (
        <View style={styles.img}>
          <Img img={en} />
        </View>
      ),
    },
    {
      lang: 'sv',
      accessibilityLabel: txt('languages.swedish'),
      label: <Label img={sv} label={txt('languages.swedish') + ' (App)'} />,
      symbol: (
        <View style={styles.img}>
          <Img img={sv} />
        </View>
      ),
    },
    {
      lang: 'fi',
      accessibilityLabel: txt('languages.finnish'),
      label: <Label img={fi} label={txt('languages.finnish') + ' (App)'} />,
      symbol: (
        <View style={styles.img}>
          <Img img={fi} />
        </View>
      ),
    },
    {
      lang: 'uk',
      accessibilityLabel: txt('languages.ukrainian'),
      label: <Label img={uk} label={txt('languages.ukrainian') + ' (Sprogland)'} />,
      symbol: (
        <View style={styles.img}>
          <Img img={uk} />
        </View>
      ),
    },
    {
      lang: 'de',
      accessibilityLabel: txt('languages.german'),
      label: <Label img={de} label={txt('languages.german')} />,
      symbol: (
        <View style={styles.img}>
          <Img img={de} />
        </View>
      ),
    },
    {
      lang: 'ar',
      accessibilityLabel: txt('languages.arabic'),
      label: <Label img={ar} label={txt('languages.arabic') + ' (Sprogland)'} />,
      symbol: (
        <View style={styles.img}>
          <Img img={ar} />
        </View>
      ),
    },
  ]

  const getInitValue = async () => {
    if (preferences === null) {
      const lang = await getLanguage()
      const initValue = languages.find((language) => language.lang === lang)

      if (showSelectedLabel) return initValue.label
      return initValue.symbol
    }

    const initValue = languages.find((language) => language.lang === preferences.language) ?? null

    if (initValue === null) {
      const lang = await getLanguage()
      const initValue = languages.find((language) => language.lang === lang)

      if (showSelectedLabel) return initValue.label
      return initValue.symbol
    }

    if (showSelectedLabel) return initValue.label
    return initValue.symbol
  }

  useEffect(() => {
    getInitValue().then(setInitValue)
  }, [])

  if (initValue === null) return

  const handleLanguageChange = async (language) => {
    if (preferences === null) {
      await AsyncStorage.setItem('language', language.toLowerCase())
      return Platform.OS === 'web' ? window.location.reload() : Updates.reloadAsync()
    }

    const updatedPreferences = await updateUserPreferences({ language })

    if (updatedPreferences === null) {
      return setUpdateError(txt('settings.failedToSave'))
    }

    Platform.OS === 'web' ? window.location.reload() : Updates.reloadAsync()
  }

  return (
    <>
      <ModalSelector
        data={languages}
        animationType="fade"
        accessible
        listItemAccessible
        scrollViewAccessibilityLabel={txt('languagePicker.scrollViewAccessibilityLabel')}
        backdropPressToClose
        initValueTextStyle={styles.text}
        selectStyle={styles.border}
        optionStyle={styles.optionStyle}
        optionTextStyle={styles.optionTextStyle}
        optionContainerStyle={[
          styles.optionContainer,
          { maxHeight: languages.length * (Platform.OS === 'ios' ? 44 : Platform.OS === 'android' ? 50 : 55) },
        ]}
        cancelButtonAccessible
        cancelStyle={styles.cancelStyle}
        cancelButtonAccessibilityLabel={txt('languagePicker.cancel')}
        style={style}
        touchableStyle={styles.touchableStyle}
        keyExtractor={(language) => language.lang}
        labelExtractor={(language) => language.label}
        initValue={initValue}
        onChange={(language) => handleLanguageChange(language.lang)}
      />

      <CustomDialog visible={updateError !== null} onDismiss={() => setUpdateError(null)}>
        <View style={styles.errorContainer}>
          <CustomText style={styles.errorText}>{updateError}</CustomText>

          <ButtonPrimary
            title={txt('global.ok')}
            onPress={() => setUpdateError(null)}
            style={styles.errorDismissButton}
            titleStyle={styles.errorDismissButtonText}
          />
        </View>
      </CustomDialog>
    </>
  )
}

const styles = StyleSheet.create({
  flag: {
    height: 20,
    width: 30,
    borderWidth: 0.5,
    borderRadius: 3,
    borderColor: Colors.transparent,
  },

  border: {
    borderColor: Colors.transparent,
    borderWidth: 0,
    marginTop: Platform.OS === 'web' ? 0 : 6,
  },

  optionStyle: {
    borderBottomWidth: 0,
    alignSelf: 'flex-start',
  },

  optionTextStyle: {
    alignSelf: 'flex-start',
  },

  optionContainer: {
    backgroundColor: Colors.white,
    maxHeight: 500,
    alignSelf: 'center',
    alignItems: 'center',
    paddingVertical: 20,
  },

  cancelStyle: {
    display: 'none',
  },

  touchableStyle: {
    padding: 0,
    alignSelf: 'flex-start',
  },

  text: {
    ...TypographyStyles.text,
    color: Colors.text,
  },

  img: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingBottom: Platform.OS === 'android' ? 6 : 0,
    paddingTop: Platform.OS === 'web' ? 3 : 0,
  },

  errorContainer: {
    margin: 24,
  },

  errorText: {
    paddingHorizontal: 12,
    paddingVertical: 6,
    color: Colors.errorText,
    borderRadius: 6,
  },

  errorDismissButton: {
    backgroundColor: Colors.blueAzure,
  },

  errorDismissButtonText: {
    paddingHorizontal: 12,
  },
})
