import React, { useState } from 'react'
import { StyleSheet } from 'react-native'
import { CheckBox } from 'react-native-elements'
import CustomDialog, {
  ActionButton,
  ContentContainer,
} from '../../../dialogs/CustomDialog'
import Colors from '../../../../constants/Colors'
import { txt } from '../../../../locales/i18n'
import { CustomText } from '../../../text/StyledText'
import CustomPressable from "../../../pressables/CustomPressable"

const DataConsentDialog = (props) => {
  const [error, setError] = useState('')

  const handleSend = () => {
    if (props.consent) {
      props.send()
      setError('')
    } else {
      setError(txt('groups.dataResponsibilityConsentRequired'))
    }
  }

  const handleCheckPress = () => {
    setError('')
    props.toggleConsent()
  }

  return (
    <CustomDialog visible={props.open} onDismiss={props.cancel}>
      <ContentContainer>
        <CustomText>
          {txt('groups.dataResponsibilityConsent') +
            props.dataResponsible || ''}
        </CustomText>
        <CustomPressable style={styles.checkBoxContainer} onPress={handleCheckPress}>
          <CheckBox checked={props.consent} onPress={handleCheckPress} />
          <CustomText>
            {txt('groups.dataResponsibilityAccept') +
              props.dataResponsible || ''}
          </CustomText>
        </CustomPressable>
        {!!error && (
          <CustomText style={{ color: Colors.red }}>{error}</CustomText>
        )}
      </ContentContainer>
      <ActionButton onPress={props.cancel}>
        {txt('groups.cancel')}
      </ActionButton>
      <ActionButton onPress={handleSend}>
        {txt('groups.accessText.access')}
      </ActionButton>
    </CustomDialog>
  )
}

const styles = StyleSheet.create({
  checkBoxContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
  },
})

export default DataConsentDialog
