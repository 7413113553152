import React from 'react'
import { View, Platform } from 'react-native'
import GroupItem from './GroupItem'
import KeyboardSpacer from 'react-native-keyboard-spacer'
import SearchList from '../../lists/SearchList'

export default function GroupsAll(props) {
  const renderGroupItem = ({item}) => (
    <GroupItem
      group={item}
      navigateToGroup={props.navigateToGroup}
    />
)
  return (
    <View style={{ flex: 1 }}>
      <SearchList
        renderItem={renderGroupItem}
        getData={props.getGroups}
        center={true}
        renderChips={props.renderChips}
      />
      {Platform.OS === 'ios' && <KeyboardSpacer />}
    </View>
  )
}
