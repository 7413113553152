import React, { useCallback, useEffect, useRef, useState } from 'react'
import SearchList, { ListSeparator } from '../../lists/SearchList'
import PostItem from './PostItem'
import { txt } from '../../../locales/i18n'
import { CreatePostCard } from './CreatePostCard'
import PostFilter from './PostFilter'
import { useSelector } from 'react-redux'
import { useRoute } from '@react-navigation/core'
import LoadingSpinner from '../../activityIndicators/LoadingSpinner'
import { saveSearchString } from '../../../services/api/Posts'
import { Features } from '../../../constants/Features'

import { useSetAtom } from 'jotai'
import { SeClickedPostIdsAtom, DkClickedPostIdsAtom, DkClickedNewyearPostIdsAtom } from '../../../atoms'

import * as AdvertisementService from '../../../services/api/Advertisement'
import { Advertisement } from '../../advertisement'

const thisYear = new Date().getFullYear()

export default function PostFeed(props) {
  const route = useRoute()
  const setSePostFeedIds = useSetAtom(SeClickedPostIdsAtom)
  const setDkPostFeedIds = useSetAtom(DkClickedPostIdsAtom)
  const setDkNewyearPostFeedIds = useSetAtom(DkClickedNewyearPostIdsAtom)

  const birthYear = useSelector((state) => state.user.birth_year)
  const country = useSelector((state) => state.user.country)
  const userFeatures = useSelector((state) => state.user.features)

  const defaultMaxDistance = 750
  let defaultAgeLow
  let defaultAgeHigh

  if (props.universe && props.universe?.name !== 'nytaarsvenner' || country === 'Germany') {
    defaultAgeLow = 15
    defaultAgeHigh = 99
  } else {
    defaultAgeLow = Math.max(Math.round((thisYear - birthYear - 20) / 5) * 5, 15)
    defaultAgeHigh = Math.min(Math.round((thisYear - birthYear + 20) / 5) * 5, 99)
  }

  const [filterVisible, setFilterVisible] = useState(false)
  const [selectedGenders, setSelectedGenders] = useState([])
  const [ageLow, setAgeLow] = useState(defaultAgeLow)
  const [ageHigh, setAgeHigh] = useState(defaultAgeHigh)
  const [distance, setDistance] = useState(defaultMaxDistance)
  const [isLoading, setIsLoading] = useState(true)
  const [updateToggle, setUpdateToggle] = useState(false)
  const [numFilters, setNumFilters] = useState(0)
  const [advertisements, setAdvertisements] = useState([])
  const postIds = useRef([])
  const hasNewFilters = useRef(false)
  const prevSearch = useRef(route?.params?.search || '')

  useEffect(() => {
    getMorePostIds().then(() => {
      setIsLoading(false)
    })
  }, [])

  useEffect(() => {
    const query = {}
    if ('universe' in props) query.universeId = props.universe.id
    AdvertisementService.getAdvertisements(query).then(setAdvertisements)
  }, [])

  useEffect(() => {
    let filterCount = selectedGenders.length
    if (ageLow !== defaultAgeLow) filterCount++
    if (ageHigh !== defaultAgeHigh) filterCount++
    if (distance !== defaultMaxDistance) filterCount++
    setNumFilters(filterCount)
  }, [ageLow, ageHigh, distance, selectedGenders])

  function resetFilters() {
    setSelectedGenders([])
    setAgeLow(defaultAgeLow)
    setAgeHigh(defaultAgeHigh)
    setDistance(defaultMaxDistance)
    setNumFilters(0)
  }

  async function resetPostIds() {
    postIds.current = await getPostIds(0)
  }

  async function getMorePostIds() {
    const ids = await getPostIds(postIds.current.length)
    postIds.current = [...postIds.current, ...ids]
  }

  async function getPostIds(offset) {
    return props.getNewPostIds({
      search: prevSearch.current,
      birthYearLow: thisYear - ageHigh,
      birthYearHigh: thisYear - ageLow,
      genders: selectedGenders,
      maxDistance: distance,
      offset,
    })
  }

  async function getData({ search, skip, amount }) {
    const isNewSearch = prevSearch.current !== search
    if (isNewSearch) {
      prevSearch.current = search
      await resetPostIds()
    }
    let nextPostIds = postIds.current.slice(skip, skip + amount)
    if (nextPostIds.length < 1) {
      await getMorePostIds()
      nextPostIds = postIds.current.slice(skip, skip + amount)
    }
    return props.getPosts(nextPostIds)
  }

  function triggerUpdate() {
    if (hasNewFilters.current) {
      hasNewFilters.current = false
      return true
    }
    return false
  }

  async function applyFilter() {
    await resetPostIds()
    hasNewFilters.current = true
    trackFilter()
    setUpdateToggle((prev) => !prev)
  }

  function trackFilter() {
    saveSearchString({
      searchString: prevSearch.current,
      birthYearLow: thisYear - ageLow,
      birthYearHigh: thisYear - ageHigh,
      genders: selectedGenders.join(),
      maxDistance: distance,
    })
  }

	function handlePostClick(postId, universeId) {
		if (country === 'Sweden' && !userFeatures.includes(Features.START_CONVERSATION)) {
			setSePostFeedIds(async (sePostFeedIdsPromise) => {
				const sePostFeedIds = await sePostFeedIdsPromise
			
				// universeId 14 = nyarsvanner
				if (universeId === 14) return sePostFeedIds

				if (sePostFeedIds.includes(postId)) return sePostFeedIds

				// incremental steps for display of subscription dialog 
				const contactIncrement = 2

				// max length of stored list
				const maxListLength = 50

				if (sePostFeedIds.length > maxListLength) {
					const sePostFeedIdsSliced = sePostFeedIds.slice(contactIncrement, sePostFeedIds.length)
					return [...sePostFeedIdsSliced, postId]
				}

				return [...sePostFeedIds, postId]
			})
		}

		if (country === 'Denmark' && universeId === 10 && !userFeatures.includes(Features.START_CONVERSATION)) {
			setDkNewyearPostFeedIds(async (dkNewyearPostFeedIdsPromise) => {
				const dkNewyearPostFeedIds = await dkNewyearPostFeedIdsPromise

				if (dkNewyearPostFeedIds.includes(postId)) return dkNewyearPostFeedIds

				// incremental steps for display of subscription dialog 
				const contactIncrement = 3

				// max length of stored list
				const maxListLength = contactIncrement * 10

				if (dkNewyearPostFeedIds.length > maxListLength) {
					const dkNewyearPostFeedIdsSliced = dkNewyearPostFeedIds.slice(contactIncrement, dkNewyearPostFeedIds.length)
					return [...dkNewyearPostFeedIdsSliced, postId]
				}

				return [...dkNewyearPostFeedIds, postId]
			})
		}

		if (country === 'Denmark' && !userFeatures.includes(Features.START_CONVERSATION)) {
			setDkPostFeedIds(async (dkPostFeedIdsPromise) => {
				const dkPostFeedIds = await dkPostFeedIdsPromise

				if (dkPostFeedIds.includes(postId)) return dkPostFeedIds

				// incremental steps for display of subscription dialog 
				const contactIncrement = 3

				// max length of stored list
				const maxListLength = 50

				if (dkPostFeedIds.length > maxListLength) {
					const dkPostFeedIdsSliced = dkPostFeedIds.slice(contactIncrement, dkPostFeedIds.length)
					return [...dkPostFeedIdsSliced, postId]
				}

				return [...dkPostFeedIds, postId]
			})
		}

		props.navigateToPost(postId)
	}

  const renderItem = ({ item, index }) => {
    if (item === 'advertisement') return <Advertisement advertisements={advertisements} />

    if (item.isSeparator) {
      return (
        <ListSeparator key={index} text={txt('listSeparators.posts')} color={props?.universe?.separatorColor ?? null} />
      )
    }

    if (item.isCreatePostItem) {
      const newyearUniverses = ['nytaarsvenner', 'nyarsvanner']
      if (newyearUniverses.includes(props?.universe?.name)) {
        return (
          <CreatePostCard
            title={txt('home.createBoblTitleNewYear')}
            text={txt('home.createBoblTextNewYear')}
            navigateToCreatePost={props.navigateToCreatePost}
            universe={props.universe}
          />
        )
      }

      return <CreatePostCard navigateToCreatePost={props.navigateToCreatePost} />
    }

    return (
      <PostItem
        item={item}
        onPressAction={() => handlePostClick(item.id, item.universe_id, index)}
        universe={props.universe}
      />
    )
  }

  const ListHeaderText = useCallback(() => {
    return (
      <>
        <ListSeparator text={txt('posts.nearYou')} color={props?.universe?.separatorColor ?? null} />
        {!props.universe?.name && <CreatePostCard navigateToCreatePost={props.navigateToCreatePost} />}
      </>
    )
  }, [])

  const applyAdvertisements = (listItems) => {
    if (advertisements.length === 0) return listItems

    const steps = 20
    const offset = 15

    const listContent = listItems.reduce((listContent, listItem, index) => {
      if (index % steps === offset) listContent.push('advertisement')
      listContent.push(listItem)
      return listContent
    }, [])

    return listContent
  }

  function filter() {
    return (
      <PostFilter
        filterVisible={filterVisible}
        setFilterVisible={setFilterVisible}
        selectedGenders={selectedGenders}
        setSelectedGenders={setSelectedGenders}
        ageLow={ageLow}
        setAgeLow={setAgeLow}
        ageHigh={ageHigh}
        setAgeHigh={setAgeHigh}
        distance={distance}
        setDistance={setDistance}
        resetFilters={resetFilters}
        applyFilter={applyFilter}
        numFilters={numFilters}
      />
    )
  }

  if (isLoading) {
    return <LoadingSpinner />
  }

  return (
    <SearchList
      childFunc={props.childFunc}
      style={props.style}
      renderItem={renderItem}
      getData={getData}
      renderChips={props.renderChips}
      center={true}
      backgroundColor={props.backgroundColor}
      refresh={resetPostIds}
      ListHeaderComponent={ListHeaderText}
      filterRight={filter}
      triggerUpdate={triggerUpdate}
      updateToggle={updateToggle}
      saveSearch={trackFilter}
      applyAdvertisements={applyAdvertisements}
    />
  )
}