import React from 'react'
import { View, StyleSheet, Image } from 'react-native'

import { CustomText } from '../../text/StyledText'
import { ButtonPrimary } from '../../buttons/StyledButtons'
import { URLOpener } from '../../../utils/URLOpener'
import { txt } from '../../../locales/i18n'
import Colors from '../../../constants/Colors'
import CustomPressable from '../../pressables/CustomPressable'
import { trackPlausibleEvent } from '../../../services/api/plausible'

export default function WeCare() {
	const handleClick = (url, clickType) => {
		trackPlausibleEvent('HerlevOfferClick', { origin: `WeCare-${clickType}` }, 'herlev/HerlevOffers')
		URLOpener(url)
	}

	return (
		<View style={styles.partnerInfoContainer}>
			<Image
				source={{ uri: 'https://filer.boblberg.dk/assets/img/universe/we-care.png' }}
				resizeMode="contain"
				style={[styles.logo, styles.partnerInfoHeader]}
				accessibilityIgnoresInvertColors
				accessible
				accessibilityRole="image"
				accessibilityLabel={txt('universe.herlev.weCare')}
			/>
			<CustomText font="largeBold" style={styles.partnerInfoTitle}>
				{txt('universe.herlev.partnerInfo.weCare.title')}
			</CustomText>
			<CustomText style={styles.text}>{txt('universe.herlev.partnerInfo.weCare.body1')}</CustomText>
			<CustomText style={styles.text}>{txt('universe.herlev.partnerInfo.weCare.body2')}</CustomText>
			<CustomText style={styles.text}>{txt('universe.herlev.partnerInfo.weCare.body3')}</CustomText>

			<CustomPressable onPress={() => handleClick('https://www.youtube.com/watch?v=SEYn8c_hBtA', 'video')}>
				<Image
					source={{ uri: 'https://filer.boblberg.dk/assets/img/universe/we-care-video.png' }}
					resizeMode="contain"
					style={styles.image}
					accessibilityIgnoresInvertColors
					accessible
					accessibilityRole="image"
				/>
			</CustomPressable>

			<CustomText font="bold">{txt('universe.herlev.partnerInfo.weCare.selfcareTitle')}</CustomText>
			<CustomText style={styles.text}>
				{txt('universe.herlev.partnerInfo.weCare.selfcareBody1')}
				<CustomText style={styles.link} onPress={() => handleClick('https://we.care/selfcare/', 'link')}>
					{txt('universe.herlev.partnerInfo.weCare.selfcareLink')}
				</CustomText>
				{txt('universe.herlev.partnerInfo.weCare.selfcareBody2')}
			</CustomText>

			<ButtonPrimary
				title={txt('universe.herlev.partnerInfo.weCare.btnText')}
				onPress={() => handleClick('https://we.care/psykolog-herlev', 'btn')}
				style={{ marginTop: 12 }}
			/>
		</View>
	)
}

const styles = StyleSheet.create({
	logo: {
		alignSelf: 'center',
		height: 60,
		marginVertical: 6,
		maxWidth: 300,
		minWidth: 200,
	},
	partnerInfoContainer: {
		alignSelf: 'center',
		flex: 1,
		maxWidth: 600,
		width: '100%',
	},
	partnerInfoHeader: {
		marginBottom: 24,
	},
	partnerInfoTitle: { marginBottom: 12, marginTop: 12 },
	text: {
		marginBottom: 24,
	},
	link: {
		color: Colors.blueAzure,
	},
	image: {
		alignSelf: 'center',
		height: 170,
		width: 300,
		marginBottom: 24,
	},
})
