import React, { useEffect, useState } from 'react'
import Story from '../../components/views/Stories/Story'
import TxtTMD from '../../constants/TxtTMD'
import PageTitle from '../../components/tags/PageTitle'
import { getStories } from '../../services/api/Stories'
import SearchList from '../../components/lists/SearchList'
import MainContainer from '../../components/MainContainer/MainContainer'
import NormalIcon from '../../components/icons/NormalIcon'
import Colors from '../../constants/Colors'
import Fontsizes from '../../constants/Fontsizes'
import CustomPressable from "../../components/pressables/CustomPressable"
import { useNavigation, useRoute } from "@react-navigation/core"
import { getUniverseConfig, getUniverseId } from "../../universe/universeController"

export default function StoriesScreen(props) {
  const [universe, setUniverse] = useState(null)
  const [universeObject, setUniverseObject] = useState(null)
  const route = useRoute()
  const navigation = useNavigation()

  useEffect(() => {
    let universeName = route.params?.universe?.toLowerCase()
    if (!universeName) return

    const universeId = getUniverseId(universeName)
    setUniverse({ id: universeId, name: universeName })
    setUniverseObject(getUniverseConfig(universeName))

  }, [navigation, route.params])

  async function fetchStories(searchInput) {
    return await getStories(searchInput)
  }

  useEffect(() => {
    props.navigation.setOptions({
      headerBackTitleVisible: false,
      headerBackImage: () => (
        <CustomPressable accessibilityRole="button" onPress={props.navigation.goBack}>
          <NormalIcon
            color={Colors.blue}
            name="chevron-left"
            size={Fontsizes.m}
            stroke="fas"
            style={{ paddingLeft: 6, paddingRight: 20 }}
          />
        </CustomPressable>
      ),
    })
  }, [])

  async function navigateToPost(storyId) {
    const params = { id: storyId }
    if (universe?.name) params.universe = universe.name
    props.navigation.navigate('Story', params)
  }

  function renderItem({ item }) {
    return (
      <Story
        item={item}
        onPressAction={() => {
          navigateToPost(item.id)
        }}
      />
    )
  }

  return (
    <MainContainer noScroll backgroundColor={universeObject?.backgroundColor}>
      <PageTitle title={TxtTMD.stories.title[TxtTMD.lang]} />

      <SearchList
        renderItem={renderItem}
        getData={fetchStories}
        navigation={props.navigation}
        center={true}
        universe={universeObject}
      />
    </MainContainer>
  )
}
